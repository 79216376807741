import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icons } from "../../Assets/AssetLog";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const Pagenation = (props) => {
  const [page,setPage] = useState(props.page_no?props.page_no:1)
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const showPages = props.itemPerPage?Math.ceil(props.totalItem / props.itemPerPage):Math.ceil(props.totalItem / appSetting.itemPerPage);

  const onSkipPrev = function () {
    // props.setPage_no((prev) => prev - appSetting.itemSkipPage);
    setPage(1)
    props.setPage_no(1);
  };

  const onClickPrev = function () {
    setPage((prev) => prev - 1);
    props.setPage_no((prev) => prev - 1);
  };
  const onClickNext = function () {
    setPage((prev) => prev + 1);
    props.setPage_no((prev) => prev + 1);
  };
  const onSkipNext = function () {
    // props.setPage_no((prev) => prev + appSetting.itemSkipPage);
    setPage(showPages);
    props.setPage_no(showPages);
  };

  const customeChange = function (e) {
    let value = e.target.value
    const numericValue = value.replace(/\D/g, '');
    if( value <= showPages){
      setPage(numericValue)
    }
    
    
  };
  const ChangeMainPage = function (e) {
    if (e.key ==="Enter" && page !== "" && page !== 0 &&  page <= showPages ){
      props.setPage_no(Number(page))
    }
  };
  
  useEffect(() => {
    setPage(props.page_no)
    },[props.page_no])
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // width: "53%",
      }}
    >
      <IconButton
        disabled={!(props.page_no > 1)}
        onClick={onSkipPrev}
        color="primary"
      >
        {/* <img src={Icons.SkipPrevious} alt="less" /> */}
        <SkipPreviousIcon />
      </IconButton>
      <IconButton disabled={!(props.page_no > 1)} onClick={onClickPrev} color="primary">
        {/* <img src={Icons.PreviousPage} alt="less" /> */}
        <NavigateBeforeIcon />
      </IconButton>
      <Box
        sx={{
          mx: "6px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <StyledTextField
          type="tel"
          disabled={!(showPages)}
          value={page}
          onChange={customeChange}
          onKeyDown={ChangeMainPage}
          length={page.length}
          
        />
        <Typography sx={{ fontSize: "14px", ml: "10px" }}>
          of {showPages || "0"}
        </Typography>
      </Box>
      <IconButton disabled={!(props.page_no < showPages)} onClick={onClickNext} color="primary">
        {/* <img src={Icons.NextPage} alt="grater" /> */}
        <NavigateNextIcon />
      </IconButton>
      <IconButton disabled={!(props.page_no < showPages)} onClick={onSkipNext} color="primary">
        {/* <img src={Icons.SkipNext} alt="grater" /> */}
        <SkipNextIcon />
      </IconButton>
    </Box>
  );
};

export default Pagenation;

const StyledTextField = styled(TextField)(({ length }) => ({
  // padding: "0px",
  // border: "1px solid #C3C3C3",
  // paddingLeft: "5px",
  // paddingRight: "5px",
  "& .MuiInputBase-input": {
    paddingBottom: "5px",
    paddingTop: "5px",
    paddingLeft:"5px",
    paddingRight:"5px",
    minWidth: "20px",
    width: `${length?length * 8:20}px`,
    maxWidth:"60px",
    fontSize: "14px",
    textAlign: "center",
    backgroundColor:"#F9F9F9",
    borderColor:"#F9F9F9"
    // padding: "3px",
    // marginRight: ""
  },
}));
