import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";
import "@fontsource/luxurious-roman";
import "@fontsource/roboto";
import { blue, red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const lightTheme = (theme) =>
  createTheme({
    typography: {
      allVariants: {
        fontFamily: theme.typography.allVariants.fontFamily,
        color: "black",
      },
    },
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            "& .MuiList-root .MuiButtonBase-root": {
              fontSize: "14px",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            fontSize: "14px", // Adjust the font size as needed
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:nth-of-type(odd)": {
              backgroundColor: "#F9F9F9",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            boxShadow: "none"
          },
          contained: {
            color: "#fff", // This sets the text color to white
            "&:hover": {
              color: "#fff", // Maintains white text on hover
            },
          },
          // If you want to be more specific for contained primary buttons
          containedPrimary: {
            color: "#fff",
            "&:hover": {
              color: "#fff",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
            "&.Mui-checked": {
              color: theme.palette.primary.main,
            },
            // "& .MuiSvgIcon-root": { fontSize: "20px" },
          },
        },
      },
    },
    palette: {
      mode: "light",
      background: {
        default: "#ebebeb",
      },
      primary: {
        main: theme.palette.primary.main,
      },
      error: {
        main: theme.palette.error.main,
      },
    },
  });

// Dark theme
const darkTheme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      color: "white",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#212121",
          boxShadow: "0px 4px 6px rgba(255, 255, 255, 0.1)",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          "& .MuiList-root .MuiButtonBase-root": {
            fontSize: "14px",
            color: "white",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: "14px", // Adjust the font size as needed
          color: "white",
        },
      },
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#3a3a3a",
    },
    primary: {
      main: "#101418",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
});

export { lightTheme, darkTheme };
