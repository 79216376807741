import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import TableSwitch from "../../../Components/Utils/TableSwitch";
import VBTextField from "../../../Components/Utils/VBTextField";
import VBInputField from "../../../Components/Utils/VBInputField";
import General from "./General";
import Inventory from "./Inventory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "92%", width: "100%" }}
      {...other}
    >
      {/* {value === index && ( */}
        <Paper
          elevation={0}
          sx={{ borderRadius: 2, height: "100%", width: "100%" }}
        >
          {children}
        </Paper>
      {/* )} */}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Setting() {
  const [tabValue, setTabValue] = useState(0);

  return (
    <Box sx={{ pl: 2, height: "100%" }}>
      <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
        <Button
          sx={{
            px: 6,
            borderRadius: 2,
            backgroundColor: tabValue !== 0 ? "white" : "black",
            color: tabValue === 0 ? "white" : "black",
            ":hover": { backgroundColor: tabValue !== 0 ? "white" : "black" },
          }}
          onClick={() => {
            setTabValue(0);
          }}
        >
          General
        </Button>
        <Button
          sx={{
            px: 6,
            borderRadius: 2,
            backgroundColor: tabValue !== 1 ? "white" : "black",
            color: tabValue === 1 ? "white" : "black",
            ":hover": { backgroundColor: tabValue !== 1 ? "white" : "black" },
          }}
          onClick={() => {
            setTabValue(1);
          }}
        >
          Inventory
        </Button>
      </Stack>

      <CustomTabPanel value={tabValue} index={0}>
        <General />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Inventory />
      </CustomTabPanel>
    </Box>
  );
}

export default Setting;
