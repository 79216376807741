import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import TableSwitch from "../../../Components/Utils/TableSwitch";
import VBTextField from "../../../Components/Utils/VBTextField";
import VBInputField from "../../../Components/Utils/VBInputField";
import { callGeneralSettingsSingle } from "../../../Api/generalSettings/generalSettings";
import { useDispatch, useSelector } from "react-redux";
import { Essential, Lite, Standard } from "../../../Edition/edition";
import { viknSnackOpen } from "../../../Function/comonFunction";
import { generalSettingsUpdate } from "../../../features/generalSettingsSclice";
import { MyUrl } from "../../../generalVeriable";

function General() {
  const dispatch = useDispatch();
  const { CompanyID, BranchID, Edition } = useSelector(
    (state) => state.companyDetails
  );
  const glss = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  // const roll = useSelector((state) => state.userRollSettings);
  // const userRoll = useSelector((state) => state.rollPermessions);

  const [list, setList] = useState([
    {
      keyName: "EnableCardNetWork",
      name: "Enable Card Net Work",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableCardDetails",
      name: "Enable Card Details",
      checked: true,
      premession: true,
    },
    {
      keyName: "ShowDiscountInPayments",
      name: "Show Discount In Payments",
      checked: true,
      premession: true,
    },
    {
      keyName: "ShowDiscountInReceipts",
      name: "Show Discount In Receipts",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableBillwise",
      name: "Enable Billwise",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableLoyaltySettings",
      name: "Enable Loyalty Settings",
      checked: true,
      premession: true,
    },
    {
      keyName: "ShowInactiveUsers",
      name: "Show Inactive Users",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableRoute",
      name: "Enable Route",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableForeignCurrency",
      name: "Enable Foreign Currency",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableBranch",
      name: "Enable Branch",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableExciseTax",
      name: "Enable Excise Tax",
      checked: true,
      premession: true,
    },
    {
      keyName: "VBAssist",
      name: "VB Assist",
      checked: true,
      premession: true,
    },
    {
      keyName: "EnableKsaEInvoicePhase2",
      name: "Enable KSA E-invoice Phase 2",
      checked: true,
      premession: true,
    },
  ]);

  const [inputData, setInputData] = useState({
    PriceDecimalPoint: "",
    QtyDecimalPoint: "",
    UpdationDelayCount: "",
  });

  const [timeoutId, setTimeoutId] = useState(null);

  const permissFunc = function (key) {
    let permission = true;
    let edit = "";
    console.log({
      Lite: Lite[key],
      Essential: Essential[key],
      Standard: Standard[key],
      Edition: Edition,
    });

    switch (Edition) {
      case "Professional":
      case "Advance":
        // return true;
        edit = "Advance";
        permission = true;
        break;
      case "Lite":
        edit = "Lite";
        permission = Lite[key] === undefined; // If key not present in the Lite object then it become true
        break;
      case "Essential":
        edit = "Essential";
        permission = Essential[key] === undefined; // If key not present in the Essential object then it become true
        break;
      case "Standard":
        edit = "Standard";
        permission = Standard[key] === undefined; // If key not present in the Standard object then it become true
        break;
      default:
        edit = "default";
        permission = true;
        break;
    }
    console.log(key, permission, edit);

    return permission;
  };

  // const changeInput = async function (e) {
  //   setInputData({
  //     ...inputData,
  //     [e.target.name]: e.target.value,
  //   });

  //   setTimeout(() => {
  //     async function xb10ff() {
  //       await callGeneralSettingsSingle({
  //         CompanyID,
  //         BranchID,
  //         type_data: "edit",
  //         SettingsType: [e.target.name] || "",
  //         SettingsValue: e.target.value,
  //       });
  //     }
  //     xb10ff();
  //   }, 3000);
  // };

  const changeInput = useCallback(async (e) => {
    // Update the input state immediately
    setInputData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set new timeout and store its ID
    const newTimeoutId = setTimeout(async () => {
      try {
        await callGeneralSettingsSingle({
          CompanyID,
          BranchID,
          type_data: "edit",
          SettingsType: e.target.name || "",
          SettingsValue: e.target.value,
        }).then((res) => {
          if (res.StatusCode === 6000 && res.message === "Success") {
            updateReduxSetting(e.target.name, e.target.value);
            viknSnackOpen({
              open: true,
              message: "Setting Change successfully",
              severity: "success",
            });
          } else {
            viknSnackOpen({
              open: true,
              message: "You can't change this now",
              severity: "warning",
            });
          }
        });
      } catch (error) {
        console.error('API call failed:', error);
        // Handle error appropriately
      }
    }, 3000);

    setTimeoutId(newTimeoutId);
  }, [timeoutId]); // Add other dependencies if needed


  const changeSetting = async function (keyName, bool, ind) {
    let newList = list.map((e, i) =>
      ind === i ? { ...e, checked: !e.checked } : e
    );

    setList(newList);
    await callGeneralSettingsSingle({
      CompanyID,
      BranchID,
      type_data: "edit",
      SettingsType: keyName || "",
      SettingsValue: bool,
    }).then((res) => {
      if (res.StatusCode === 6000 && res.message === "Success") {
        updateReduxSetting(keyName, !list[ind].checked)
        viknSnackOpen({
          open: true,
          message: "Setting Change successfully",
          severity: "success",
        });
      } else {
        setList((prev) =>
          prev.map((e, i) => (ind === i ? { ...e, checked: !e.checked } : e))
        );
        viknSnackOpen({
          open: true,
          message: "You can't change this now",
          severity: "warning",
        });
      }
    });
  };

  const updateReduxSetting = function (key, value) {
    dispatch( generalSettingsUpdate({ key: key, value: value }) )
  }

  // Clean up timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  useEffect(() => {
    setList((prev) =>
      prev.map((e) => ({
        ...e,
        checked: glss[e.keyName],
        premession: permissFunc(e.keyName),
      }))
    );

    setInputData({
      PriceDecimalPoint: glss.PriceDecimalPoint,
      QtyDecimalPoint: glss.QtyDecimalPoint,
      UpdationDelayCount: glss.UpdationDelayCount,
    });

    console.log(glss.QtyDecimalPoint);
    
  }, [glss]);

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 2, mb: 1 }}>
        <Typography sx={{ mb: 2, fontSize: "18px", fontWeight: "500" }}>
          General
        </Typography>
        <Grid container spacing={2}>
          {list.map(
            (obj, ind) =>
              obj.premession && (
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: "100%",
                      p: "12px",
                      bgcolor: "#f8f8f8",
                      border: "1px solid #e2e2e2",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px" }}>
                      {obj.name}
                    </Typography>
                    <TableSwitch
                      checked={obj.checked}
                      onChange={(e) => {
                        changeSetting(obj.keyName, e.target.checked, ind);
                      }}
                    />
                  </Paper>
                </Grid>
              )
          )}
          {glss.EnableVbassist && (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "12px", width: "100%" }}>
                Updation Delay In Days
              </Typography>
              <VBInputField
                sx={{ width: "60px" }}
                name="UpdationDelayCount"
                value={inputData.UpdationDelayCount}
              />
            </Grid>
          )}
        </Grid>
      </Box>

      <Divider />
      <Box sx={{ p: 2 }}>
        <Typography sx={{ mb: 3, fontSize: "18px", fontWeight: "500" }}>
          Decimal Points
        </Typography>
        <Stack direction={"row"} gap={4} sx={{ mb: 1, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "12px", width: "auto", minWidth: "fit-content" }}
            >
              Quantity Decimal
            </Typography>
            <VBInputField
              sx={{ width: "60px" }}
              type="number"
              name="QtyDecimalPoint"
              value={inputData.QtyDecimalPoint}
              onChange={changeInput}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              sx={{ fontSize: "12px", width: "100%", minWidth: "fit-content" }}
            >
              Price Decimal
            </Typography>
            <VBInputField
              sx={{ width: "60px" }}
              type="number"
              name="PriceDecimalPoint"
              value={inputData.PriceDecimalPoint}
              onChange={changeInput}
            />
          </Box>
        </Stack>
      </Box>
      <Divider />

      <Box sx={{ p: 2 }}>
        <Typography sx={{ mb: 2, fontSize: "18px", fontWeight: "500" }}>
          Sender ID
        </Typography>
        <Button variant="contained" component={"a"} href={`${MyUrl}/dashboard/setupsenderid`} target="_blank" sx={{ borderRadius: 5, px: 5 }}>
          Set Up a sender ID
        </Button>
      </Box>
    </>
  );
}

export default General;
