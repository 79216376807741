import { Box, Paper, Typography } from '@mui/material'
import React from 'react'

function Users() {
  return (
    <Box sx={{ pl: 2, height: "100%" }}>
      <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: 2 }}>
        User
      </Typography>
      <Paper
        elevation={0}
        sx={{ borderRadius: 2, height: "93%", width: "100%" }}
      >
        {" "}
      </Paper>
    </Box>
  )
}

export default Users