import React from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {
  CustomizeTableModal,
  DeleteButton,
  StyledAutoComplete,
  StyledProductAutoComplete,
  StyledTextField,
  ValueBox,
} from "../../Components/TableComponents/TableComponents";
import { TableVirtuoso } from "react-virtuoso";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function isMultipleOf(n) {
  return n % 9 === 0;
}

const columns = [
  {
    id: 1,
    width: 40,
    label: "Sl No",
    dataKey: "index",
    is_textBox: true,
    disabled: true,
    textAlign: "center",
    paddingLeft: "0px",
  },
  {
    id: 2,
    width: 120,
    label: "Product Code",
    dataKey: "ProductCode",
    numeric: false,
    is_autoComplete: true,
    OptionName: "ProductCode",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce:true,

  },
  {
    id: 3,
    width: 80,
    label: "BarCode",
    dataKey: "BarCode",
    numeric: false,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 4,
    width: 300,
    label: "Ledger",
    dataKey: "Ledger",
    numeric: false,
    is_autoComplete: true,
    OptionName: "LedgerName",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce:true,
  },
  {
    id: 5,
    width: 80,
    label: "Amount",
    dataKey: "Amount",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 6,
    width: 120,
    label: "Amount",
    dataKey: "FCAmount",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    foreignCurrency : true,
  },
  {
    id: 7,
    width: 120,
    label: "Conversion Rate",
    dataKey: "ConversionRate",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    foreignCurrency : true,
  },

  {
    id: 8,
    width: 80,
    label: "Description",
    dataKey: "Description",
    numeric: false,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 9,
    width: 80,
    label: "Unit",
    dataKey: "Unit",
    numeric: false,
    is_autoComplete: true,
    OptionName: "UnitName",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce:false,
  },
  {
    id: 10,
    width: 80,
    label: "Qty",
    dataKey: "Qty",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },

  {
    id: 11,
    width: 80,
    label: "F Qty",
    dataKey: "FQty",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 12,
    width: 80,
    label: "Inclu",
    dataKey: "InclusivePrice",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 13,
    width: 80,
    label: "Rate",
    dataKey: "Rate",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 14,
    width: 80,
    label: "Gross Amount",
    dataKey: "GrossAmount",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 15,
    width: 80,
    label: "Disc(%)",
    dataKey: "DiscountPerc",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 16,
    width: 80,
    label: "DiscAmt",
    dataKey: "DiscountAmount",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 17,
    width: 80,
    label: "Tax",
    dataKey: "Tax",
    numeric: false,
    is_autoComplete: true,
    OptionName: "TaxName",
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    is_debounce:false
  },
  // {
  //   id: 14,
  //   width: 80,
  //   label: "Excise Tax",
  //   dataKey: "ExciseTax",
  //   numeric: true,
  //   is_textBox: true,
  //   disabled: false,
  //   textAlign: "left",
  //   paddingLeft: "10px",
  // },
  {
    id: 18,
    width: 80,
    label: "Tax Amt",
    dataKey: "TaxAmount",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 19,
    width: 80,
    label: "Total",
    dataKey: "Total",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
  },
  {
    id: 20,
    width: 80,
    label: "Total",
    dataKey: "FCTotal",
    numeric: true,
    is_textBox: true,
    disabled: false,
    textAlign: "left",
    paddingLeft: "10px",
    foreignCurrency : true,

  },
  
  

  {
    id: 21,
    width: 20,
    label: (
      <BorderColorIcon
        color="#0A9EF3"
        sx={{ color: "#0A9EF3", cursor: "pointer", width: "20px" }}
      />
    ),
    dataKey: "Activity",
    numeric: true,
    is_delete_button: true,
    disabled: true,
    textAlign: "center",
    paddingLeft: "0px",
  },
];

const CheckFocus = (column, state, focusSettings) => {
  return focusSettings[column.dataKey];
};

const ShowColumn = (column, state, tableSettings,GeneralSettingsData) => {
  let data = true;
  if (column.dataKey === "Adjustment") {
    if (state.Type?.name === "Used" || state.Type?.name === "Damage") {
      data = false;
    }
    // } else if (column.dataKey === "Excess") {
    //   if (state.Type?.name !== "Excess") {
    //     data = false;
    //   }
    // } else if (column.dataKey === "Shortage") {
    //   if (state.Type?.name !== "Shortage") {
    //     data = false;
    //   }
  } else if (column.dataKey === "Used") {
    if (state.Type?.name !== "Used") {
      data = false;
    }
  } else if (column.dataKey === "Damage") {
    if (state.Type?.name !== "Damage") {
      data = false;
    }
  }
  
  else if ((column.foreignCurrency && !GeneralSettingsData.EnableForeignCurrency) 
    || (column.foreignCurrency && GeneralSettingsData.EnableForeignCurrency && (state.Treatment?.value !== "7" && state.Treatment?.value !== "4"))) {
    data = false;
  }


  else {
    data = tableSettings[column.dataKey];
  }

  return data;
};

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent(
  index,
  column,
  state,
  setIsCustomize,
  tableSettings,
  GeneralSettingsData
) {
  return (
    <TableRow>
      {columns.map(
        (column) =>
          ShowColumn(column, state, tableSettings,GeneralSettingsData) && (
            <TableCell
              key={column.dataKey}
              variant="head"
              // align={column.numeric || false ? "right" : "left"}
              align={column.textAlign}
              style={{
                // paddingLeft: "9px",
                width: column.width,
                //borderChange
                // borderRight: "1px solid rgba(224, 224, 224, 1) ",
                // borderTop: "1px solid rgba(224, 224, 224, 1) ",
                padding: "2px",
                paddingLeft: `${column.paddingLeft}`,
                height: "45px",
              }}
              sx={{
                backgroundColor: "#fff",
                fontSize: "13px",
                textTransform: "capitalize",
              }}
              onClick={() =>
                column.dataKey === "Activity" ? setIsCustomize(true) : null
              }
            >
              { column.foreignCurrency ? column.label + `(${state.AccountLedger?.CurrencySymbol === undefined ? '' : state.AccountLedger?.CurrencySymbol })` : column.label }

            </TableCell>
          )
      )}
    </TableRow>
  );
}

function rowContent(
  index,
  row,
  columns,
  state,
  handleChanges,
  handleCompleteChanges,
  CellValue,
  setCellValue,
  ClickColumn,
  textFieldRef,
  handleKeyDown,
  autoFocus,
  handleInputChange,
  tableSettings,
  RemoveLine,
  PriceDecimalPoint,
  dataState,
  Errors,
  debouncedOnDetailsInputChange,
  GeneralSettingsData
) {
  // const [CellValue,setCellValue] = React.useState({RowIndex:null,ColumnId:null})
  const ShowCell = (index, columnId) => {
    // return true
    if (index === CellValue.RowIndex && columnId === CellValue.ColumnId) {
      return true;
    } else {
      return false;
    }
  };

  const getValue = (index, row, column) => {
    let Value = null;
    if (column.dataKey === "index") {
      Value = index + 1;
    } else if (column.is_autoComplete === true) {
      let OptionName = column.OptionName;
      if (row[column.dataKey]) {
        Value = row[column.dataKey][OptionName];
      } else {
        Value = null;
      }
    } else {
      Value = row[column.dataKey];
    }
    return Value;
  };

  const get_AutoComplete_List = (index, row, column) => {
    let Data = [];
    if (column.dataKey === "Ledger") {
      Data = dataState["ProductList"];
    } else if (column.dataKey === "ProductCode") {
      Data = dataState["ProductList"];
    } else if (column.dataKey === "Unit") {
      Data = row["UnitList"];
    } else if (column.dataKey === "Tax") {
      Data = dataState.TaxCategoryData;
    }

    return Data;
  };

  return (
    <React.Fragment>
      {columns.map(
        (column, i) =>
          ShowColumn(column, state, tableSettings,GeneralSettingsData) && (
            <TableCell
              key={column.dataKey}
              align={"left"}
              style={{
                height:"17px",
                overflow:"hidden",
                textOverflow:"ellipsis",
                padding: "0px",
                width: column.width,
                borderBottom:
                  Errors.error && Errors.indexList.includes(index)
                    ? "1px solid rgba(224, 224, 224, 1)"
                    : "none",
                borderColor:
                  Errors.error && Errors.indexList.includes(index)
                    ? "red"
                    : null,
              }}
              onKeyDown={(e) => {
                handleKeyDown(e, index, column.id);
              }}
            >
              {column.is_textBox && ShowCell(index, column.id) ? (
                <StyledTextField
                  handleChanges={handleChanges}
                  name={column.dataKey}
                  value={row[column.dataKey]}
                  index={index}
                  textFieldRef={textFieldRef}
                  autoFocus={autoFocus}
                  numeric={column.numeric}
                  PriceDecimalPoint={PriceDecimalPoint}
                />
              ) : column.is_autoComplete && ShowCell(index, column.id) && (column.dataKey === "Product" || column.dataKey === "ProductCode") ? (
                <StyledProductAutoComplete
                  List={get_AutoComplete_List(index, row, column)}
                  handleCompleteChanges={handleCompleteChanges}
                  handleInputChange={column.is_debounce?debouncedOnDetailsInputChange:handleInputChange}
                  OptionName={column.OptionName}
                  textFieldRef={textFieldRef}
                  autoFocus={autoFocus}
                  index={index}
                  name={column.dataKey}
                  value={row[column.dataKey]}
                  ProductColumns = {[{name:"ProductCode",value:"ProductCode",width:"20%",numeric:false,permission:GeneralSettingsData?.show_productcode_InsearchSale},{name:"ProductName",value:"ProductName",width:"30%",numeric:false,permission:true},{name:"BrandName",value:"BrandName",width:"20%",numeric:false,permission:GeneralSettingsData?.show_brand_InsearchSale},{name:"Stock",value:"Stock",width:"10%",numeric:true,permission:GeneralSettingsData?.show_stock_InsearchSale},{name:"PurchasePrice",value:"PurchasePrice",width:"10%",numeric:true,permission:GeneralSettingsData?.show_purchasePrice_InsearchSale&&GeneralSettingsData?.PurchasePriceInSales&&GeneralSettingsData?.sales_purchase_price_perm},{name:"SalesPrice",value:"SalesPrice",width:"10%",numeric:true,permission:true}]}
                />
              ) : column.is_autoComplete && ShowCell(index, column.id) ? (
                <StyledAutoComplete
                  List={get_AutoComplete_List(index, row, column)}
                  handleCompleteChanges={handleCompleteChanges}
                  handleInputChange={column.is_debounce?debouncedOnDetailsInputChange:handleInputChange}
                  OptionName={column.OptionName}
                  textFieldRef={textFieldRef}
                  autoFocus={autoFocus}
                  index={index}
                  name={column.dataKey}
                  value={row[column.dataKey]}
                  disabled = {column.dataKey ===  "Tax" && (state.TreatmentData==='1'||state.TreatmentData==='7') ? true : false}

                />
              ) : column.is_delete_button ? (
                <DeleteButton index={index} RemoveLine={RemoveLine} />
              ) : (
                <ValueBox
                  index={index}
                  column={column}
                  value={getValue(index, row, column)}
                  ClickColumn={ClickColumn}
                  disabled={column.disabled}
                  numeric={column.numeric}
                  PriceDecimalPoint={PriceDecimalPoint}
                  // openSnackbar={openSnackbar}
                  // missingMasterMandatory={missingMasterMandatory}
                />
              )}
            </TableCell>
          )
      )}
    </React.Fragment>
  );
}

export default function MyGrid(props) {
  let {
    DataList,
    setDataLists,
    handleChanges,
    handleCompleteChanges,
    handleInputChange,
    tableSettings,
    setTableSettings,
    focusSettings,
    setFocusSettings,
    ChangeTableCustomization,
    RemoveLine,
    AddLine,
    PriceDecimalPoint,
    Errors,
    debouncedOnDetailsInputChange,
    GeneralSettingsData
  } = props;
  let rows = DataList;
  let state = props.state;
  let dataState = props.dataState;

  let NowActiveColumns = columns.filter((i) => i.disabled === false);
  let columnsLength = NowActiveColumns.length + 2;
  let activeFirstColumn = NowActiveColumns[0].id;
  let activeLastColumn = NowActiveColumns[NowActiveColumns.length - 1].id;

  //row length
  let rowLength = rows.length;
  const [IsCustomize, setIsCustomize] = React.useState(false);
  const textFieldRef = React.useRef(null);
  const [CellValue, setCellValue] = React.useState({
    RowIndex: null,
    ColumnId: null,
  });

  let tableSettingsList = Object.entries(tableSettings).map(([key, value]) => ({
    [key]: value,
    dataKey: key,
  }));
  let focusSettingsList = Object.entries(focusSettings).map(([key, value]) => ({
    [key]: value,
    dataKey: key,
  }));

  const ClickColumn = (index, columnId) => {
    setCellValue({
      RowIndex: index,
      ColumnId: columnId,
    });
    if (textFieldRef.current) {
      textFieldRef.current.focus(); // Focus the TextField on button click
    }
  };

  const [autoFocus, setAutoFocus] = React.useState(false);

  const handleKeyDown = (event, index, columnId) => {
    console.log(event.key, "event.key");

    //Moving forward
    if (event.key === "Tab" || event.key === "Enter") {
      if (event.key === "Enter" && textFieldRef.current) {
        textFieldRef.current.focus();
      }

      if (event.key === "Enter") {
        setAutoFocus(true);
      } else {
        setAutoFocus(false);
      }

      let ColumnNewID = columnId;
      let indexNew = index;
      let columnIndex = columns.findIndex((i) => i.id === columnId);

      let IsNextStep = false;

      while (IsNextStep === false) {
        if (ColumnNewID == columnsLength) {
          ColumnNewID = 1;
          indexNew = indexNew + 1;
          columnIndex = 0;
        } else {
          ColumnNewID = ColumnNewID + 1;
          columnIndex += 1;
        }

        IsNextStep =
          columns[columnIndex].disabled === false &&
          ShowColumn(columns[columnIndex], state, tableSettings) === true &&
          CheckFocus(columns[columnIndex], state, focusSettings) === true;
      }

      setCellValue({
        RowIndex: indexNew,
        ColumnId: ColumnNewID,
      });

      // auto scroll
      if (isMultipleOf(indexNew) && ColumnNewID == 2) {
        tableRef.current.scrollToIndex(indexNew);
      }

      //Add New Line and autoscroll
      if (indexNew + 1 === rowLength) {
        AddLine();
        tableRef.current.scrollToIndex(indexNew);
      }

      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }

    //Moving backward
    else if (event.key === "Alt" && textFieldRef.current) {
      setAutoFocus(true);
      let ColumnNewID = columnId;
      let indexNew = index;
      let columnIndex = columns.findIndex((i) => i.id === columnId);

      console.log(
        columnId,
        "columnId",
        index,
        "index",
        columnIndex,
        "columnIndex"
      );

      let IsNextStep = false;

      while (IsNextStep === false) {
        if (ColumnNewID == activeFirstColumn) {
          ColumnNewID = activeLastColumn;
          indexNew = indexNew - 1;
          columnIndex = activeLastColumn - 1;
        } else {
          ColumnNewID = ColumnNewID - 1;
          columnIndex -= 1;
        }

        IsNextStep =
          columns[columnIndex].disabled === false &&
          ShowColumn(columns[columnIndex], state, tableSettings) === true &&
          CheckFocus(columns[columnIndex], state, focusSettings) === true;
      }

      setCellValue({
        RowIndex: indexNew,
        ColumnId: ColumnNewID,
      });

      // auto scroll
      if (isMultipleOf(indexNew) && ColumnNewID == 2) {
        tableRef.current.scrollToIndex(0);
      }
    }
  };

  const [scrollToIndex, setScrollToIndex] = React.useState(null); // State for target index to scroll to
  const tableRef = React.useRef(null); // useRef for TableVirtuoso instance

  const handleScrollComplete = () => {
    setScrollToIndex(null); // Reset scroll target after completion
  };

  console.log(CellValue, "CellValue");

  return (
    <Paper style={{ height: 250, width: "100%" ,margin:"0", border:"none" }}>
      <CustomizeTableModal
        open={IsCustomize}
        setOpen={setIsCustomize}
        columns={columns}
        ChangeTableCustomization={ChangeTableCustomization}
        tableSettingsList={tableSettingsList}
        focusSettingsList={focusSettingsList}
      />

      <TableVirtuoso
        ref={tableRef}
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={(index, column) =>
          fixedHeaderContent(
            index,
            column,
            state,
            setIsCustomize,
            tableSettings,
            GeneralSettingsData
          )
        }
        // itemContent={rowContent}
        onScrollComplete={handleScrollComplete}
        itemContent={(index, row) =>
          rowContent(
            index,
            row,
            columns,
            state,
            handleChanges,
            handleCompleteChanges,
            CellValue,
            setCellValue,
            ClickColumn,
            textFieldRef,
            handleKeyDown,
            autoFocus,
            handleInputChange,
            tableSettings,
            RemoveLine,
            PriceDecimalPoint,
            dataState,
            Errors,
            debouncedOnDetailsInputChange,
            GeneralSettingsData
          )
        }
      />
    </Paper>
  );
}
