import axios from "axios";

// Stock repoprt API
const CallStockReport = async function (body) {
  try {
    const { data } = await axios.post("/api/v10/sales/stock/report/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log(" error in stock report");
  }
};

// Stock Value Report API
const CallStockValueReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/stock-value/report-qry/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in stock Value report");
  }
};
// Stock Value Report total API
const CallStockValueReportTotal = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/stock-value/report-qry/total/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in stock Value report");
  }
};



const CallTotalStockValueReport = async function (body) {
  try {
    const { data } = await axios(
      "api/v11/reports/check-task-status/"+body.id,
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in stock Value report");
  }
};

// Product Summary Report API
const CallProductSummaryReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/product-summary-report/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in stock product summary report");
  }
};

// Stock Ledger Report API
const CallStockLedger = async function (body) {
  try {

    const { data } = await axios.post("api/v10/sales/stock-ledger/report/", {
      
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in stock ledger report");
  }
};

// Opening Stock Report API
const CallOpeningStockReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/openingStock/openingStock-report/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in opening stock report");
  }
};

// Stock Transfer Register Report API
const CallStockTransferRegisterReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/stockTransfers/report/stockTransfer-register/",
      {
        
        // UserID: UserDetails.user_id,
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log.log("error in CallStockTransferRegisterReport");
  }
};

// Branch Transfer Report API
const CallBranchTransferReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/salesBt/branch-transfer-report-bt/report/",
      {
        
        // 
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallBranchTransferReport");
  }
};

// Product Serialisation Report API
const CallProductSerialisation = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/products/product-serialisation-report/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProductSerialisation");
  }
};

const callBatchReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/products/batch/report/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProductSerialisation");
  }
};

// Note Product Summary Report API Specific for new filters 
const CallProductSummaryReportNew = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/product-summary-report/new/",
      {
        
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in stock product summary report");
  }
};

const callProductWarehouseReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/product-warehouse-report/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in product warehouse report");
  }
};
const callUpdateLedgerBalance = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/reports/update-balance-Ledger/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in product warehouse report");
  }
};


export {
  CallStockReport,
  CallStockValueReport,
  CallProductSummaryReport,
  CallStockLedger,
  CallOpeningStockReport,
  CallStockTransferRegisterReport,
  CallBranchTransferReport,
  CallProductSerialisation,
  callBatchReport,
  CallStockValueReportTotal,
  CallProductSummaryReportNew,
  callProductWarehouseReport,
  callUpdateLedgerBalance
};
