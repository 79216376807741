import { Box, CircularProgress, Collapse, Divider, Grid, List, ListItem, Paper, Popover, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import VBSelect from '../../../../Components/Utils/VBSelect';
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import VBInputField from '../../../../Components/Utils/VBInputField';
import ViknCheckboxGroup from '../../../../Components/Utils/ViknCheckboxGroup';
import { date_range } from '../../../../Function/comonFunction';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import ViknRadioGroup from '../../../../Components/Utils/ViknRadioGroup';
import ViknDrawer from '../../../../Components/ViknDrawer/ViknDrawer';
import FilterLable from '../../../../Components/Utils/FilterLable';
import Pagenation from '../../../../Components/Utils/Pagenation';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import { CallAgeingLedgerReport, CallEmployees, CallParties, CallRoutes, callEditCustomePeriods } from '../../../../Api/Reports/CommonReportApi';
import { CallCreditorAgeingReport, CallDebtorAgeingReport } from '../../../../Api/Reports/AccountApi';
import moment from 'moment';
import { useSelector } from 'react-redux';
import BillwiseTable from './Components/BillwiseTable';
import LedgerwiseTable from './Components/LedgerwiseTable';
import ViknBlueButton from '../../../../Components/Utils/ViknBlueButton';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { t } from 'i18next';
import { UserRolePermission } from '../../../../Function/Editions';
import { useTranslation } from 'react-i18next';

const CreditorAgeingReport = () => {
  let Export_view = UserRolePermission("Creditor Ageing Report", "print_permission");
  // Declear state
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const generalSettings = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const [page_bill, setPage_bill] = useState(1);
  const [page_ledg, setPage_ledg] = useState(1);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [partySearch, setPartySearch] = useState("")
  const [routSearch, setRoutSearch] = useState("")
  const [employeeSearch, setEmployeeSearch] = useState("")
  const [t, i18n] = useTranslation("common");

  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    routes: null,
    customer: null,
    employee:null,
    dateRange: "Custom",
    dateFilter: "Due Date",
    customePeriods: {
      first: 1,
      second: 30,
      third: 60, 
      fourth: 90,
      fifth: 120,
      sixth: 150
    }
  });
  const [filterData, setFilterData] = useState({
    ageingReportList: [],
    ledgerReportList: [],
    routeList: [],
    customerList: [],
    employeeList:[],
    totalvalue: {
      AmountTotal: "",
      BalanceTotal: "",
      RecievedTotal: "",
    },
    ledgerTotalValue: {
      TotalFirst: "",
      TotalSecond: "",
      TotalThird: "",
      TotalFourth: "",
      TotalFifth: "",
      TotalSixth: "",
      TotalCurrent: "",
      Total: "",
    },
    switchReport: true,
    checkDefaultPeriod: false,
    disabledPeriods: false,
    count_ledg: 0,
    count_bill: 0,
  });

  const [customTable, setCustomTable] = useState({
    employeeName:false,
    showTax:false
  })

  const [anchorEl, setAnchorEl] = useState(null)

  const [customePeriods, setCustomePeriods] = useState({
    first: 1,
    second: 30,
    third: 60, 
    fourth: 90,
    fifth: 120,
    sixth: 150
  })
  const [loading, setLoading] = useState({
    editPeriod: false
  })

  // Handle fundtions
  const closeFilter = () => setOpenFilter(false);

  const filterReport = function () {
    if (filterData.switchReport) {
      fetchBillwiseReport();
    } else {
      fetchLedgerReport();
      setFilterOptions({
        ...filterOptions,
        customePeriods: {
          first: customePeriods.fifth,
          second: customePeriods.second,
          third: customePeriods.third,
          fourth: customePeriods.fourth,
          fifth: customePeriods.fifth,
          sixth: customePeriods.sixth,
        },
      });
    }
  };


  const onChangeInputPeriod = function (name, value, min, max) {
    let newValue;
    console.log(value, "value is Empty");
    if (value === '') {
    console.log("Another way ++++++++++ is Empty");
      newValue = filterOptions.customePeriods[name]; // Allow empty string for clearing the input field
    } else {
      newValue = Math.min(Math.max(parseInt(value, 10), min), max).toString();
    }
    setCustomePeriods((prevPeriods) => ({
      ...prevPeriods,
      [name]: newValue,
    }));
  };

  const onChangePeriodValue = function (name, newValue) {
    setCustomePeriods((prevPeriods) => ({
      ...prevPeriods,
      [name]: newValue,
    }));
  }

  const handleTableHeadingSelection = (value) => {
    
    setCustomTable((prevState)=>({
      ...prevState,
      [value]:!prevState[value]
    }))
  }

  const handlePenIconClick = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePenIconClose = () => {
    setAnchorEl(null)
  };
  // Fetch data
  const fetchBillwiseReport = async function () {
    const payload = {
      FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
      customerID: 0,
      RouteLedgers: [0],
      PartyType: 1,
      Type: "DueDate",
      Invoice: "PI",
      page_no: page_bill,
      items_per_page: appSetting.itemPerPage,
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
    }

    if (filterOptions.routes) payload.RouteLedgers = [0, filterOptions.routes.RouteID]
    if (filterOptions.customer) payload.customerID = filterOptions.customer.LedgerID
    if (filterOptions.employee) payload.EmployeeID = filterOptions.employee.EmployeeID

    const res = await CallCreditorAgeingReport(payload);
    if (res.StatusCode === 6000) {
      setFilterData({
        ...filterData,
        ageingReportList: res.data,
        count_bill: res.count,
        totalvalue: {
          AmountTotal: res.AmountTotal,
          BalanceTotal: res.BalanceTotal,
          RecievedTotal: res.RecievedTotal,
        },
      });
    }
    else{
      setFilterData((prevState)=>({
        ...prevState,
        ageingReportList:[]
      }))
    }
  };

  const fetchLedgerReport = async function(){
    const payload = {
      RouteLedgers: [0],
      // PartyType: 1,
      Type: filterOptions.dateFilter === "Due Date" ? "DueDate" : "VoucherDate",
      Invoice: "PI",
      page_no: page_ledg,
      items_per_page: appSetting.itemPerPage,
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
    }
    if (filterOptions.routes) payload.RouteLedgers = [0, filterOptions.routes.RouteID]

    const res = await CallAgeingLedgerReport(payload);
    if (res.StatusCode === 6000) {
      setFilterData(prev => ({
        ...prev,
        ledgerReportList: res.data,
        count_ledg: res.count,
        ledgerTotalValue: {
          TotalFirst: res.TotalFirst,
          TotalSecond: res.TotalSecond,
          TotalThird: res.TotalThird,
          TotalFourth: res.TotalFourth,
          TotalFifth: res.TotalFifth,
          TotalSixth: res.TotalSixth,
          TotalCurrent: res.TotalCurrent,
          Total: res.Total,
        },
      }))
      setFilterOptions({
        ...filterOptions,
        customePeriods: {
          first: res.FirstPeriod,
          second: res.SecondPeriod,
          third: res.ThirdPeriod,
          fourth: res.FourthPeriod,
          fifth: res.FifthPeriod,
          sixth: res.SixthPeriod,
        },
      });
      setCustomePeriods({
        first: res.FirstPeriod,
        second: res.SecondPeriod,
        third: res.ThirdPeriod,
        fourth: res.FourthPeriod,
        fifth: res.FifthPeriod,
        sixth: res.SixthPeriod,
      })
    }
  }

  const editCustomePeriods = async function() {
    setLoading({
      ...loading,
      editPeriod: true,
    });
    const res = await callEditCustomePeriods({
      FirstPeriod: Number(customePeriods.first),
      SecondPeriod: Number(customePeriods.second),
      ThirdPeriod: Number(customePeriods.third),
      FourthPeriod: Number(customePeriods.fourth),
      FifthPeriod: Number(customePeriods.fifth),
      SixthPeriod: Number(customePeriods.sixth),
      CompanyID:CompanyID,
      BranchID:BranchID,
    });

    if (res.StatusCode === 6000) {
      setLoading({
        ...loading,
        editPeriod: false,
      });
    }
  }

  const fetchRout = async function () {
    const resData = {
      route: []
    };
    const routRes = generalSettings.EnableRoute && await CallRoutes({ 
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      page_number: "1",  
      search: routSearch
    });
    if (routRes?.StatusCode === 6000) {
      resData.route = routRes.data;
    }
    setFilterData(prev => ({
      ...prev,
      routeList: resData.route,
    }));
  }

  const fetchParties = async function () {
    const resData = {
      custom: [],
    };

    const customerRes = await CallParties({ 
      CompanyID:CompanyID,
      BranchID:BranchID,
      PriceRounding:Number(PriceDecimalPoint),
      CreatedUserID:user_id,
      PartyType: 2, 
      RouteID: null,
      search : partySearch,
      page_no: 1,
      items_per_page: 10,
    });
    if (customerRes?.StatusCode === 6000) {
      resData.custom = customerRes.data;
    }
    setFilterData(prev => ({
      ...prev,
      customerList: resData.custom,
    }));
  };

  const fetchEmployee = async () =>{
    const fetchEmployee = await CallEmployees({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      items_per_page: 10,
      page_no: 1,
      search: employeeSearch,
    });

    if (fetchEmployee.StatusCode === 6000){
      setFilterData((prevState)=>({
        ...prevState,
        employeeList:fetchEmployee.data
      }))
    }
  }

  useEffect(() => {
    fetchLedgerReport()
  }, [page_ledg, customTable.employeeName, customTable.showTax])


  useEffect(() => {
    fetchBillwiseReport()
  }, [page_bill])  

  useEffect(() => {
    fetchBillwiseReport()
    fetchRout()
    fetchParties()
    fetchEmployee()
  }, [])

  useEffect(() => {
    fetchRout()
  }, [routSearch])
  
  useEffect(() => {
    fetchParties()
  }, [partySearch])

  useEffect(() => {
    fetchEmployee()
  }, [employeeSearch])

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        {/* Top header */}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center"}}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Creditor Ageing Report")}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {Export_view ? 
          <ReportDownload
            invoice_type={filterData.switchReport ? "ageing_billwise" : "ageing_ledgerwise"}
            FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
            RouteLedgers={filterOptions.routes ? `[0, ${filterOptions.routes?.RouteID}]` : `[0]`}
            // UserID={user_id}
            value={1}
            // filterValue={"VoucherDate"}
            filterValue={filterOptions.dateFilter === "Due Date" ? "DueDate" : "VoucherDate"}
            LedgerList={""}
            CustomerID={filterOptions.customer ? filterOptions.customer.LedgerID : 0}
            ShowEmployee={customTable.employeeName}
            ShowTax={customTable.showTax}
            EmployeeID={filterOptions.employee?.EmployeeID || 0}
          />
          : null}
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            {filterData.switchReport ? <Pagenation               
              page_no={page_bill}
              setPage_no = {setPage_bill}
              totalItem={filterData.count_bill} 
            /> : <Pagenation               
            page_no={page_ledg}
            setPage_no = {setPage_ledg}
            totalItem={filterData.count_ledg} 
          />}
          </FlexBox>
        </Box>

        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          {filterData.switchReport && <FilterLable
            name={t("Date")}
            value={(filterOptions.FromDate.format("DD/MM/YYYY") + " - ") + filterOptions.ToDate.format("DD/MM/YYYY")}
          />}

          {(filterOptions.routes) && <FilterLable
            name={t("Route Name")}
            value={filterOptions.routes.RouteName}
            handleDelete={() => setFilterOptions({...filterOptions, routes: null})}
          />}

          {(filterData.switchReport && filterOptions.customer) && <FilterLable
            name={t("Supplier")}
            value={filterOptions.customer.PartyName}
            handleDelete={() => setFilterOptions({...filterOptions, customer: null})}
          />}
          {(!filterData.switchReport) && <FilterLable
            name={t("Periods")}
            value={!filterData.disabledPeriods ? " Custom Periods" : "Default Periods"}
          />}
          {(!filterData.switchReport) && <FilterLable
            name={t("Date Range")}
            value={filterOptions.dateFilter}
          />}
        </Box>

        {/* Report Table */}
        {/* <div style={{ height: "100%", backgroundColor: "red" }}> */}
        {/* </div> */}
        {/* <ReportTable /> */}

        {filterData.switchReport ? (
          <BillwiseTable
            data={filterData.ageingReportList}
            total={filterData.totalvalue}
            page={page_bill}
            handlePenIconClick={handlePenIconClick}
            customTable={customTable}
          />
        ) : (
          <LedgerwiseTable
            data={filterData.ledgerReportList}
            total={filterData.ledgerTotalValue}
            periods={filterOptions.customePeriods}
            page={page_ledg}
          />
        )}
      </Paper>
      <Popover
            id={Boolean(anchorEl) ? "simple-popover" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePenIconClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ padding: "10px" }}
          >
            <ViknCheckboxGroup
              radios={[
                {
                  label: t("Employee Name"),
                  checked: customTable.employeeName,
                  onChange: () => handleTableHeadingSelection("employeeName"),
                },
                {
                  label: t("Total Tax"),
                  checked: customTable.showTax,
                  onChange: () => handleTableHeadingSelection("showTax"),
                },
              ]}
            />
          </Popover>
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={filterReport}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <ViknRadioGroup
                value={filterData.switchReport}
                radios={[
                  {
                    value: true,
                    label: t("Billwise"),
                  },
                  {
                    value: false,
                    label: t("Ledgerwise"),
                  },
                ]}
                handleChange={(e) =>
                  setFilterData((prev) => ({
                    ...prev,
                    switchReport: "true" === e.target.value,
                  }))
                }
              />
            </ListItem>

            <Divider />

            <Collapse in={filterData.switchReport} timeout="auto" unmountOnExit>
              {[
                <ListItem>
                  <VBDatePiker
                    fromDate={filterOptions.FromDate}
                    toDate={filterOptions.ToDate}
                    fromOnChange={(value) =>
                      setFilterOptions({
                        ...filterOptions,
                        FromDate: value,
                        dateRange: "Custom"
                      })
                    }
                    toOnChange={(value) =>
                      setFilterOptions({
                        ...filterOptions,
                        ToDate: value,
                        dateRange: "Custom"
                      })
                    }
                  />
                </ListItem>,

                <ListItem>
                  <VBSelect
                    value={filterOptions.dateRange}
                    options={["Custom", "This Week", "This Month", "This Year"]}
                    handleChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        dateRange: e.target.value,
                        FromDate: date_range(e.target.value).fromdate,
                        ToDate: date_range(e.target.value).todate,
                      })
                    }
                  />
                </ListItem>,
              ]}
            </Collapse>
            <Collapse
              in={!filterData.switchReport}
              timeout="auto"
              unmountOnExit
            >
              {[
                <ListItem sx={{ pb: 0 }}>
                  <ViknCheckboxGroup
                    // isBorder={false}
                    radios={[
                      {
                        checked: filterData.checkDefaultPeriod,
                        onChange: () => {
                          setFilterData((prev) => ({
                            ...prev,
                            checkDefaultPeriod: !prev.checkDefaultPeriod,
                            disabledPeriods: !prev.checkDefaultPeriod,
                          }));
                          setCustomePeriods({
                            first: 1,
                            second: 30,
                            third: 60,
                            fourth: 90,
                            fifth: 120,
                            sixth: 150,
                          });
                        },
                        label: t("Default periods"),
                      },
                    ]}
                  />
                </ListItem>,
                <ListItem>
                  <Box>
                    <Typography sx={{ fontSize: "14px", color: "green" }}>
                      Custom Periodes
                    </Typography>
                    <Grid
                      container
                      sx={{ mb: 1 }}
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {[
                        "first",
                        "second",
                        "third",
                        "fourth",
                        "fifth",
                        "sixth",
                      ].map((name, index, value) => (
                        <Grid item xs={6} key={name}>
                          <VBInputField
                            type="number"
                            disabled={
                              !(!filterData.checkDefaultPeriod && index !== 0)
                            }
                            value={customePeriods[name]}
                            onBlur={(e) =>
                              onChangeInputPeriod(
                                name,
                                e.target.value,
                                index === 0
                                  ? 0
                                  : parseInt(
                                      customePeriods[value[index - 1]],
                                      10
                                    ) + 1,
                                index === value.length - 1
                                  ? 364
                                  : parseInt(
                                      customePeriods[value[index + 1]],
                                      10
                                    ) - 1
                              )
                            }
                            onChange={(e) =>
                              onChangePeriodValue(name, e.target.value)
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>

                    <ViknBlueButton
                      disabled={loading.editPeriod}
                      onClick={() => editCustomePeriods()}
                      name={
                        loading.editPeriod ? (
                          <CircularProgress
                            size={21}
                            sx={{ color: "white" }}
                          />
                        ) : (
                          "Save"
                        )
                      }
                    />
                  </Box>
                </ListItem>,
              ]}
            </Collapse>

            <Divider />

            {generalSettings.EnableRoute && 
            <ListItem>
              <ViknAutoComplete
                placeholder={t("Routes")}
                value={filterOptions.routes}
                options={filterData.routeList}
                getOptionLabel={(option) =>
                  option.RouteName ? option.RouteName : ""
                }
                onInputChange={(e, newValue) => {
                  console.log(newValue);
                  setRoutSearch(newValue)
                }}
                onChange={(e, newValue) =>
                  setFilterOptions({
                    ...filterOptions,
                    routes: newValue,
                    customer: null,
                  })
                }
              />
            </ListItem>
            }

            <Collapse in={filterData.switchReport} timeout="auto" unmountOnExit>
              <ListItem>
                <ViknAutoComplete
                  placeholder={t("Supplier")}
                  value={filterOptions.customer}
                  
                  options={
                    filterOptions.routes
                      ? filterData.customerList.filter(
                          (i) => i.RouteID === filterOptions.routes.RouteID
                        )
                      : filterData.customerList
                  }
                  getOptionLabel={(option) => option?.PartyName}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      (option.PartyName && option.PartyName.toLowerCase().includes(inputValue.toLowerCase())) ||
                      (option.PartyCode && option.PartyCode.toLowerCase().includes(inputValue.toLowerCase()))
                    )
                  }
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.PartyCode}</Box>
                      <Box sx={{fontSize: "14px",}}>{option.PartyName}</Box>
                    </Box>
                  )}
                  onInputChange={(e, newValue) => {
                    console.log(newValue);
                    setPartySearch(newValue)
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions({
                      ...filterOptions,
                      customer: newValue,
                    })
                  }
                />
              </ListItem>
            </Collapse>

            <Collapse
              in={!filterData.switchReport}
              timeout="auto"
              unmountOnExit
            >
              <ListItem>
                <VBSelect
                  value={filterOptions.dateFilter}
                  options={["Due Date", "Voucher Date"]}
                  handleChange={(e) =>
                    setFilterOptions({
                      ...filterOptions,
                      dateFilter: e.target.value,
                    })
                  }
                />
              </ListItem>
            </Collapse>

            {customTable.employeeName &&

            <ListItem>
              <ViknAutoComplete
                placeholder={"Employee"}
                value={filterOptions.employee}
                options={filterData.employeeList}
                getOptionLabel={(option) => option?.FirstName}
                onInputChange={(e, newValue) => {
                  setEmployeeSearch(newValue)
                }}
                onChange={(e, newValue) =>
                  setFilterOptions({
                    ...filterOptions,
                    employee: newValue,
                  })
                }
              />
            </ListItem>
            }
          </List>
        }
      />
    </>
  );
}

export default CreditorAgeingReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  // width: "26%",
}));