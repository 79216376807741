import axios from "axios";
// import { openSnackbar } from "../../features/SnackBarslice";

export const sales_details_api = async function (body) {
  try {
    const { data } = await axios.post("api/v11/sales/get-details/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const create_sales_api = async function (body) {
  try {
    const { data } = await axios.post("api/v11/sales/sales-invoice/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const list_sales_api = async function (body) {
  try {
    const { data } = await axios.get("api/v11/sales/sales-invoice/", {
      params: body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const view_sales_api = async function (body) {
  try {
    const { data } = await axios.get("api/v11/sales/sales-invoice/", {
      params: body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const get_sales_details_api = async function (body) {
  try {
    const { data } = await axios.get(
      "api/v11/sales/get-sales-invoice-details/",
      {
        params: body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const edit_sales_api = async function (body) {
  try {
    const { data } = await axios.put("api/v11/sales/sales-invoice/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const delete_sales_api = async function (body) {
  try {
    const { data } = await axios.delete("api/v11/sales/sales-invoice/", {
      data: body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const delete_contacts_api = async function (body) {
  try {
    const { data } = await axios.post("api/v10/parties/delete/party/1/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const sales_invoice_single_print = async function (params) {
  try {
    const { data } = await axios("api/v10/settings/print-paper-new/", {
      params: params,
      headers: {
        "authorization" : ""
      },
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const sales_invoice_single_log = async function (body) {
  try {
    const { data } = await axios.post("api/v10/sales/single-sale-log/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const sales_invoice_single_payment = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/payments/billwise-details/payment/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const invoice_send_mail = async function (body) {
  try {
    const { data } = await axios.post("api/v10/settings/send-mail/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const view_social_media = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/companySettings/view/SocialMedia/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const sales_invoice_search = async function (body) {
  try {
    const { data } = await axios.post("api/v10/sales/sale-pagination/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const loyalty_customer = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/loyaltyCustomer/loyaltyCustomers/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const ledger_list_for_payment = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/accountLedgers/ledgerListforPayments/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const main_voucher = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/main/generate/voucherNo/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const ledger_listfor_payment = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/accountLedgers/ledgerListforPayments/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const sales_master = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/sales/view/salesMaster/"+body.unq_id+"/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};