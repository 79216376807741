import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagenation from "../../../Components/Utils/Pagenation";
import { blue } from "@mui/material/colors";
import styled from "@emotion/styled";
import VBInputField from "../../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../../Components/Utils/ViknAutoComplete";
import SearchReport from "../../../Components/Utils/SearchReport";
import { useDispatch, useSelector } from "react-redux";
import { MenuOptions } from "../../../Components/CommonComponents";
import ConfirmBox from "../../../Components/Utils/ConfirmBox";
import { openSnackbar } from "../../../features/SnackBarslice";
import CloseIcon from "@mui/icons-material/Close";

import {
  CreateProductGroupAPI,
  KitchenListAPI,
  ProductCategoryListAPI,
  ProductGroupAPI,
  ProductGroupDeleteAPI,
  ProductGroupDetailsAPI,
  ProductGroupSearchAPI,
  ProductGroupUpdateAPI,
  SetDefultProductAPI,
} from "../../../Api/Products/ProductApis";

import {
  Box,
  Paper,
  TableBody,
  TableRow,
  Typography,
  Checkbox,
  Modal,
  Button,
  IconButton,
} from "@mui/material";

import {
  CreateNewButton,
  DeleteButton,
} from "../../../Components/Utils/StyledButtons";

import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../Components/ReportTable/ReportTable";
import NavigationButtons from "../../../Components/ProductComponents/NavigationButtons";

function ProductGroup() {
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);

  const [pageNo, setPageNo] = useState(1);
  const [IsConfirmBox, setConfirmBox] = useState(false);
  const [createModal, setCreateModal] = useState(false);

  const [response, setResponse] = useState({
    data: [],
    count: null,
    selectedItems: [],
    isSelectedAll: false,
    productCategoryList: [],
    kitchenList: [],
  });

  const [uploadData, setUploadData] = useState({
    name: "",
    description: "",
    productCategory: null,
    kitchen: null,
  });

  const [isEditModal, setIsEditModal] = useState({
    state: false,
    uniq_id: null,
  });

  let userRoleSettings = useSelector((state) => state.userRollSettings);
  let permissions = userRoleSettings.filter((i) => i.name === "Brands");

  let createPermission = permissions[0].save_permission;
  let editPermission = permissions[0].edit_permission;
  let deletePermission = permissions[0].delete_permission;

  const optionList = [
    { name: "Edit", permission: editPermission },
    { name: "Delete", permission: deletePermission },
  ];

  const optionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = optionList.find((i) => i.name === name)?.permission;
      if (name === "Edit" && permission) {
        setCreateModal(true);
        setIsEditModal((prevState) => ({
          ...prevState,
          state: true,
          uniq_id: uniq_id,
        }));

        setCreateModal(true);
        setIsEditModal((prevState) => ({
          ...prevState,
          state: true,
          uniq_id: uniq_id,
        }));
      } else if (name === "Delete" && permission) {
        setResponse({ ...response, selectedItems: [uniq_id] });
        setConfirmBox(true);
      }
    }
  };

  const selectItems = (type, id) => {
    let data = [...response.selectedItems];
    let isSelectedAll = response.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (response.isSelectedAll) {
        data = [];
      } else {
        response.data.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }
    setResponse({
      ...response,
      selectedItems: data,
      isSelectedAll: isSelectedAll,
    });
  };

  const fetchData = async () => {
    let payload = {
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      page_no: pageNo,
      items_per_page: 15,
    };

    const productCatogeryListResponse = await ProductCategoryListAPI({
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
    });
    if (productCatogeryListResponse.StatusCode === 6000) {
      setResponse((prevState) => ({
        ...prevState,
        productCategoryList: productCatogeryListResponse.data,
      }));
    }

    const kitchenListResponse = await KitchenListAPI({
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
    });
    if (kitchenListResponse.StatusCode === 6000) {
      setResponse((prevState) => ({
        ...prevState,
        kitchenList: kitchenListResponse.data,
      }));
    }
    if (isEditModal.state && isEditModal.uniq_id) {
      const responseData = await ProductGroupDetailsAPI(
        {
          CreatedUserID: user_id,
          BranchID: BranchID,
          CompanyID: CompanyID,
        },
        isEditModal.uniq_id
      );

      if (responseData.StatusCode === 6000) {
        const productCategoryList = response.productCategoryList.find(
          (data) =>
            data.ProductCategoryID === responseData.data.ProductCategoryID
        );

        const kitchenList = response.kitchenList.find(
          (data) => data.kitchenList === responseData.data.kitchenListID
        );

        setUploadData((prevState) => ({
          ...prevState,
          name: responseData.data.GroupName,
          description: responseData.data.Notes,
          productCategory: productCategoryList,
          kitchen: kitchenList,
        }));
      }
    } else {
      const responseData = await ProductGroupAPI(payload);

      if (responseData.StatusCode === 6000) {
        setResponse((prevState) => ({
          ...prevState,
          data: responseData.data,
          count: responseData.count,
        }));
      }
    }
  };

  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      selecte_ids: response.selectedItems,
    };
    let responseData = await ProductGroupDeleteAPI(
      { ...payload },
      ...response.selectedItems
    );
    
    if (responseData.StatusCode === 6000) {
      fetchData();
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      fetchData();
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const onClose = () => {
    setCreateModal(false);
    setIsEditModal((prevState) => ({ ...prevState, state: false }));
    setUploadData((prevState) => ({ ...prevState, name: "", description: "" }));
  };

  const submitCreate = async (e) => {
    e.preventDefault();
    let payload = {
      GroupName: uploadData.name,
      CategoryID: uploadData.productCategory.ProductCategoryID,
      Notes: uploadData.description,
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      kitchen: uploadData.kitchen.id,
    };

    if (isEditModal.state) {
      const responseData = await ProductGroupUpdateAPI(
        payload,
        isEditModal.uniq_id
      );

      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message || "Group edited successfully",
            severity: "success",
          })
        );
        setCreateModal(false);
        setIsEditModal((prevState) => ({ ...prevState, state: false }));
        setUploadData((prevState) => ({
          ...prevState,
          name: "",
          description: "",
          productCategory: null,
          kitchen: null,
        }));
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    } else {
      const responseData = await CreateProductGroupAPI(payload, dispatch);
      setIsEditModal((prevState) => ({
        ...prevState,
        state: false,
      }));
      if (responseData.data.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData.data?.message || "Group Created Successfully",
            severity: "success",
          })
        );
        setCreateModal(false);
        setUploadData((prevState) => ({
          ...prevState,
          name: "",
          description: "",
          productCategory: null,
          kitchen: null,
        }));
        fetchData();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData.data?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    }
  };

  const selectDefultBranch = async (id) => {
    let payload = {
      BranchID: BranchID,
      CompanyID: CompanyID,
      id: id,
      value: true,
    };
    const responseData = await SetDefultProductAPI(payload);
    fetchData();
  };

  const searchGroup = async (product_name) => {
    let payload = {
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
      PriceRounding: 2,
      product_name: product_name,
      length: 1,
    };
    const responseData = await ProductGroupSearchAPI(payload);
    if (responseData.StatusCode === 6000) {
      setResponse((prevState) => ({ ...prevState, data: responseData.data }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo, isEditModal.state]);

  return (
    <>
      <Paper sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "26px",
            py: "15px",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems:"Center" }}>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              Product Groups
            </Typography>
            <SearchReport placeholder={"Search Record"} onChange={(e) => searchGroup(e.target.value)}/>
          </Box>
          <Box sx={{ display: "flex" }}>
            <DeleteButton t={t} onClick={() => setConfirmBox(true)} />
            <CreateNewButton
              t={t}
              onClick={() => {
                if (createPermission) {
                  setCreateModal(true);
                }
              }}
            />
            <Pagenation
              page_no={pageNo}
              totalItem={response.count}
              setPage_no={setPageNo}
            />
          </Box>
        </Box>
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader
              sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
            >
              <Checkbox
                sx={{
                  padding: "2px",
                  margin: 0,
                  color: blue[400],
                  "&.Mui-checked": {
                    color: blue[400],
                  },
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                }}
                checked={response.isSelectedAll}
                onChange={() => selectItems("all")}
              />
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Serial No")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Group Name")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Category Name")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Notes")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Default")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}> </VBTableCellHeader>
          </VBTableHeader>
          <TableBody>
            {response.data.map((item, index) => (
              <TableRow key={index}>
                <VBTableCellBody
                  sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
                >
                  <Checkbox
                    checked={
                      response.selectedItems.includes(item.id) ? true : false
                    }
                    onChange={() => {
                      selectItems("not", item.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {(pageNo - 1) * appSetting.itemPerPage + index + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {item.GroupName}
                </VBTableCellBody>{" "}
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {item.ProductCategoryName || "-"}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {item.Notes || "-"}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  <Checkbox
                    onClick={() => selectDefultBranch(item.id)}
                    checked={item.IsDefault}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer", textAlign: "right" }}>
                  <MenuOptions
                    OptionList={optionList}
                    OptionOnClick={optionOnClick}
                    uniq_id={item.id}
                  />
                </VBTableCellBody>
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>
        </VBTableContainer>
        <ConfirmBox
          heading={"Confirm to delete?"}
          message={"Once you delete, you will not get it back!"}
          open={IsConfirmBox}
          setOpen={setConfirmBox}
          confirmFunction={deleteData}
        />
        <Modal
          open={createModal}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={submitCreate}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                  borderBottom: "2px solid #F5F5F5",
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  {isEditModal.state
                    ? "Edit Product Group"
                    : "Add Product Group"}
                </Typography>

                <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
              </Box>
              <VBInputField
                label="Name"
                required
                value={uploadData.name}
                onChange={(e) =>
                  setUploadData((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />
              <ViknAutoComplete
                label="Category"
                required
                placeholder="Type here"
                value={uploadData.productCategory}
                options={response.productCategoryList}
                onChange={(event, newValue) =>
                  setUploadData((prevState) => ({
                    ...prevState,
                    productCategory: newValue,
                  }))
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.CategoryName}
              />
              <ViknAutoComplete
                label="Kitchen"
                placeholder="Type here"
                value={uploadData.kitchen}
                options={response.kitchenList}
                onChange={(event, newValue) =>
                  setUploadData((prevState) => ({
                    ...prevState,
                    kitchen: newValue,
                  }))
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.KitchenName}
              />
              <VBInputField
                label="Description"
                value={uploadData.description}
                onChange={(e) =>
                  setUploadData((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }))
                }
              />
              <Box sx={{ marginTop: "16px" }}>
                <BlueButton sx={{ px: 3, width:"100%"}} type="submit">
                  Save
                </BlueButton>
                <IconButton sx={{ color: "#0A9EF3" }}></IconButton>
              </Box>
            </form>
          </Box>
        </Modal>
      </Paper>
      <NavigationButtons />
    </>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};
const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));
export default ProductGroup;
