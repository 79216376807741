import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import { useTranslation } from "react-i18next";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import Pagenation from "../../../../Components/Utils/Pagenation";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import moment from "moment";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import FilterLable from "../../../../Components/Utils/FilterLable";
import { callProductWarehouseReport, callUpdateLedgerBalance } from "../../../../Api/Reports/InventoryApi";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Paper,
  Snackbar,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import CachedIcon from '@mui/icons-material/Cached';import {
  CallChartOfAccount,
} from "../../../../Api/Reports/CommonReportApi";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { Padding } from "@mui/icons-material";
import { BASE_V11_URL } from "../../../../generalVeriable";
import ReplayIcon from '@mui/icons-material/Replay';
function ChartOfAccountReport() {
  // ==================== global states ====================
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const isAdmin = BranchID === 1 ? true : false;
  console.log(EnableBranch, BranchID);

  // ==================== variables ====================

  const [t] = useTranslation("common");

  // ==================== states ====================

  const [loading, setLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [state, setState] = useState({
    pageNo: 1,
    anchorEl: null, // popover
    openFilter: false,
    clearFilter: false,
    customTable: {
      purchaseReturn: { isActive: false },
      salesReturn: { isActive: false },
    },
  });

  const [dataList, setDataList] = useState({
    productWarehouseResponse: {
      data: [],
      count: 0,
    },
  });

  const [filterData, setFilterData] = useState({
    AccountGroupList: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    fromDate: moment(),
    toDate: moment(),
    ProductObj: [],
    WarehouseObj: [],
    userObj: [],
    IsSelectedAllBranch: false,
    SelectedBranches: [{ BranchID: BranchID }],
    BranchList: [],
  });


  // ==================== functions ====================

  const handleFilterClose = () => {
    setState((pervState) => ({
      ...pervState,
      openFilter: false,
    }));
  };

  const handleFilter = () => {
    setPageNo(1);
  };

  const handleDefaultFilter = () => {
    setFilterOptions({
      fromDate: moment(),
      toDate: moment(),
    });
    setPageNo(1);
    setState((prevState) => ({
      ...prevState,
      clearFilter: !prevState.clearFilter,
    }));
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      debounceOnChange(value);
    }
  };
  const handleAutoComplete = function (e, value, name) {
    if (value) {
      setFilterOptions({ ...filterOptions, [name]: value });
    }
  };

  const branchCheckChange = (name) => {
    let SelectedBranches = [];
    if (!filterOptions[name] === true) {
      filterOptions.BranchList.map((i) => {
        if (SelectedBranches.includes(i) === false) {
          SelectedBranches.push(i);
        }
      });
      setFilterOptions({
        ...filterOptions,
        SelectedBranches,
        [name]: !filterOptions[name],
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        [name]: !filterOptions[name],
      });
    }
  };

  // ==================== API Fetching ====================

  const checkTaskStatus = async (taskId) => {
    const response = await fetch(
      `${BASE_V11_URL}reports/check-task-status/` + taskId + "/"
    );
    const data = await response.json();
    if (data.status === "completed") {
      if (data.result.StatusCode === 6000) {
        setDataList((prevState) => ({
          ...prevState,
          productWarehouseResponse: {
            ...prevState.productWarehouseResponse,
            data: data.result.data,
            count: data.result.count,
          },
        }));
        setLoading(false);
      }
    } else if (data.status === "pending") {
      setLoading(true);
      // setTimeout(() => checkTaskStatus(taskId), 3000); // Poll every 1 second
    } else {
    }
  };

  const UpdateLedgerBalance = async () => {
    const UpdateLedgerBalance = await callUpdateLedgerBalance({
      CompanyID: CompanyID,
      BranchID: filterOptions.SelectedBranches.map((i) => i.BranchID),
    });

    if (UpdateLedgerBalance.StatusCode === 6000) {
      // Snackbar(UpdateLedgerBalance)
      // checkTaskStatus(productWarehouseResponse.task_id);
    }
  };

  // const filterApi = async function () {
  //   const payload = {
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     PriceRounding: Number(PriceDecimalPoint),
  //     CreatedUserID: user_id,
  //     show_all: true,
  //     items_per_page: 10,
  //     page_no: 1,
  //     search: "",
  //     PartyType: "1",
  //   };
  
  //   try {
  //     // Call the API
  //     const response = await CallChartOfAccount(payload);
  
  //     // Initialize the response object
  //     let AccountGroup = []
  
  //     // Handle the API response
  //     if (response.StatusCode === 6000) {
  //       const flatAccountGroup = response.AccountGroup || [];
  
  //       // Transform the data into a hierarchical structure
  //       const accountGroupMap = {};
  //       const topLevelGroups = [];
  
  //       // Create a map for easy lookup
  //       flatAccountGroup.forEach((group) => {
  //         accountGroupMap[group.AccountGroupID] = { ...group, AccountGroup: [] };
  //       });
  
  //       // Nest child groups under their parents
  //       flatAccountGroup.forEach((group) => {
  //         if (group.AccountGroupUnder && accountGroupMap[group.AccountGroupUnder]) {
  //           accountGroupMap[group.AccountGroupUnder].AccountGroup.push(
  //             accountGroupMap[group.AccountGroupID]
  //           );
  //         } else {
  //           // Add to top-level groups if no parent
  //           topLevelGroups.push(accountGroupMap[group.AccountGroupID]);
  //         }
  //       });
  
  //       // Update the state with the hierarchical structure
  //       setFilterData((prevState) => ({
  //         ...prevState,
  //         AccountGroupList: topLevelGroups,
  //       }));
  //     } else {
  //       console.log(`API returned StatusCode ${response.StatusCode}`);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while making the API call", error);
  //   }
  // };
  
  // ==================== useEffect ====================
  const filterApi = async function () {
    setLoading(true)
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    };
  
    try {
      // Call the API
      const response = await CallChartOfAccount(payload);
  
      // Initialize the response object
      let AccountGroup = [];
  
      // Handle the API response
      if (response.StatusCode === 6000) {
        const flatAccountGroup = response.AccountGroup || [];
  
        // Transform the data into a hierarchical structure
        const accountGroupMap = {};
        const topLevelGroups = [];
  
        // Create a map for easy lookup
        flatAccountGroup.forEach((group) => {
          accountGroupMap[group.AccountGroupID] = { ...group, AccountGroup: [] };
        });
  
        // Nest child groups under their parents
        flatAccountGroup.forEach((group) => {
          if (group.AccountGroupUnder && accountGroupMap[group.AccountGroupUnder]) {
            accountGroupMap[group.AccountGroupUnder].AccountGroup.push(
              accountGroupMap[group.AccountGroupID]
            );
          } else {
            // Add to top-level groups if no parent
            topLevelGroups.push(accountGroupMap[group.AccountGroupID]);
          }
        });
  
        // Function to calculate balances recursively
        const calculateBalances = (groups) => {
          return groups.map((group) => {
            let totalBalance = 0;
  
            // Sum up balances from AccountLedgers
            if (group.AccountLedgers) {
              totalBalance += group.AccountLedgers.reduce((sum, ledger) => sum + ledger.Balance, 0);
            }
  
            // Process nested AccountGroups
            if (group.AccountGroup && group.AccountGroup.length > 0) {
              const nestedBalances = calculateBalances(group.AccountGroup);
              totalBalance += nestedBalances.reduce((sum, balance) => sum + balance, 0);
            }
  
            // Add Balance property to the group
            group.Balance = totalBalance;
            return totalBalance;
          });
        };
  
        // Start the balance calculation for the top-level groups
        calculateBalances(topLevelGroups);
  
        // Update the state with the hierarchical structure and calculated balances
        setFilterData((prevState) => ({
          ...prevState,
          AccountGroupList: topLevelGroups,
        }));
      } else {
        console.log(`API returned StatusCode ${response.StatusCode}`);
      }
    } catch (error) {
      console.error("An error occurred while making the API call", error);
    }
    setLoading(false)

  };
  
  useEffect(() => {
    filterApi();
  }, []);
  // useEffect(() => {
  //   FetchData();
  // }, [state.clearFilter, pageNo]);
  // useEffect(() => {
  //   if (productSearch) {
  //     searchProduct();
  //   }
  // }, [productSearch]);

  // ============= to increase performance ============
  // ====================use memo======================
  const debounceOnChange = React.useMemo(
    () =>
      debounce((value) => {
        setProductSearch(value);
      }, 400),
    []
  );
  // const AccountGroupRow = ({ group, level = 0 }) => (
  //   <>
  //     {/* Render the main Account Group row */}
  //     <TableRow>
  //       <VBTableCellBody
  //         sx={{px: `${20 + level * 20}px`, }}
  //       >
  //         <Box sx={{display:"flex"}}>
  //           <Button size="small"  sx={{padding:0,margin:0,minWidth:`${18 - level+5}px`}}>
  //           <ExpandMoreIcon size="large" fontSize="inherit" sx={{height:`${18 - level}px`,width:`${18 - level}px`,fontSize:`${18 - level}px`,minWidth:`${18 - level}px`}}/>
  //           <ChevronRightIcon size="large" fontSize="inherit" sx={{height:`${18 - level}px`,width:`${18 - level}px`,fontSize:`${18 - level}px`,minWidth:`${18 - level}px`}}/>
  //           </Button>
  //           <Typography sx={{
  //             fontWeight: "bold",
  //             fontSize: `${18 - level}px`, 
  //           }}>

  //           {group.AccountGroupName}
  //           </Typography>
  //         </Box>
  //       </VBTableCellBody>
  //       <VBTableCellBody sx={{ textAlign: "left" }}>{" "}</VBTableCellBody>
  //       <VBTableCellBody sx={{ textAlign: "right" }}>{parseFloat(group.Balance).toFixed(2)}</VBTableCellBody>
  //       <VBTableCellBody sx={{ textAlign: "left" }}>{group.CreatedUser}</VBTableCellBody>
  //       <VBTableCellBody sx={{ textAlign: "left" }}>{group.CreatedDate}</VBTableCellBody>
  //     </TableRow>

  //     {group.AccountLedgers?.map((ledger, ledgerIndex) => (
  //       <TableRow key={`ledger-${ledgerIndex}`}>
  //         <VBTableCellBody sx={{ px: `${40 + level * 20}px`, fontSize: "13px" }}>{ledger.LedgerName}</VBTableCellBody>
  //         <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>{ledger.LedgerCode}</VBTableCellBody>
  //         <VBTableCellBody sx={{ textAlign: "right", fontSize: "13px" }}>{ledger.Balance}</VBTableCellBody>
  //         <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>{ledger.CreatedUser}</VBTableCellBody>
  //         <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>{ledger.CreatedDate}</VBTableCellBody>
  //       </TableRow>
  //     ))}
  

  //     {group.AccountGroup?.map((childGroup, childIndex) => (
  //       <React.Fragment key={`child-group-${childIndex}`}>
  //         <AccountGroupRow group={childGroup} level={level + 1} />
  //       </React.Fragment>
  //     ))}
  //   </>
  // );
  const AccountGroupRow = ({ group, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleGroup = () => {
      setIsOpen((prevState) => !prevState);
    };
  
    return (
      <>
        {/* Render the main Account Group row */}
        <TableRow>
          <VBTableCellBody sx={{ px: `${20 + level * 20}px` }}>
            <Box onClick={toggleGroup} sx={{ display: "flex", alignItems: "center" }}>
              {/* Toggle button with animation */}
              {(group.AccountLedgers?.length>0 ||group.AccountGroup?.length>0 )&&

                <Button
                size="small"
                sx={{ padding: 0, margin: 0, minWidth: 0 }}
                // onClick={toggleGroup}
                >
                <ExpandMoreIcon
                  sx={{
                    height: `${20 - level}px`,
                    width: `${20 - level}px`,
                    fontSize: `${20 - level}px`,
                    minWidth: `${20 - level}px`,
                    transition: "transform 0.3s ease",
                    transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                  }}
                  />
              </Button>
                }
  
              {/* Group Name */}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: `${18 - level}px`,
                  marginLeft: "8px",
                }}
                
              >
                {group.AccountGroupName}
                
              </Typography>
            </Box>
          </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "left" }}>{" "}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}>
            {parseFloat(group.Balance).toFixed(2)}
          </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "left" }}>{group.CreatedUser}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "left" }}>{group.CreatedDate}</VBTableCellBody>
        </TableRow>
  
        {/* Child Ledgers */}
        {isOpen &&
          group.AccountLedgers?.map((ledger, ledgerIndex) => (
            <TableRow key={`ledger-${ledgerIndex}`}>
              <VBTableCellBody
                sx={{ px: `${40 + level * 25}px`, fontSize: "13px" }}
              >
                {ledger.LedgerName}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>
                {ledger.LedgerCode}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "right", fontSize: "13px" }}>
                {ledger.Balance}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>
                {ledger.CreatedUser}
              </VBTableCellBody>
              <VBTableCellBody sx={{ textAlign: "left", fontSize: "13px" }}>
                {ledger.CreatedDate}
              </VBTableCellBody>
            </TableRow>
          ))}
  
        {/* Nested Account Groups */}
        {isOpen &&
          group.AccountGroup?.map((childGroup, childIndex) => (
            <React.Fragment key={`child-group-${childIndex}`}>
              <AccountGroupRow group={childGroup} level={level + 1} />
            </React.Fragment>
          ))}
        
      </>
    );
  };
  const AccountGroupTable = ({ accountGroups }) => {
    return (
      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader>{t("Account Group Name")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>{t("Code")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Balance")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Created User")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>{t("Created Date")}</VBTableCellHeader>
        </VBTableHeader>
        <TableBody>
          {/* Loop through top-level Account Groups */}
          {accountGroups.map((group, groupIndex) => (
            <React.Fragment key={`group-${groupIndex}`}>
              <AccountGroupRow group={group} />
            </React.Fragment>
          ))}
          <VBTableRowNull length = {accountGroups.data?.length}/>
        </TableBody>
      </VBTableContainer>
    );
  };
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {t("Chart of Accounts")}
      </Typography>
      </FlexBox>
      <FlexBox className="right">
      <Button onClick={()=>{filterApi()}}><CachedIcon
        sx={{
          fontSize: "24px",
          animation: loading
            ? "rotate 0.5s linear infinite"
            : "none",
          "@keyframes rotate": {
            "0%": {
              transform: "rotate(360deg)",
            },
            "100%": {
              transform: "rotate(0deg)",
            },
          },
        }}
      /></Button>
      </FlexBox>
      </Box>
      <AccountGroupTable accountGroups={filterData.AccountGroupList} />
    </Paper>
      {/* ======================  filter section =====================*/}

      <ViknDrawer
        open={state.openFilter}
        handleClose={handleFilterClose}
        onFilter={handleFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(date) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    fromDate: date,
                  }))
                }
                toOnChange={(date) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    toDate: date,
                  }))
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Product Wise")}
                filterName={t("Product Wise")}
                loading={loadingProduct}
                options={
                  filterData.productWiseList ? filterData.productWiseList : []
                }
                getOptionLabel={(option) => option.ProductName || ""}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (option) =>
                      (option.ProductName &&
                        option.ProductName.toLowerCase().includes(
                          inputValue.toLowerCase()
                        )) ||
                      (option.ProductCode &&
                        option.ProductCode.toLowerCase().includes(
                          inputValue.toLowerCase()
                        ))
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Box sx={{ fontSize: "14px", color: "#797979", mr: 2 }}>
                      {option.ProductCode}
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>{option.ProductName}</Box>
                  </Box>
                )}
                onInputChange={handleInputChange}
                onChange={(e, v) => handleAutoComplete(e, v, "ProductObj", -1)}
                value={filterOptions.ProductObj}
              />
            </ListItem>
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Warehouse")}
                filterName={t("Warehouse")}
                loading={isLoadingUsers}
                options={
                  filterData.warehouseList ? filterData.warehouseList : []
                }
                getOptionLabel={(option) => option.name || ""}
                name="WarehouseObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, WarehouseObj: {} });
                  }
                }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "WarehouseObj", -1)
                }
                value={filterOptions.WarehouseObj}
              />
            </ListItem>
            {EnableBranch && BranchID === 1 && (
              <>
                <ListItem>
                  <ViknCheckboxGroup
                    radios={[
                      {
                        label: t("All Branches"),
                        checked: filterOptions.IsSelectedAllBranch,
                        onChange: () =>
                          branchCheckChange("IsSelectedAllBranch"),
                      },
                    ]}
                  />
                </ListItem>
                <ListItem>
                  <ViknAutoComplete
                    disabled={filterOptions.IsSelectedAllBranch}
                    multiple
                    value={filterOptions.SelectedBranches}
                    placeholder={"Select Branches"}
                    options={filterOptions.BranchList}
                    getOptionLabel={(option) =>
                      option.NickName || option.BranchName
                    }
                    onChange={(e, newValue) =>
                      handleAutoComplete(e, newValue, "SelectedBranches")
                    }
                  />
                </ListItem>
              </>
            )}
          </List>
        }
      />
    </>
  );
}

export default ChartOfAccountReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
}));
