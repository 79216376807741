import React from 'react';
import { Typography, Box, Table, TableBody, TableCell, TableRow, Checkbox } from '@mui/material';

function SummaryDetails({ onChange,state,is_manual_roundoff,setIs_manual_roundoff,EnableForeignCurrency,EnableShippingCharge,Vat,Gst }) {
  const items = [
    { label: "Total Items", value: state.TotalQty,show:true  },
    { label: "Gross Amount", value: state.TotalGrossAmt,show:true  },
    { label: "Total Discount", value: state.TotalDiscount,show:true  },
    { label: "Total Tax", value: state.TotalTax,show:Vat  },
    { label: "Total SGST", value: state.TotalSGST,show:state.TotalSGST > 0 ? true:false },
    { label: "Total CGST", value: state.TotalCGST,show:state.TotalCGST > 0 ? true:false  },
    { label: "Total IGST", value: state.TotalIGST,show:state.TotalIGST > 0 ? true:false  },
    { label: "Shipping Charge", value: state.ShippingCharge,show:EnableShippingCharge && (state.Treatment?.value != "7" && state.Treatment?.value != "4")},
    { label: "Shipping Tax", value: state.ShippingTaxAmount,show:EnableShippingCharge && (state.Treatment?.value != "7" && state.Treatment?.value != "4")},
    { label: "Net Total", value: state.NetTotal,show:true  },
    { label: `Net Total (${state.AccountLedger?.CurrencySymbol !== undefined ? state.AccountLedger?.CurrencySymbol : ''})` , value: state.NetTotalFc,show:EnableForeignCurrency && (state.Treatment?.value === "7" || state.Treatment?.value === "4") ? true : false},
    { label: "RoundOff", value: state.RoundOff,name:"RoundOff",show:true  },
    { label: "Grand Total", value: state.GrandTotal, isGrandTotal: true,show:true  },
    { label: `Grand Total (${state.AccountLedger?.CurrencySymbol !== undefined ? state.AccountLedger?.CurrencySymbol : ''})`, value: state.GrandTotalFc, isGrandTotal: true,show:EnableForeignCurrency && (state.Treatment?.value === "7" || state.Treatment?.value === "4") ? true : false},
  ];
  
  let Data = items.filter((i)=> i.show === true)

  return (
    <Box width="320px" p={2} borderRadius="8px">
      <Table>
        <TableBody>
          {Data.map((item, index) => (
            <TableRow key={index} style={{background:"none"}}>
              <TableCell sx={{ borderBottom: 'none',padding:"5px"}}>
                <Typography
                  sx={{ fontSize: item.isGrandTotal ? "18px" : "13px", fontWeight: "500" }}
                >
                  {item.label}
                  {item?.name === "RoundOff" ? (<><Checkbox checked={is_manual_roundoff} onChange={()=>{setIs_manual_roundoff(!is_manual_roundoff)}} size="small" label="Manaual"  /> <span style={{fontWeight:"200"}}>Manual</span> </>):null}
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none',padding:"5px" }}>
                <Typography
                  sx={{ fontSize: item.isGrandTotal ? "15px" : "13px", fontWeight: "500" }}
                >
                  -
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: 'none',padding:"5px" }}>
                {item?.name === "RoundOff" && is_manual_roundoff ? (
                <input onChange={onChange} name="RoundOff" style={{width:"50px"}} type='number' value={Number(item.value || 0)}></input> 
                ):(
                <Typography sx={{ fontSize: item.isGrandTotal ? "15px" : "13px", fontWeight: "500" }}>{Number(item.value || 0).toFixed(2)}</Typography>
                )}

                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default SummaryDetails;
