import axios from "axios";


export const CallPurchaseDetails = async function (body) {
    try {
        const { data } = await axios.post("api/v11/purchase/get-details/", {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Purchase Get Details Api",error);
        return error
    }
}
export const list_purchase_api = async function (body) {
    try {
      const { data } = await axios.get("api/v11/purchase/purchase-invoice/", {
        params: body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error;
    }
  };

export const delete_puchase_api = async function (body) {
  try {
    const { data } = await axios.delete("api/v11/purchase/purchase-invoice/", {
      data: body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

export const CallToPurchase = async function (body) {
  try {
    const { data } = await axios.post("api/v11/purchase/purchase-invoice/", {
      ...body,
    });
    return data
  } catch (error) {
    console.log("error on Sales return API", error);
    return error
  }
}

// Get Sales Return Master Api
export const view_Purchase_api = async function (body) {
  
  try {
    const { data } = await axios.get("api/v11/purchase/purchase-invoice/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on Sales Return Master view  API", error);
    return error
  }
};

// Getting Sales Return details Api
export const get_purchase_details_Api = async function (body) {
  try {
    const { data } = await axios.get("api/v11/purchase/get-purchase-details/", {
      params:body,
    });
    return data;
  } catch (error) {
    console.log("error on  Sales Return details Api", error);
    return error
  }
};

export const edit_purchase_api = async function (body) {
  
  try {
    const { data } = await axios.put("api/v11/purchase/purchase-invoice/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error
  }
};

export const LedgerDetails = async function (body) {
  try {
      const { data } = await axios.post("api/v11/accountLedgers/ledgerListByID/", {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on getting ledger Api",error);
      return error
  }
}


export const PurchaseMultipleExpense = async function (body) {
  try {
    const { data } = await axios.post("api/v11/expense/multiple-expense-new/", {
      ...body,
    });
    return data
  } catch (error) {
    console.log("error on Expense return API", error);
    return error
  }
}

export const PurchaseMultipleEditExpense = async function (body) {
  try {
    const { data } = await axios.post("api/v11/expense/multiple-expense-edit/", {
      ...body,
    });
    return data
  } catch (error) {
    console.log("error on Expense return API", error);
    return error
  }
}