import React, { useState, useEffect, useCallback } from "react";
import { Box, Paper, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ClearButton,
  ListButton,
  LoadingButton,
  SaveButton,
} from "../../Components/Utils/StyledButtons";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
} from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import MyGrid from "./Details";
import { debounce } from "../../Function/comonFunction";
import SummaryDetails from "./SummaryDetails";
import NotesTextArea from "../../Components/Utils/TextArea";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SalesEstimateDetails,
  LedgerDetails,
  SalesEstimateCreate,
  saleEstimate_details,
  EditSalesEstimateList,
  SalesEstimateList,
} from "../../Api/Sales/SalesEstimateApis";
import {
  place_of_supply_api,
  ledger_details_api,
  billwise_datas,
  product_search_new_api,
  get_pricelist_list_new_api,
  get_single_pricelist_new_api,
  get_product_by_barcode_api,
  get_VoucherNo,
  CustomiseTableApi,
  get_product_history_api,
} from "../../Api/CommonApi/CommonApis";
import {
  SalesLineCalc,
  SalesInvoiceCalc,
  SalesEstimateValidation,
} from "./functions";
import { CallEmployees } from "../../Api/Reports/CommonReportApi";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";
import { AddressModal, HistoryModal } from "../../Components/CommonForms";
import { create_user_address, list_user_address } from "../../Api/User/User";

const CreateSaleEstimate = () => {
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const uniq_id = location.state?.uniq_id ?? params.get("unq_id");
  const is_edit = location.state?.is_edit ?? params.get("is_edit");
  const is_convert = location.state?.is_convert || false
  console.log(location.state, "######### 📍 📍 📍 ||||||");
  

  const [is_manual_roundoff, setIs_manual_roundoff] = useState(false);
  const [IsSalesHistory, setIsSalesHistory] = useState(false);
  const [IsPurchaseHistory, setIsPurchaseHistory] = useState(false);

  const [t, i18n] = useTranslation("common");
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    PriceCategory,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    EnableEinvoiceKSA,
    RoundOffSales,
    AvoidLedgerwiseFilterInSalesHistory
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  let navigate = useNavigate();
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  const [openCustomVoucher, setopenCustomVoucher] = useState(false);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const [IsBilling, setIsBilling] = useState(false);

  let user_role_settings = useSelector((state) => state.userRollSettings);

  let sales_history_permission = user_role_settings.filter((i) => i.name === "Sales History");
  let purchase_history_permission = user_role_settings.filter((i) => i.name === "Purchase History");

  let sales_history_view_permission = sales_history_permission[0].view_permission
  let purchase_history_view_permission = purchase_history_permission[0].view_permission

  const { user_id } = useSelector((state) => state.user);
  const { Country, State } = useSelector((state) => state.companyDetails);
  const CompanyState = State
  const dispatch = useDispatch();

  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "dashboard/sales-order-list",
      domain: "books",
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];



  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData: [],
    CashList: [],
    BankList: [],
    CountryData: [],
    EmployeeData: [],
    TaxCategoryData: [],
    UserTableData: [],
    WarehouseData: [],
    PlaceOfSupplyData: [],
    PriceCategoryData: [],
    ProductList: [],
    ProductCodeList: [],
    BillWiseDatas: [],
    SalesHistory:[],
    PurchaseHistory:[]
  });

  console.log(dataState.TaxCategoryData, "Same as posivle");
  
  const [state, setState] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    Employee: null,
    // Warehouse: null,
    PriceCategory: null,
    Treatment: null,
    PlaceOfSupply: null,
    Date: DefaultDate,
    ReffNo: "",
    RefferenceBillDate: DefaultDate,
    BillDiscPercent: 0,
    BillDiscAmt: 0,
    CashAmount: 0,
    BankAmount: 0,
    Notes: "",
    //Total
    Warehouse: location.state?.WarehouseID ? { WarehouseID : location.state?.WarehouseID} : null,
    TotalQty: 0,
    TotalGrossAmt: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    NetTotal: 0,
    TotalTaxableAmount: 0,
    TaxTaxableAmount: 0,
    NonTaxTaxableAmount: 0,
    GrandTotal: 0,
    LedgerValue: "",
    RoundOff: "0",
    VAT_Treatment: "",
    TaxType:VAT ? "VAT" : "GST Intra-state B2C",
    TaxID:VAT ? 32 : 22,
    TotalSGST:0,
    TotalCGST:0,
    TotalIGST:0,
    BillingAddress:null,
  });

  //when you adding any field here add in AddLine and clearData function tooo
  let DataListItem = {
    ProductCode: null,
    BarCode: "",
    Product: null,
    Description: "",
    Unit: null,
    UnitList: [],
    Stock: "",
    Qty: "",
    FQty: "",
    Rate: "",
    AvgCost: "",
    Tax: null,
    InclusivePrice: "",
    GrossAmount: "",
    DiscountPerc: "",
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: "",
    MRP: "",
    PurchasePrice: "",
    BatchCode: "",
    MinimumSalesPrice: "",
    AverageCost: "",
    TaxableAmount: 0,
    NonTaxableAmount: 0,
    ProductImage:null,
    IGSTAmount : 0,
    IGSTPerc : 0,
    CGSTAmount : 0,
    CGSTPerc : 0,
    SGSTAmount : 0,
    SGSTPerc : 0,

  };
  
  const [DataList, setDataLists] = useState([DataListItem]);
  console.log("DataLists", DataList);
  
  const [defaultValue, setDefaultValue] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    PriceCategory: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    // ShippingTax: null,
  });

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });
  const MasterCalcFields = [
    "BillDiscPercent",
    "BillDiscAmt",
    "CashAmount",
    "BankAmount",
    "RoundOff",
  ];
  const [OpenBillWise, setOpenBillWise] = useState(false);
  const [IsBillwise, setBillwise] = useState(false);
  const is_customer =
    state.AccountLedger?.AccountGroupUnder !== 9 &&
    state.AccountLedger?.AccountGroupUnder !== 8;

  const [tableSettings, setTableSettings] = useState({
    index: true,
    PurchasePrice:false,
    AverageCost:false,
    ProductImage:true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductImage:false,
    PurchasePrice:false,
    AverageCost:false,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Description: true,
    Unit: true,
    Qty: true,
    FQty: true,
    Rate: true,
    Tax: true,
    InclusivePrice: true,
    GrossAmount: true,
    DiscountPerc: true,
    DiscountAmount: true,
    TaxAmount: true,
    Amount: true,
    Activity: true,
  });
  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    PurchasePriceInSales,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    PriceCategory,
  };

  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular", is_gst: true },
    { value: "1", name: "Registered Business - Composition", is_gst: true },
    { value: "2", name: "Consumer", is_gst: true, default: true },
    { value: "4", name: "Overseas", is_gst: true },
    { value: "5", name: "Special Economic Zone", is_gst: true },
    { value: "6", name: "Deemed Export", is_gst: true },
    { value: "0", name: "Business to Business(B2B)", is_vat: true },
    {
      value: "1",
      name: "Business to Customer(B2C)",
      is_vat: true,
      default: true,
    },
  ];
  console.log(GST,VAT,"VAT=GST");
  const GST_TreatmentData = TreatmentData.filter((i) => i.is_gst === true);
  const VAT_TreatmentData = TreatmentData.filter((i) => i.is_vat === true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator
      ? dataState.SuffixSeparator
      : "";
    let Suffix = dataState.Suffix ? dataState.Suffix : "";
    let VoucherNo =
      String(PreFix) +
      String(Seperator) +
      String(InvoiceNo) +
      String(SuffixSeparator) +
      String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setopenCustomVoucher(false);
  };

  // Change table Custamization
  const ChangeTableCustomization = (type, name) => {
    let value = null;
    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value,
      });
    } else {
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value,
      });
    }
  };

  const Call_salesEstimate_details = async (payload) => {
    let data = null;
    const Response = await saleEstimate_details(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  // Fetching Data For MAster
  const fetchData = async () => {
    let ResponseView = null;
    let AccountLedgerData = [];
    let CashList = [];
    let BankList = [];
    let CountryData = [];
    let EmployeeData = [];
    let PriceCategoryData = [];
    let TaxCategoryData = [];
    let UserTableData = [];
    let WarehouseData = [];
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let VoucherNo = "";
    let PlaceOfSupplyData = [];
    let BillWiseDatas = [];
    let Employee = null;
    let ReffNo = "";
    let BillDiscPercent = 0;
    let BillDiscAmt = 0;
    let Notes = "";
    let PriceCategory = null;

    const Data = await SalesEstimateDetails({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    });

    if (Data.StatusCode === 6000) {
      const PlaceOfSupplyResponse = await place_of_supply_api(Country);
      if (PlaceOfSupplyResponse.StatusCode === 6000) {
        PlaceOfSupplyData = PlaceOfSupplyResponse.data;
      }

      AccountLedgerData = Data.AccountLedgerData;
      let CashAndBankData = Data.CashAndBankData;
      if (CashAndBankData) {
        CashList = CashAndBankData.filter((i) => i.AccountGroupUnder === 9);
        BankList = CashAndBankData.filter((i) => i.AccountGroupUnder === 8);
      }

      CountryData = Data.CountryData;
      EmployeeData = Data.EmployeeData;
      PriceCategoryData = Data.PriceCategoryData;
      TaxCategoryData = Data.TaxCategoryData;
      UserTableData = Data.UserTableData;
      WarehouseData = Data.WarehouseData;

      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData;
      Seperator = VoucherNoGenerateTableData.Seperater;
      Suffix = VoucherNoGenerateTableData.Suffix;
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator;
      PreFix = VoucherNoGenerateTableData.PreFix;
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo;
      VoucherNo = VoucherNoGenerateTableData.VoucherNo;

      //Default Values
      let AccountLedgerDefault = AccountLedgerData.find((i) => i.is_default === true);

      let Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      let Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      PriceCategory = PriceCategoryData.find((i) => (i.PriceCategoryID = 1));
      let CashAccount = CashList[0];
      let BankAccount = BankList[0];

      let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply.id)

      let customise_tale = await CustomiseTableApi({
        CompanyID : CompanyID,
        BranchID : BranchID,
        UserID : user_id,
        VoucherType : "SE",
        SettingsName : '',
        SettingsValue : '',
        Type : 'List'
      })
      const res = customise_tale.datas.reduce((acc, curr) => {
        acc[curr.SettingsName] = curr.SettingsValue;
        return acc;
      }, {});
      
      setTableSettings((prev) => ({
        ...prev,
        BarCode: res.is_barcode ?? prev.BarCode,
        Unit: res.is_unit ?? prev.Unit,
        Description: res.is_description ?? prev.Description,
        DiscountAmount: res.is_discount_amount ?? prev.DiscountAmount,
        Rate: res.is_rate ?? prev.Rate,
        Qty: res.is_qty ?? prev.Qty,
        ProductCode: res.is_product_code ?? prev.ProductCode,
        DiscountPerc: res.is_discount_percentage ?? prev.DiscountPerc,
        FQty: res.is_free_qty ?? prev.FQty,
        BatchCode: res.is_batchcode ?? prev.BatchCode,
        Tax: res.is_tax ?? prev.Tax,
        GrossAmount: res.is_gross_amount ?? prev.GrossAmount,
        Amount: res.is_amount ?? prev.Amount,
        Product: res.is_name ?? prev.Product,
        TaxAmount: res.TaxAmount ?? prev.TaxAmount,
        InclusivePrice: res.is_inclusive_tax ?? prev.InclusivePrice
      }));
      
      setFocusSettings((prev) => ({
        ...prev,
        BarCode: res.is_barcode_focus ?? prev.BarCode,
        Unit: res.is_unit_focus ?? prev.Unit,
        Description: res.is_description_focus ?? prev.Description,
        DiscountAmount: res.is_discount_amount_focus ?? prev.DiscountAmount,
        Rate: res.is_rate_focus ?? prev.Rate,
        Qty: res.is_qty_focus ?? prev.Qty,
        ProductCode: res.is_product_code_focus ?? prev.ProductCode,
        DiscountPerc: res.is_discount_percentage_focus ?? prev.DiscountPerc,
        FQty: res.is_free_qty_focus ?? prev.FQty,
        BatchCode: res.is_batchcode_focus ?? prev.BatchCode,
        Tax: res.is_tax_focus ?? prev.Tax,
        GrossAmount: res.is_gross_amount_focus ?? prev.GrossAmount,
        Amount: res.is_amount_focus ?? prev.Amount,
        Product: res.is_name_focus ?? prev.Product,
        TaxAmount: res.is_tax_amount_focus ?? prev.TaxAmount,
        InclusivePrice: res.is_inclusivePrice_focus ?? prev.InclusivePrice
      }));






      setDefaultValue((prev) => {
        return {
          ...prev,
          AccountLedger: AccountLedgerDefault,
          CashAccount: CashAccount,
          BankAccount: BankAccount,
          PriceCategory: PriceCategory,
          Warehouse: Warehouse,
          Treatment: Treatment,
          PlaceOfSupply: PlaceOfSupply,
          // ShippingTax:ShippingTax,
        };
      });

      if (is_edit && uniq_id) {
        ResponseView = await SalesEstimateList({
          BranchID: BranchID,
          CompanyID: CompanyID,
          uniq_id: uniq_id,
        });
        
        if (ResponseView) {
          let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(ResponseView.data.AccountLedger)
          AccountLedgerDefault = AccountLedger
          Treatment = ResponseView.data.Treatment;
          let PlaceOfSupplyName = ResponseView.data.Place_of_Supply;
          PlaceOfSupply = PlaceOfSupplyData.find(
            (i) => i.Name === PlaceOfSupplyName
          );
          ReffNo = ResponseView.data.ReffNo;
          CashAccount = ResponseView.data.CashAccount;
          BankAccount = ResponseView.data.BankAccount;
          BillWiseDatas = ResponseView.data.BillwiseDetails;
          Employee = ResponseView.data.Employee;
          VoucherNo = ResponseView.data.VoucherNo;
          BillDiscPercent = ResponseView.data.BillDiscPercent;
          BillDiscAmt = ResponseView.data.BillDiscAmt;
          Notes = ResponseView.data.Notes;

          NewTaxType = ResponseView.data.TaxType
          NewTaxID = ResponseView.data.TaxID


          PriceCategory = PriceCategoryData.find(
            (i) => i.PriceCategoryID === ResponseView.data.PriceCategoryID
          );
          setIs_manual_roundoff(ResponseView.data.is_manual_roundoff);

          let TotalQty = Number(ResponseView.data.TotalQty);
          let noOfPages = Math.ceil(TotalQty / 100);
          // let noOfPages = 1;
          let data = [];
          for (let i = 1; i <= noOfPages; i++) {
            let DetailsReponse = await Call_salesEstimate_details({
              BranchID: BranchID,
              CompanyID: CompanyID,
              uniq_id: uniq_id,
              page: i,
            });
            console.log(DetailsReponse);
            
            if (DetailsReponse) {      
              data = [...data, ...DetailsReponse];
            }
          }
          
          setDataLists([...data]);

          // setDataState((prev) => ({
          //   ...prev,
          //   BillWiseDatas: BillWiseDatas,
          // }));

        }
      }

      if (is_convert) {
        // let ProductList = location.state.ProductList
        // console.log(location.state);
        // console.log(ProductList[0].id);

        // let index = 0;
        // let v = ProductList[0]?.id || "";
        // let Data = DataList;
        // let lineItem = Data[index];
        // setState({
        //   ...state,
        //   Warehouse: { ...state.Warehouse, WarehouseID: location.state?.WarehouseID }
        // })
        
      }
      setState((prev) => {
        return {
          ...prev,
          ...ResponseView,
          AccountLedger:AccountLedgerDefault,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          CashAccount,
          BankAccount,
          PriceCategory,
          ReffNo,
          Employee,
          BillDiscPercent,
          BillDiscAmt,
          Notes,
          TaxID:NewTaxID,
          TaxType : NewTaxType,
        };
      });

      setDataState((prev) => {
        return {
          ...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
          BillWiseDatas,
        };
      });

      setBillingAddress({...BillingAddress,CountryData,StateData:PlaceOfSupplyData})
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "get salesreturn Api Error",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState, state, DataList,is_manual_roundoff,customRound,GST,CompanyState,RoundOffSales);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);

  // search employee
  const Call_Employees_api = async (payload) => {
    let data = [];
    const Response = await CallEmployees(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Seles Man Search Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  // const Call_LedgerDetials = async (data) => {
  //   const LedgerDetails = await ledger_details_api({
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     CreatedUserID: user_id,
  //     ledger_id: data?.id,
  //     PriceRounding: PriceDecimalPoint,
  //   });

  //   if (LedgerDetails.StatusCode === 6000) {
  //     let AccountLedger = data; //state.AccountLedger
  //     AccountLedger["Treatment"] = LedgerDetails.data.Treatment;
  //     AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo;
  //     AccountLedger["Mobile"] = LedgerDetails.data.Mobile;
  //     AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress;
  //     AccountLedger["RouteName"] = LedgerDetails.data.RouteName;
  //     AccountLedger["Balance"] = LedgerDetails.data.Balance;
  //     AccountLedger["PriceCategoryID"] = LedgerDetails.data.PriceCategoryID;

  //     // setState({...state,AccountLedger:AccountLedger})
  //     Call_BillwiseDetails(AccountLedger);
  //     return AccountLedger;
  //   } else {
  //     dispatch(
  //       openSnackbar({
  //         open: true,
  //         message: LedgerDetails?.message ?? "Api Error",
  //         severity: "error",
  //       })
  //     );
  //   }
  // };


    //Ledger Searching api
    const Call_LedgerDetials = async (data) => {
      const LedgerDetails = await ledger_details_api({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        ledger_id: data?.id,
        PriceRounding: PriceDecimalPoint,
      });
  
      if (LedgerDetails.StatusCode === 6000) {
        let AccountLedger = data; //state.AccountLedger
        AccountLedger["Treatment"] = LedgerDetails.data.Treatment;
        AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo;
        AccountLedger["Mobile"] = LedgerDetails.data.Mobile;
        AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress;
        AccountLedger["RouteName"] = LedgerDetails.data.RouteName;
        AccountLedger["Balance"] = LedgerDetails.data.Balance;
  
        let BillingAddress_object = LedgerDetails.data.BillingAddress_object
        
        // setState({...state,AccountLedger:AccountLedger})
        Call_BillwiseDetails(AccountLedger)
        return {AccountLedger,BillingAddress_object}
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: LedgerDetails?.message ?? "Api Error",
            severity: "error",
          })
        );
      }
    };
  

  const Call_BillwiseDetails = async (data) => {
    try {
      if (!uniq_id && !is_edit) {
        const billWiseDataResponse = await billwise_datas({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          LedgerID: data?.LedgerID ?? 0,
          PaymentVoucherNo: dataState.VoucherNo,
          PaymentVoucherType: "SE",
          PriceRounding: PriceDecimalPoint,
          call_type: "list_type",
        });

        setDataState((prevState) => ({
          ...prevState,
          BillWiseDatas:
            billWiseDataResponse.StatusCode === 6000
              ? billWiseDataResponse.data
              : [],
        }));
        if (billWiseDataResponse?.data?.length) {
          setBillwise(true);
        } else {
          setBillwise(false);
        }
      } else {
        console.log("Passs");
      }
    } catch (error) {
      console.error("Error fetching billwise details:", error);
      setDataState({
        ...dataState,
        BillWiseDatas: [],
      });
    }
  };


  const Call_Product_History_api = async(payload) =>{
    let data = []
    let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
    if (ProductIDs.length > 0){          
      let LastProduct =  ProductIDs[ProductIDs.length - 1].Product?.ProductID
      payload.CompanyID = CompanyID
      payload.BranchID = BranchID
      payload.CreatedUserID = user_id
      payload.LedgerID = state.AccountLedger.LedgerID
      payload.PriceRounding = 2
      payload.ProductIDs = [LastProduct]
      payload.CompanyID = CompanyID
      payload.AvoidLedgerwise = AvoidLedgerwiseFilterInSalesHistory
    }
    
    const Response = await get_product_history_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.history_datas
    }
    else if (Response.StatusCode === 6001){
      data = []
      dispatch(
        openSnackbar({
          open: true,
          message: "No More Data!",
          severity: "warning",
        })
      );
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }
  




  const onMasterChange = (e) => {
    if (e) {
      const { name, value } = e.target;

      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);

      if (isCalcField) {
        state[name] = value;

        //handling bill disc
        if (name === "BillDiscAmt") {
          state.BillDiscPercent =
            100 * (Number(value) / Number(state.NetTotal));
        } else if (name === "BillDiscPercent") {
          state.BillDiscAmt = (Number(value) / 100) * Number(state.NetTotal);
        }

        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff,
          customRound,
          GST,
          CompanyState,
          RoundOffSales
        );
        setState({ ...newState });
      } else {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.Warehouse.WarehouseID,
          is_product_image: true,
          length: value?.length,
          product_name: value,
          type: "SalesEstimate",
        });
        console.log("ProductList", ProductList);
        
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };

  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);



  function customRound(x) {
  
    if (EnableEinvoiceKSA){    
      const rounded = Math.round(x * 100) / 100;
  
      if (rounded - x === 0.5) {
        return rounded;
      }
  
      return rounded;
    }
    else{
      return Number(x)
    }
  }
  

  const addByBarCode = async (text) => {
    let Data = DataList;
    let index = Data.length;
    let lineItem = DataListItem;
    let Product = await Call_Barcode_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      BarCode: text,
    });

    if (Product) {
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id: Product.id,
        type_data: "SI", // ------------------------------------------------------------------------------------------------------------------------
      });

      let Unit = UnitList.find((i) => i.UnitInSales === true);
      let PriceListID = Unit.PriceListID;

      let UnitDetails = await Call_Single_PriceList_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceListID: PriceListID,
        PriceRounding: Number(PriceDecimalPoint || 2),
        WarehouseID: state.Warehouse.WarehouseID,
      });

      let Rate = 0;
      let Qty = 1;
      let FQty = 0;
      let DiscountPerc = 0;
      let DiscountAmount = 0;

      if (UnitDetails) {
        Rate = Number(UnitDetails.Rate);

        lineItem.MRP = UnitDetails.MRP;
        lineItem.Rate = Rate;
        lineItem.PurchasePrice = UnitDetails.PurchasePrice;
        lineItem.BarCode = UnitDetails.BarCode;
        lineItem.BatchCode = UnitDetails.BatchCode;
        lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
        // lineItem.AverageCost = UnitDetails.AverageCost
      }

      let Tax = dataState.TaxCategoryData.find(
        (i) => i.TaxID === Product.ProductTaxID
      );

      lineItem.Product = Product;
      lineItem.ProductCode = Product;
      lineItem.Description = Product.Description;
      lineItem.Stock = Product.Stock;
      lineItem.Qty = Qty;
      lineItem.FQty = FQty;
      lineItem.Tax = Tax;
      lineItem.DiscountPerc = DiscountPerc;
      lineItem.DiscountAmount = DiscountAmount;
      lineItem.ProductImage = Product.ProductImage;
      lineItem.Unit = Unit;
      lineItem.UnitList = UnitList;

      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound,GST,CompanyState
      );

      Data.push(calculated_lineItem);

      setDataLists([...Data]);
    }
  };

  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      let lineItem = Data[index];
      lineItem[name] = value;

      // handling Line Discount
      let Amount = lineItem.GrossAmount; //+ lineItem.TaxAmount;
      if (name === "DiscountAmount") {
        lineItem.DiscountPerc = 100 * (Number(value) / Number(Amount));
      } else if (name === "DiscountPerc") {
        lineItem.DiscountAmount = (Number(value) / 100) * Number(Amount);
      }

      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound,GST,CompanyState
      );

      Data[index] = calculated_lineItem;
      setDataLists([...DataList]);
    }
  };

  const onDetailsAutoChange = async (v, index, name) => {
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI", //______________----------------------------------------------------------------------------------------------------------------------------
        });

        let Unit = UnitList.find((i) => i.UnitInSales === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        // if (UnitDetails) {
        //   Rate = Number(UnitDetails.Rate);

        //   lineItem.MRP = UnitDetails.MRP;
        //   lineItem.Rate = Rate;
        //   lineItem.PurchasePrice = UnitDetails.PurchasePrice;
        //   lineItem.BarCode = UnitDetails.BarCode;
        //   lineItem.BatchCode = UnitDetails.BatchCode;
        //   lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
        //   lineItem.AverageCost = UnitDetails.AverageCost;
        // }

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
          lineItem.is_inclusive = UnitDetails.is_inclusive;
          lineItem.Stock = UnitDetails.Stock;

          lineItem.SalesPrice = UnitDetails.SalesPrice;
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1;
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2;
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3;

          if (state?.PriceCategory?.PriceCategoryID === 1) {
            lineItem.Rate = UnitDetails.SalesPrice;
          } else if (state?.PriceCategory?.PriceCategoryID === 2) {
            lineItem.Rate = UnitDetails.SalesPrice1;
          } else if (state?.PriceCategory?.PriceCategoryID === 3) {
            lineItem.Rate = UnitDetails.SalesPrice2;
          } else if (state?.PriceCategory?.PriceCategoryID === 4) {
            lineItem.Rate = UnitDetails.SalesPrice3;
          }
        }

        let Tax = dataState.TaxCategoryData.find(
          (i) => i.TaxID === Product.ProductTaxID
        );

        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.ProductImage = Product.ProductImage;
        lineItem.Description = Product.Description;
        lineItem.Stock = Product.Stock;
        lineItem.Qty = Qty;
        lineItem.FQty = FQty;
        lineItem.Tax = Tax;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;

        lineItem.Unit = Unit;
        lineItem.UnitList = UnitList;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound,GST,CompanyState
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);

        return
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.Warehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.BarCode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
        }
        lineItem.Unit = Unit;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound,GST,CompanyState
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound,GST,CompanyState
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
    return;
  };

  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = [];
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
        
      }
    }
    
    let newState = SalesInvoiceCalc(dataState,state, Data,is_manual_roundoff,customRound,GST,CompanyState,RoundOffSales);
    setState({ ...newState,deletedData:deletedData });
    
    setDataLists([...Data]);
  };

  const clearData = async () => {
    if (is_edit) {
      navigate("/create-sales-estimate");
    }
    setIsButtonClick(false);

    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SE",
    });

    setDataState({
      ...dataState,
      ...voucher_datas,
    });

    setState({
      ...state,
      AccountLedger: defaultValue.AccountLedger,
      CashAccount: defaultValue.CashAccount,
      BankAccount: defaultValue.BankAccount,
      PriceCategory: defaultValue.PriceCategory,
      // Employee: null,
      Warehouse: defaultValue.Warehouse,
      Treatment: defaultValue.Treatment,
      PlaceOfSupply: defaultValue.PlaceOfSupply,
      Date: DefaultDate,
      ReffNo: "",
      RefferenceBillDate: DefaultDate,
      BillDiscPercent: 0,
      BillDiscAmt: 0,
      CashAmount: 0,
      BankAmount: 0,
      Notes: "",
      //Total
      TotalQty: 0,
      TotalGrossAmt: 0,
      TotalDiscount: 0,
      TotalTax: 0,
      NetTotal: 0,
      TotalTaxableAmount: 0,
      TaxTaxableAmount: 0,
      NonTaxTaxableAmount: 0,
      GrandTotal: 0,
      LedgerValue: "",
      RoundOff: "0",
      BillingAddress:null
    });
    setIs_manual_roundoff(false)
    setDataLists([DataListItem]);
  };

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "SalesInvoice",
        });
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }
      } else if (name === "Employee") {
        const EmployeeList = await Call_Employees_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          search: search,
          list_type: "sales",
        });
        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some(
            (employee) => employee.id === salesman.id
          );
        });
        let EmployeeData = [...dataState.EmployeeData, ...uniqueSalesmansData];
        setDataState({ ...dataState, EmployeeData: EmployeeData });
      }
    }
  };

  const onMasterAutoChange = async (e, v, name) => {
    if (v && name) {
      const isCalcField = MasterCalcFields.includes(name);
      let value = v;
      if (name === "AccountLedger"){
        console.log(value,"valuevalue");
        let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(value)
        console.log(AccountLedger,"AccountLedger>>");
        let data = AccountLedger
        let PlaceOfSupplyData;
        let CountryID = Country
        if (data.CountryID){
          CountryID = data.CountryID
        }
        const PlaceOfSupplyResponse = await place_of_supply_api(CountryID);
        if (PlaceOfSupplyResponse.StatusCode === 6000) {
          PlaceOfSupplyData = PlaceOfSupplyResponse.data;
        }
        let Treatment = null;
        if(VAT) {
          Treatment = VAT_TreatmentData.find((i)=> i.value === data.Treatment)
        } else {
          Treatment = GST_TreatmentData.find((i)=> i.value === data.Treatment)
        }
        let stateID = CompanyState
        if(data.State_id){
          stateID = data.State_id
        }
        let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === stateID)
        if ((VAT && (Treatment === null || Treatment === undefined))) {
          Treatment = {value: "1", name: 'Business To Customer (B2C)'};
        } else if ((GST && (Treatment === null || Treatment === undefined))) {
            Treatment = {value: "2", name: 'Consumer'};
        }

        let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply.id)
        
        // let PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === 1)
        // if (data.PriceCategoryID){
        //   PriceCategory = dataState.PriceCategoryData.find((i)=>i.PriceCategoryID === data.PriceCategoryID)
        // }
        setDataState({
          ...dataState,
          PlaceOfSupplyData : PlaceOfSupplyData
        })
        setState({...state,[name]:data,Treatment,PlaceOfSupply,ShippingAddress:null,BillingAddress:BillingAddress_object,TaxType:NewTaxType,TaxID:NewTaxID})
      }
          
      else if (name === "PriceCategory") {
        let Data = DataList;
        Data.map((lineItem, index) => {
          if (value?.PriceCategoryID === 1) {
            lineItem.Rate = lineItem.SalesPrice;
          } else if (value?.PriceCategoryID === 2) {
            lineItem.Rate = lineItem.SalesPrice1;
          } else if (value?.PriceCategoryID === 3) {
            lineItem.Rate = lineItem.SalesPrice2;
          } else if (value?.PriceCategoryID === 4) {
            lineItem.Rate = lineItem.SalesPrice3;
          }

          let calculated_lineItem = SalesLineCalc(
            dataState,
            state,
            lineItem,
            customRound,
            GST,
            CompanyState
          );
          Data[index] = calculated_lineItem;
        });

        setDataLists([...Data]);
        setState({ ...state, [name]: value });
      }
      else if (name === "Treatment") {
        let Treatment = value;
        const {TaxType , TaxID} = state
        let PlaceOfSupply = state.PlaceOfSupply?.id;
        let [NewTaxType,NewTaxID] = await Get_TaxType_TaxID(Treatment.value,PlaceOfSupply)

        setState({
          ...state,
          TaxType:NewTaxType,
          TaxID:NewTaxID,
          Treatment:Treatment,
        })
      }

            
      else if (name === 'PlaceOfSupply') {
        if (value){
          let PlaceOfSupply = value;
          let TaxType = state.TaxType;
          let TaxID = state.TaxID;

          if(CompanyState === PlaceOfSupply?.id && state.Treatment?.value !="5" ){
            if(TaxID === 23){
              TaxType = "GST Intra-state B2B";
              TaxID = 21;
            } else if(TaxID === 24) {
              TaxType = "GST Intra-state B2C";
              TaxID = 22;
            }
          } else {
            if (TaxID === 21) {
              TaxType = "GST Inter-state B2B";
              TaxID = 23;
            } else if (TaxID === 22) {
              TaxType = "GST Inter-state B2C";
              TaxID = 24;
            }
          }
          
          setState({
            ...state,
            PlaceOfSupply: PlaceOfSupply,
            TaxType : TaxType,
            TaxID : TaxID,
          })
        } else {
          setState({...state,[name]:value})
        }
      }

      else if (isCalcField) {
        state[name] = value;
        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff,
          GST,
          customRound,
          CompanyState,
          RoundOffSales,
        );
        setState({ ...newState });
      } else {
        setState({ ...state, [name]: value });
      }
    }
  };

  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };

  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };
  const Call_Barcode_api = async (payload) => {
    let data = null;
    const Response = await get_product_by_barcode_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const handleSubmit = async (e, IsBillWise = true) => {
    setErrors({
      field: null,
      message: null,
      error: false,
      indexList: [],
    });

    let { error, message, field, indexList } = await SalesEstimateValidation(
      dataState,
      state,
      DataList,
      GeneralSettingsData
    );
    if (error) {
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
      setErrors({ ...state, errors });
    } else {
      let Details = [];
      DataList.map((i) => {
        if (i.Product) {
          let item = {
            id: i.id,
            ProductID: i.Product.ProductID,
            Qty: i.Qty,
            UnitPrice: i.Rate,
            InclusivePrice: i.InclusivePrice,
            RateWithTax: i.Rate,
            Description: i.Description ?? "",
            DiscountAmount: i.DiscountAmount,
            GrossAmount: i.GrossAmount,
            TaxableAmount: i.TaxableAmount,
            TaxAmount: i.TaxAmount,
            NetAmount: i.Amount,
            FreeQty: i.FQty,
            Tax: i.Tax,
            // CostPerPrice : i.Rate,
            PriceListID: i.Unit.PriceListID,
            DiscountPerc: i.DiscountPerc,
            // VATPerc : i.Rate,
            BatchCode: i.BatchCode,
            Description: i.Description,
            ProductTaxID: i.Tax.TaxID,
            NonTaxableAmount: i.NonTaxableAmount,
            PurchasePrice: i.PurchasePrice,
            SGSTAmount:i.SGSTAmount,
            CGSTAmount:i.CGSTAmount,
            IGSTAmount:i.IGSTAmount,
            IGSTPerc:i.IGSTPerc,
            CGSTPerc : i.CGSTPerc,
            SGSTPerc:i.SGSTPerc
          };
          Details.push(item);
        }
      });

      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceRounding: PriceDecimalPoint,
        Details: Details,
        is_manual_roundoff: is_manual_roundoff,
        uniq_id: uniq_id, //uniq_id

        ReffNo: state.ReffNo,
        DeliveryDate: state.RefferenceBillDate,
        // ------
        Date: state.Date,
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        // ---------
        BillwiseDetails: dataState.BillWiseDatas,
        LedgerID: state.AccountLedger.LedgerID,
        PriceCategoryID: state.PriceCategory.PriceCategoryID,
        SalesAccount: 86,
        EmployeeID: state.Employee?.EmployeeID,
        CustomerName: state.AccountLedger.LedgerName,
        Treatment: state.Treatment.value,
        State_of_Supply: state.PlaceOfSupply.id,
        Country_of_Supply: state.PlaceOfSupply.Country,
        BillDiscPercent: state.BillDiscPercent,
        BillDiscAmt: state.BillDiscAmt,
        CashAmount: state.CashAmount,
        BankAmount: state.BankAmount,
        Notes: state.Notes,
        RoundOff: state.RoundOff,
        VAT_Treatment: state.VAT_Treatment,
        WarehouseID: state.Warehouse.WarehouseID,

        // Total
        TotalQty: state.TotalQty,
        TotalGrossAmt: state.TotalGrossAmt,
        TotalDiscount: state.TotalDiscount,
        TotalTax: state.TotalTax,
        NetTotal: state.NetTotal,
        TotalTaxableAmount: state.TotalTaxableAmount,
        TaxTaxableAmount: state.TaxTaxableAmount,
        NonTaxTaxableAmount: state.NonTaxTaxableAmount,
        GrandTotal: state.GrandTotal,
        BillingAddress:state.BillingAddress?.id,
        TotalCGST:state.TotalCGST,
        TotalSGST:state.TotalSGST,
        TotalIGST:state.TotalIGST,
        TaxType : state.TaxType,
        TaxID : state.TaxID,

      };

      let responseData = null;
      if (IsBillwise === true && IsBillWise === true) {
        setOpenBillWise(true);
      } else {
        if (is_edit && uniq_id) {
          setIsButtonClick(true);
          responseData = await EditSalesEstimateList(payload);
        } else {
          setIsButtonClick(true);
          responseData = await SalesEstimateCreate(payload);
        }
        if (responseData?.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          clearData();
        } else if (responseData?.StatusCode === 6001) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
        } else if (responseData?.StatusCode === 6002) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "error",
            })
          );
        } else {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message:
                responseData?.message + " : " + responseData?.error ??
                "Something went wrong!",
              severity: "error",
            })
          );
        }
      }
    }
  };



    //=============KEYBOARD SHORTCUTS===================== 
    const shortCutKeyPress = useCallback (async(event) => {
      //sales history
      if (event.altKey && (event.key === 'q' || event.keyCode === 81) && sales_history_view_permission){
        if (DataList.length > 0){        
          let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
          if (ProductIDs.length > 0){              
            let SalesHistoryResponse = await Call_Product_History_api({
              Type:"Sales",
              items_per_page:5,
              page_no:1
            })
            
            setDataState({...dataState,SalesHistory:SalesHistoryResponse})
          }
        }
        setIsSalesHistory(true)
        setIsPurchaseHistory(false)
      }
      //purchase history
      else if (event.altKey && (event.key === 'w' || event.keyCode === 87 || event.keyCode === 119) && purchase_history_view_permission){
        if (DataList.length > 0){        
          let ProductIDs = DataList.filter((i)=>i.Product?.ProductID)
          if (ProductIDs.length > 0){              
            let PurchaseHistoryResponse = await Call_Product_History_api({
              Type:"Purchase",
              items_per_page:5,
              page_no:1
            })
            
            setDataState({...dataState,PurchaseHistory:PurchaseHistoryResponse})
          }
        }
        setIsPurchaseHistory(true)
        setIsSalesHistory(false)

      }
      //closing modal
      else{
        setIsSalesHistory(false)
        setIsPurchaseHistory(false)
      }
      
    },[state.AccountLedger,DataList])

    useEffect(() => {
      window.addEventListener('keydown', shortCutKeyPress);
      return () => {
        window.removeEventListener('keydown', shortCutKeyPress);
      };
    }, [shortCutKeyPress ]);

  const processList = () => {
    if (!is_convert || !location.state?.ProductList) return;
  
    let currentIndex = 0;
    let details = null;
  
    const processNextItem = async () => {
      // Check if we've processed all items
      // if (currentIndex >= ProductList.length) {
      //   console.log("All items processed successfully");
      //   return;
      // }
  
      // console.log("Processing item:", currentIndex, ProductList[currentIndex]);

      // onDetailsAutoChange(ProductList[currentIndex], currentIndex, "Product")
      //   .then(res => {
      //     // if (res === 0) {
      //     console.log("Successfully processed item:", currentIndex, ProductList[currentIndex]);
      //     // Increment index and process next item
      //     currentIndex++;
      //     processNextItem();
      //     // } else {
      //     //   console.log("Failed to process item:", currentIndex, res);
      //     //   // You can decide what to do on failure - either stop or continue
      //     //   currentIndex++;
      //     //   processNextItem();
      //     // }
      //   })
      //   .catch(error => {
      //     console.error("Error processing item:", currentIndex, error);
      //     // You can decide what to do on error - either stop or continue
      //     currentIndex++;
      //     processNextItem();
      //   });
    };
  
    // Start processing the first item
    processNextItem();
  };
  
  // If this is inside a useEffect, wrap it properly:
  useEffect(() => {
    if (is_convert) {
      // processList();
      const saleDetails = location.state.saleDetails;
      const productList = location.state?.ProductList;
      let data = [];

      const taxMap = new Map(dataState.TaxCategoryData.map(tax => [tax.TaxID, tax]));
      console.log(taxMap, dataState.TaxCategoryData);
      
      data = saleDetails.map((product, i) => {
        let dataItem = {
          ...productList[i],
          ...saleDetails[i],
          ProductCode: product.ProductCode,
          BarCode: product.Barcode,
          Product: productList[i],
          Description: product.Description,
          Unit: {
            UnitName: product.UnitName, 
            PriceListID: product.PriceListID,
            // UnitID: UnitID
            detailID: product.detailID
          },
          UnitList: product.UnitList,
          Stock: product.Stock,
          Qty: product.Qty,
          FQty: product.FreeQty,
          Rate: product.RateWithTax,
          // AvgCost: product,
          // Tax: {
          //   Inclusive: false,
          //   IsDefault: true,
          //   PurchaseTax: product.TAX1Perc,
          //   SalesTax: product.TAX2Perc,
          //   TaxID: product.ProductTaxID,
          //   TaxName: product.ProductTaxName,
          //   // id: "430edb67-2383-41d9-90b8-a06a398a9669"
          // },
          InclusivePrice: product.InclusivePrice,
          // GrossAmount: product,
          DiscountPerc: product.DiscountPerc,
          DiscountAmount: product.DiscountAmount,
          TaxAmount: product.TAX1Amount,
          // Amount: product,
          MRP: product.MinimumSalesPrice,
          PurchasePrice: product.PurchasePrice,
          BatchCode: product.BatchCode,
          MinimumSalesPrice: product.MinimumSalesPrice,
          // AverageCost: product,
          TaxableAmount: product.TaxableAmount,
          // NonTaxableAmount: saleDetails[i],
          // ProductImage: saleDetails[i],
        };

        if(product.ProductTaxID && taxMap.has(product.ProductTaxID)){
          console.log("Thsi is inside the ", product.ProductTaxID);
          return {...dataItem, Tax: taxMap.get(product.ProductTaxID)}
        }
        console.log("Same is ", product.ProductTaxID, taxMap.has(product.ProductTaxID));
        
        return {...dataItem, Tax: {
            Inclusive: false,
            IsDefault: true,
            PurchaseTax: product.TAX1Perc,
            SalesTax: product.TAX2Perc,
            TaxID: product.ProductTaxID,
            TaxName: product.ProductTaxName,
          }}
      })
      // for (let i = 0; i < saleDetails.length; i++) {
      //   console.log(saleDetails);
        // console.log(dataState.TaxCategoryData.filter(e => e.TaxID === saleDetails[i].ProductTaxID));
        
        // let dataItem = {
        //   ...productList[i],
        //   ...saleDetails[i],
        //   ProductCode: saleDetails[i].ProductCode,
        //   BarCode: saleDetails[i].Barcode,
        //   Product: productList[i],
        //   Description: saleDetails[i].Description,
        //   Unit: {
        //     UnitName: saleDetails[i].UnitName, 
        //     PriceListID: saleDetails[i].PriceListID,
        //     // UnitID: UnitID
        //     detailID: saleDetails[i].detailID
        //   },
        //   UnitList: saleDetails[i].UnitList,
        //   Stock: saleDetails[i].Stock,
        //   Qty: saleDetails[i].Qty,
        //   FQty: saleDetails[i].FreeQty,
        //   Rate: saleDetails[i].RateWithTax,
        //   // AvgCost: saleDetails[i],
        //   Tax: {
        //     Inclusive: false,
        //     IsDefault: true,
        //     PurchaseTax: saleDetails[i].TAX1Perc,
        //     SalesTax: saleDetails[i].TAX2Perc,
        //     TaxID: saleDetails[i].ProductTaxID,
        //     TaxName: saleDetails[i].ProductTaxName,
        //     // id: "430edb67-2383-41d9-90b8-a06a398a9669"
        //   },
        //   InclusivePrice: saleDetails[i].InclusivePrice,
        //   // GrossAmount: saleDetails[i],
        //   DiscountPerc: saleDetails[i].DiscountPerc,
        //   DiscountAmount: saleDetails[i].DiscountAmount,
        //   TaxAmount: saleDetails[i].TAX1Amount,
        //   // Amount: saleDetails[i],
        //   MRP: saleDetails[i].MinimumSalesPrice,
        //   PurchasePrice: saleDetails[i].PurchasePrice,
        //   BatchCode: saleDetails[i].BatchCode,
        //   MinimumSalesPrice: saleDetails[i].MinimumSalesPrice,
        //   // AverageCost: saleDetails[i],
        //   TaxableAmount: saleDetails[i].TaxableAmount,
        //   // NonTaxableAmount: saleDetails[i],
        //   // ProductImage: saleDetails[i],
        // };
        // data = [...data, dataItem];
      // }
      // console.log(data);
      
      setDataLists(data);
      console.log(location.state.saleDetails[0]);
      console.log(JSON.stringify(location.state));
    }
  }, [is_convert, dataState.TaxCategoryData]);


  const Get_TaxType_TaxID = async (GST_Treatment, PlaceOfSupply) => {
    let { TaxType, TaxID, Customer_state_Code: CustomerStateCode } = state;
    let company_state = CompanyState;
  
    PlaceOfSupply = PlaceOfSupply || company_state;
  
    if (GST) {
      switch (GST_Treatment) {
        case "0":
        case "1":
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2B";
            TaxID = 21;
          } else {
            TaxType = "GST Inter-state B2B";
            TaxID = 23;
          }
          break;
  
        case "5":
          TaxType = "GST Inter-state B2B";
          TaxID = 23;
          break;
  
        case "3":
        case "2":
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2C";
            TaxID = 22;
          } else {
            TaxType = "GST Inter-state B2C";
            TaxID = 24;
          }
          break;
  
        case "4":
        case "6":
          TaxType = "Export";
          TaxID = 25;
          break;
  
        default:
          GST_Treatment = "2";
          if (company_state === PlaceOfSupply) {
            TaxType = "GST Intra-state B2C";
            TaxID = 22;
          } else {
            TaxType = "GST Inter-state B2C";
            TaxID = 24;
          }
      }
    } else if (VAT) {
      TaxType = "VAT";
      TaxID = 32;
    }
  
    return [TaxType, TaxID, GST_Treatment];
  };
  const openBillingAddressModal  = async() =>{
    setIsBilling(true)
    let res = await Create_BillingAddress(BillingAddress,"list")
    if (res){
        setBillingAddress({...BillingAddress,BillingAddressList:res})
    }
  }

  const Call_States_of_Country_api = async(payload) =>{
    let data = null
    const Response = await place_of_supply_api(payload)
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }

    //==============CREATE/UPDATE BILLING ADDRESS==================
    const Create_BillingAddress = async(payload,method) =>{
    
      if (method === "list"){
        payload.CompanyID = CompanyID
        payload.BranchID=BranchID
        payload.LedgerID=state.AccountLedger.LedgerID
        payload.Type = "BillingAddress"	
        const list_resposne = await list_user_address({
        CompanyID : CompanyID,
        BranchID:BranchID,
        LedgerID:state.AccountLedger.LedgerID,
        Type : "BillingAddress"	,
        })
        if (list_resposne.StatusCode===6000){
          return list_resposne.data
        }
      }
      else if(
        !payload.Attention||
        !payload.PostalCode||
        !payload.Address1||
        !payload.Address2||
        !payload.City||
        !payload.District||
        !payload.country||
        !payload.state||
        !payload.Mobile
      ){
        dispatch(
          openSnackbar({
            open: true,
            message: "Please fill all mandatory fields",
            severity: "warning",
          })
        );
      }
      else if (method==="create"){
        
        payload.CompanyID = CompanyID
        payload.BranchID=BranchID
        payload.LedgerID=state.AccountLedger.LedgerID
        payload.type_data = "create"
        payload.AddressType = "BillingAddress"
        
        payload.country = payload.country.id
        payload.state = payload.state.id
  
      
        const responseData = await create_user_address(payload)
          
        if (responseData.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          
          setBillingAddress({...BillingAddress,BillingAddressList:responseData.data})
          return true
        } else if (responseData.StatusCode === 6001) {
    
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
          return false
        } 
        else if (responseData.StatusCode === 6002) {
       
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
              severity: "error",
            })
          );
          return false
        } 
        else {
    
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
              severity: "error",
            })
          );
          return false
        }
      }
      
    }

    const[BillingAddress,setBillingAddress] = useState({
      Attention : "", //Name
      PostalCode : "", //Pincode
      Address1 : "", //Building
      Address2 : "", //Area
      City : "",
      District : "",
      country : null,
      state : null,
      Mobile : "",
      CountryData:[],
      StateData:[],
      BillingAddressList:[],
    }) 


    const calculateData = (dataState, state, DataList, customRound, GST, CompanyState, setDataLists) => {
      const updatedData = DataList.map((lineItem) => {
        const updatedLineItem = SalesLineCalc(dataState, state, lineItem, customRound, GST, CompanyState);
        return updatedLineItem;
      });
      
      setDataLists([...updatedData]);
    };
    
    useEffect(() => {
      if(GST) {
        calculateData(dataState, state, DataList, customRound, GST, CompanyState, setDataLists);
      }
    }, [state.PlaceOfSupply,state.Treatment]);
  
  
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                cmr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit
                ? t("Update Sales Estimate")
                : t("Create Sales Estimate")}
            </Typography>
          </FlexBox>
          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                navigate("/list-sales-estimate");
              }}
              t={t}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              <SaveButton onClick={handleSubmit} t={t} />
            )}
          </FlexBox>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          <div class="sales-grid-item ">
            {/* customer search */}
            <InvoiceLedgerSearch 
            name = {"AccountLedger"} 
            label = {"Select Customer"} 
            optionLabel = {"LedgerName"} 
            optionLabel2 = {"LedgerCode"} 
            OptionList = {dataState.AccountLedgerData} 
            Value = {state.AccountLedger}  
            OnChange = {onMasterAutoChange} 
            OnInputChange = {onMasterInputChange} 
            openBillingAddressModal={openBillingAddressModal} 
            BillingAddress={state.BillingAddress}  
            is_customer={is_customer} 
            GST={GST}/>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
               {VAT ? "VAT Treatment:" : "GST Treatment:"}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                 <InvoiceAutoComplete
                  name={"Treatment"}
                  label={"Select a Treatment"}
                  optionLabel={"name"}
                  List={VAT ? VAT_TreatmentData : GST ? GST_TreatmentData : []}
                  Value={state.Treatment}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
            <div
              style={{
                display: PriceCategory ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PriceCategory"}
                  label={"Select a Price Category"}
                  optionLabel={"PriceCategoryName"}
                  List={dataState.PriceCategoryData}
                  Value={state.PriceCategory}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PlaceOfSupply"}
                  label={"Select a Place Of Supply"}
                  optionLabel={"Name"}
                  List={dataState.PlaceOfSupplyData}
                  Value={state.PlaceOfSupply}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

            {/* <div
              style={{
                display: EnableSalesManInSales ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Employee"}
                  label={"Select a Employee"}
                  optionLabel={"FirstName"}
                  // List={dataState.EmployeeData}
                  // Value={state.Employee}
                  // OnChange={onMasterAutoChange}
                  // OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"ReffNo"}
                  label={"Type a RefNo"}
                  placeholder={"Ref No"}
                  Value={state.ReffNo}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  is_edit={is_edit}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher={setopenCustomVoucher}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <CustomVoucher
                open={openCustomVoucher}
                setCustomVoucher={setopenCustomVoucher}
                state={dataState}
                handleChange={handleChange}
                CustomVoucherSet={CustomVoucherSet}
              />

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Delivery Date:{" "}
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
                  name={"RefferenceBillDate"}
                  label={"Select a Date"}
                  Value={state.RefferenceBillDate}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales ? "flex" : "none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"Employee"}
                  label={"Select a Employee"}
                  optionLabel={"FirstName"}
                  placeholder={"Select Sales Man"}
                  List={dataState.EmployeeData}
                  Value={state.Employee}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine={RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "13px",
              }}
            >
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
              Add By Barcode :{" "}
            </span>
            <InvoiceBarCodeTextBox
              onEnter={addByBarCode}
              placeholder={" Add By Barcode"}
            />
          </div>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}
          <div>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%):
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount:
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscPercent"}
                  label={"Type a BillDiscPercent"}
                  Value={state.BillDiscPercent}
                  OnChange={onMasterChange}
                />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscAmt"}
                  label={"Type a BillDiscAmt"}
                  Value={state.BillDiscAmt}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>

          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff} onChange={onMasterChange} Vat={VAT} Gst={GST}/>

        </Box>

        {/* ============HISTORY MODALS============== */}
        <HistoryModal Type = {"Sales"} heading = {"Sales History"} List = {dataState.SalesHistory} open = {IsSalesHistory} setOpen = {setIsSalesHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState} />
        <HistoryModal Type = {"Purchase"}  heading = {"Purchase History"} List = {dataState.PurchaseHistory} open = {IsPurchaseHistory} setOpen = {setIsPurchaseHistory} Call_Product_History_api={Call_Product_History_api} dataState={dataState} setDataState={setDataState}  />
      </Paper>
      <AddressModal type={"Billing"}  state={state} setState={setState} open={IsBilling} setOpen={setIsBilling} List = {BillingAddress?.BillingAddressList} name={"Billing Address"} Address={BillingAddress} setAddress={setBillingAddress} Call_States_of_Country_api={Call_States_of_Country_api} api = {Create_BillingAddress} />

      <BottomNavigationMenu data={MenuBottomData}/>
    </>
  );
};

export default CreateSaleEstimate;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
