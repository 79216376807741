import {
  Box,
  List,
  ListItem,
  Paper,
  Popover,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { CallProductSummaryReportNew } from "../../../../Api/Reports/InventoryApi";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import {
  CallBrands,
  CallParties,
  CallProductCategories,
  CallProductGroups,
  CallProductSearch,
  CallUsers,
  CallWarehouses,
} from "../../../../Api/Reports/CommonReportApi";
import { useSelector } from "react-redux";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import { formatNumber } from "../../../../generalFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const ProductSummaryReport = () => {
  let Export_view = UserRolePermission(
    "Product Summary Report",
    "print_permission"
  );
  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [page_no, setPage_no] = useState(1);
  const [reportData, setReportData] = useState({
    data: [],
    total: {
      LastGrossAmount: 0,
      Qty: 0,
      FreeQty: 0,
      GrossAmount: 0,
      ReturnQty: 0,
      ReturnAmount: 0,
    },
  });
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableWarehouse } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState(
    "Generating Report..."
  );
  const [filterOptions, setFilterOptions] = useState({
    isProduct: true,
    isProductCategory: false,
    isProductGroup: false,
    isBrand: false,
    fromDate: moment(),
    toDate: moment(),
    product: null,
    productCategory: null,
    productGroup: null,
    brand: null,
    supplier: null,
    customer: null,
    transactables: "PI",
    showActiveProductsOnly: true,
    unitInReport: false,
    warehouse: null,
    withVoucher: false,
    userFilter: false,
    user: null,
    showLedger:true,
    showUnit:true,
  });

  const [filterData, setfilterData] = useState({
    productList: [],
    productCategoryList: [],
    productGrouplist: [],
    brandList: [],
    supplierList: [],
    customerList: [],
    warehouseList: [],
    userList: [],
  });

  const [anchorEl, setAnchorEl] = useState(null)
  // Handle fundtions

  const [csValue, setCsValue] = useState({
    name: "",
    PartyType: 1,
    product: "",
  });

  const [isLoading, setIsLoading] = useState({
    report: false,
    supplier: false,
    customer: false,
    product: false,
  });

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const handleDefaultFilter = function () {
    setFilterOptions({
      isProduct: true,
      isProductCategory: false,
      isProductGroup: false,
      isBrand: false,
      fromDate: moment(),
      toDate: moment(),
      product: null,
      productCategory: null,
      productGroup: null,
      brand: null,
      supplier: null,
      customer: null,
      transactables: "all",
      unitInReport: false,
    });
    setClearFilter(!clearFilter);
    setPage_no(1);
  };

  const radiosValue = [
    t("Product"),
    t("Product Category"),
    t("Product Group"),
    t("Brand"),
  ].map((option) => {
    const isChecked = filterOptions[`is${option.replace(" ", "")}`];

    return {
      checked: isChecked !== undefined ? isChecked : false,
      onChange: () => {
        setFilterOptions({
          ...filterOptions,
          isProduct: false,
          isProductCategory: false,
          isProductGroup: false,
          isBrand: false,
          [`is${option.replace(" ", "")}`]: true,
        });
        setFilterOptions((prev) => ({
          ...prev,
          product: null,
          productCategory: null,
          productGroup: null,
          brand: null,
        }));
      },
      label: option,
    };
  });

  const searchReport = function () {
    fetchReport();
  };

  const handlePenIconClick = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePenIconClose = () => {
    setAnchorEl(null)
  };

  const handleTableHeadingSelection = (value) => {
    
    setFilterOptions((prevState)=>({
      ...prevState,
      [value]:!prevState[value]
    }))
  }

  const handleChange = function (e) {
    setFilterOptions((prev) => ({ ...prev, transactables: e.target.value }));
  };

  const handleFilter = function () {
    setPage_no(1);
    fetchReport();
  };

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading({ ...isLoading, report: true });
    // console.log(filterOptions.productGroup);
    const payload = {
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      ProductID: filterOptions.product ? filterOptions.product.ProductID : 0,
      CategoryID: filterOptions.productCategory
        ? filterOptions.productCategory.ProductCategoryID
        : 0,
      GroupID: filterOptions.productGroup
        ? filterOptions.productGroup.ProductGroupID
        : 0,
      BrandID: filterOptions.brand ? filterOptions.brand.BrandID : 0,
      LedgerID:
        filterOptions.customer?.LedgerID ||
        filterOptions.supplier?.LedgerID ||
        0,
      PriceListID: 0,
      VoucherType: filterOptions.transactables,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      UnitInReport: filterOptions.unitInReport,
      WarehouseID: filterOptions.warehouse?.WarehouseID || 0,
      WithVoucher: filterOptions.withVoucher,
      UserID: filterOptions.user?.UserID,
      ShowLedger:filterOptions.showLedger,
      ShowUnit:filterOptions.showUnit
    };

    // console.log(payload);
    const data = await CallProductSummaryReportNew(payload);
    console.log(data);
    if (data?.StatusCode === 6000) {
      const totalPrices = data.data.reduce(
        (accumulator, currentItem) => {
          // Add both price and sellingPrice to the accumulator
          return {
            ReturnAmount: accumulator.ReturnAmount + currentItem.ReturnAmount,
            ReturnQty: accumulator.ReturnQty + currentItem.ReturnQty,
            LastGrossAmount:
              accumulator.LastGrossAmount + currentItem.LastGrossAmount,
            Qty: accumulator.Qty + currentItem.Qty,
            FreeQty: accumulator.FreeQty + currentItem.FreeQty,
            GrossAmount: accumulator.GrossAmount + currentItem.GrossAmount,
          };
        },
        {
          LastGrossAmount: 0,
          Qty: 0,
          FreeQty: 0,
          GrossAmount: 0,
          ReturnQty: 0,
          ReturnAmount: 0,
        }
      );
      console.log(totalPrices, "Total Items is hear");
      // setReportData(data.data);
      setReportData((prev) => ({
        ...prev,
        data: data.data,
        total: totalPrices,
      }));
    }
    setIsLoading({ ...isLoading, report: false });
  };

  const searchProduct = async function () {
    setIsLoading({ ...isLoading, product: true });
    const product = await CallProductSearch({
      product_name: csValue.product,
      length: 1,
      type: "report",
      is_product_image: false,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      showActiveProductsOnly: filterOptions.showActiveProductsOnly,
    });

    const userResponse = await CallUsers({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    });
    if (userResponse?.StatusCode === 6000) {
      setfilterData((prev) => ({
        ...prev,
        userList: userResponse.data,
      }));
    }

    if (product.StatusCode === 6000) {
      setfilterData((prev) => ({
        ...prev,
        productList: product.data,
      }));
    }

    setIsLoading({ ...isLoading, product: false });
  };

  const searchCS = async function () {
    if (csValue.PartyType === 1) {
      setIsLoading({
        ...isLoading,
        customer: true,
      });
    } else {
      setIsLoading({
        ...isLoading,
        supplier: true,
      });
    }

    const res = await CallParties({
      PartyType: csValue.PartyType,
      search: csValue.name,
      page_no: 1,
      items_per_page: 10,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });
    if (res.StatusCode === 6000) {
      if (csValue.PartyType === 1) {
        setfilterData((prev) => ({
          ...prev,
          customerList: res.data,
        }));
      } else {
        setfilterData((prev) => ({
          ...prev,
          supplierList: res.data,
        }));
      }
    }

    if (csValue.PartyType === 1) {
      setIsLoading({
        ...isLoading,
        customer: false,
      });
    } else {
      setIsLoading({
        ...isLoading,
        supplier: false,
      });
    }
  };

  const filterApi = async function () {
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
    };
    try {
      const apiNames = [
        "warehouseList",
        "brandList",
        "productGrouplist",
        "productCategoryList",
      ];
      const responses = await Promise.all([
        CallWarehouses(payload),
        CallBrands(payload),
        CallProductGroups(payload),
        CallProductCategories(payload),
      ]);

      responses.forEach((response, index) => {
        if (response.StatusCode === 6000) {
          setfilterData((prevState) => ({
            ...prevState,
            [apiNames[index]]: response.data,
            // [`${apiNames[index].toLowerCase()}List`]: response.data
          }));
        } else {
          console.log(
            `API ${apiNames[index]} returned StatusCode ${response.StatusCode}`
          );
        }
      });
    } catch (error) {
      console.error("An error occurred while making the API calls", error);
    }
  };

  const getDataForPage = () => {
    const startIndex = (page_no - 1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData.data;
    return alldata.slice(startIndex, endIndex) || [];
  };

  // console.log(filterData);

  useEffect(() => {
    filterApi();
  }, []);

  useEffect(() => {
    fetchReport();
  }, [clearFilter, filterOptions.showLedger, filterOptions.showUnit, filterOptions.withVoucher]);

  useEffect(() => {
    searchCS();
  }, [csValue.name]);

  useEffect(() => {
    searchProduct();
  }, [csValue.product]);

  
  return (
    <>
      {progress > 0 ? (
        <LoaderLite
          message={progressMessage + " " + progress + progress ? "%" : ""}
          progress={progress}
          fullscreen={true}
        />
      ) : null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{
                color: "#001746",
                fontSize: "23px",
                fontWeight: "500",
                mr: 2,
              }}
              variant="h2"
            >
              {t("Product Summary Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // width: "26%",
            }}
          >
            {/* <ExportBtn /> */}
            {Export_view ? (
              //   <ReportDownload
              //   download_type={"PDF"}
              //   VoucherType={filterOptions.transactables}
              //   is_celery={true}
              //   setProgress={setProgress}
              //   setProgressMessage={setProgressMessage}
              //   invoice_type={"product_summary"}
              //   FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
              //   ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
              //   // PriceRounding={state.PriceRounding}
              //   ProductID={filterOptions.product ? filterOptions.product.ProductID : 0}
              //   PriceListID={0}
              //   CategoryID={filterOptions.productCategory ? filterOptions.productCategory.ProductCategoryID : 0}
              //   GroupID={filterOptions.productGroup ? filterOptions.productGroup.ProductGroupID : 0}
              //   BrandID={filterOptions.brand ? filterOptions.brand.BrandID : 0}
              //   LedgerID={filterOptions.customer?.LedgerID || filterOptions.supplier?.LedgerID || 0}
              //   checking={"productSummaryReport"}
              // />
              <ReportDownload
                download_type={"PDF"}
                VoucherType={filterOptions.transactables}
                is_celery={true}
                setProgress={setProgress}
                setProgressMessage={setProgressMessage}
                invoice_type={"product_summary_report_new"}
                FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
                ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
                // PriceRounding={state.PriceRounding}
                ProductID={
                  filterOptions.product ? filterOptions.product.ProductID : 0
                }
                PriceListID={0}
                CategoryID={
                  filterOptions.productCategory
                    ? filterOptions.productCategory.ProductCategoryID
                    : 0
                }
                GroupID={
                  filterOptions.productGroup
                    ? filterOptions.productGroup.ProductGroupID
                    : 0
                }
                BrandID={filterOptions.brand ? filterOptions.brand.BrandID : 0}
                LedgerID={
                  filterOptions.customer?.LedgerID ||
                  filterOptions.supplier?.LedgerID ||
                  0
                }
                WarehouseID={filterOptions.warehouse?.WarehouseID || 0}
                UserID={filterOptions.user?.UserID || 0}
                PriceRounding={2}
                WithVoucher={filterOptions.withVoucher}
                // UserID={filterOptions.}
                // checking={"productSummaryReport"}
                ShowLedger={filterOptions.showLedger}
                ShowUnit={filterOptions.showUnit}
              />
            ) : null}
            {/* <PrintBtn /> */}
            <Pagenation
              totalItem={reportData.data.length}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </Box>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px",
            overflowX: "scroll",
            // height: "54px",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div
            style={{
              color: "#001746",
              fontSize: "15px",
              fontWeight: "400",
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date")}
            value={
              filterOptions.fromDate.format("DD/MM/YYYY") +
              " - " +
              filterOptions.toDate.format("DD/MM/YYYY")
            }
          />
          <FilterLable
            name={t("Invoice Type")}
            value={filterOptions.transactables}
          />

          {filterOptions.isProduct && filterOptions.product && (
            <FilterLable
              name={t("Product")}
              value={filterOptions.product.ProductName}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, product: null }))
              }
            />
          )}
          {filterOptions.isProductCategory && filterOptions.productCategory && (
            <FilterLable
              name={t("Category")}
              value={filterOptions.productCategory.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, productCategory: null }))
              }
            />
          )}
          {filterOptions.isProductGroup && filterOptions.productGroup && (
            <FilterLable
              name={t("Product Group")}
              value={filterOptions.productGroup.GroupName}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, productGroup: null }))
              }
            />
          )}
          {filterOptions.isBrand && filterOptions.brand && (
            <FilterLable
              name={t("Brand")}
              value={filterOptions.brand.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, brand: null }))
              }
            />
          )}

          {filterOptions.supplier && (
            <FilterLable
              name={t("Supplier")}
              value={filterOptions.supplier.PartyName}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, supplier: null }))
              }
            />
          )}

          {filterOptions.customer && (
            <FilterLable
              name={t("Customer")}
              value={filterOptions.customer.PartyName}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, customer: null }))
              }
            />
          )}
          {filterOptions.warehouse && (
            <FilterLable
              name={t("Warehouse")}
              value={filterOptions.warehouse.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, user: null }))
              }
            />
          )}

          {filterOptions.user && (
            <FilterLable
              name={t("User")}
              value={filterOptions.user.name}
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, user: null }))
              }
            />
          )}

          {filterOptions.withVoucher && (
            <FilterLable
              name={t("With Voucher")}
              value="True"
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, withVoucher: false }))
              }
            />
          )}

          {!filterOptions.showActiveProductsOnly && (
            <FilterLable
              name={t("Show Inactive Products")}
              value="True"
              handleDelete={() =>
                setFilterOptions((prev) => ({
                  ...prev,
                  showActiveProductsOnly: true,
                }))
              }
            />
          )}

          {filterOptions.unitInReport && (
            <FilterLable
              name={t("Unit In Report")}
              value="True"
              handleDelete={() =>
                setFilterOptions((prev) => ({ ...prev, unitInReport: false }))
              }
            />
          )}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Serial No")} </VBTableCellHeader>
            {filterOptions.withVoucher && (
              <>
                <VBTableCellHeader>{t("Voucher No")} </VBTableCellHeader>
                <VBTableCellHeader>{t("Voucher Date")} </VBTableCellHeader>
              </>
            )}
            <VBTableCellHeader>{t("Product Name")} </VBTableCellHeader>
            {/* {filterOptions.transactables !== "SI" &&
            filterOptions.transactables !== "SR" ? (
              <VBTableCellHeader>
                {filterOptions.transactables === "all"
                  ? t("Ledger Name")
                  : t("Supplier")}
              </VBTableCellHeader>
            ) : (
              <VBTableCellHeader>{t("Customer")} </VBTableCellHeader>
            )} */}
            {filterOptions.showLedger &&
              <VBTableCellHeader>{(filterOptions.transactables === 'PI' || filterOptions.transactables === "PR")?
                t("Supplier"):
                t("Customer")}
              </VBTableCellHeader>  
            }
            {filterOptions.showUnit && 
              <VBTableCellHeader>{t("Unit")} </VBTableCellHeader>
            }
            {filterOptions.transactables !== "SI" &&
              filterOptions.transactables !== "all" && (
                <>
                  {filterOptions.transactables === "PI" && (
                    <>
                      <VBTableCellHeader>
                        {t("Last Purchase Qty")}{" "}
                      </VBTableCellHeader>
                      <VBTableCellHeader sx={{ textAlign: "right" }}>
                        {t("Last Purchase Amount")}{" "}
                      </VBTableCellHeader>
                    </>
                  )}
                  {filterOptions.transactables === "PR" && (
                    <>
                      <VBTableCellHeader>
                        {t("Last Return Qty")}{" "}
                      </VBTableCellHeader>
                      <VBTableCellHeader sx={{ textAlign: "right" }}>
                        {t("Last Return Amount")}{" "}
                      </VBTableCellHeader>
                    </>
                  )}
                </>
              )}
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Qty")}{" "}
            </VBTableCellHeader>
            {filterOptions.transactables !== "PR" &&
              filterOptions.transactables !== "SR" && (
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Free Qty")}{" "}
                </VBTableCellHeader>
              )}
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Amount")}{" "}
            </VBTableCellHeader>
            {filterOptions.transactables === "PR" &&  filterOptions.transactables === 'SR' &&(
              <>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Return Qty")}{" "}
                </VBTableCellHeader>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Return Amount")}{" "}
                </VBTableCellHeader>
              </>
            )}
            {/* <VBTableCellHeader>Total Cost	 </VBTableCellHeader> */}
            {/* <VBTableCellHeader>Grand Total </VBTableCellHeader> */}
            <VBTableCellHeader
              sx={{ textAlign: "right", width: "20px", padding: "3px" }}
            >
              <BorderColorIcon
                sx={{
                  color: "#0A9EF3",
                  cursor: "pointer",
                  width: "20px",
                  textAlign: "center",
                }}
                onClick={handlePenIconClick}
              />
            </VBTableCellHeader>
          </VBTableHeader>
          {isLoading.report ? (
            <VBSkeletonLoader />
          ) : (
            [
              <TableBody>
                {getDataForPage().map((data, i) => (
                  <TableRow>
                    <VBTableCellBody>
                      {(page_no - 1) * appSetting.itemPerPage + i + 1}
                    </VBTableCellBody>
                    {filterOptions.withVoucher && (
                      <>
                        <VBTableCellBody>{data.VoucherNo}</VBTableCellBody>
                        <VBTableCellBody>
                          {data.VoucherDate &&
                            moment(data.VoucherDate).format("YYYY-MM-DD")}
                        </VBTableCellBody>
                      </>
                    )}
                    <VBTableCellBody>{data.ProductName}</VBTableCellBody>
                    {filterOptions.showLedger &&
                      <VBTableCellBody>{data.LedgerName}</VBTableCellBody>
                    }
                    {filterOptions.showUnit &&
                      <VBTableCellBody>{data.UnitName}</VBTableCellBody>
                    }
                    {filterOptions.transactables !== "SI" &&
                      filterOptions.transactables !== "all" &&
                      filterOptions.transactables !== "SR" && [
                        <VBTableCellBody>{data.LastQty}</VBTableCellBody>,
                        <VBTableCellBody sx={{ textAlign: "right" }}>
                          {formatNumber(data.LastGrossAmount)}
                        </VBTableCellBody>,
                      ]}
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {formatNumber(data.Qty)}
                    </VBTableCellBody>
                    {filterOptions.transactables !== "PR" &&
                      filterOptions.transactables !== "SR" && (
                        <VBTableCellBody sx={{ textAlign: "right" }}>
                          {formatNumber(data.FreeQty)}
                        </VBTableCellBody>
                      )}
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {formatNumber(data.GrossAmount)}
                    </VBTableCellBody>
                    {filterOptions.transactables === "PR" && filterOptions.transactables === 'SR' && (
                      <>
                        <VBTableCellBody sx={{ textAlign: "right" }}>
                          {formatNumber(data.ReturnQty)}
                        </VBTableCellBody>
                        <VBTableCellBody sx={{ textAlign: "right" }}>
                          {formatNumber(data.ReturnAmount)}
                        </VBTableCellBody>
                      </>
                    )}
                    <VBTableCellBody>{""}</VBTableCellBody>
                  </TableRow>
                ))}
                <TableRow></TableRow>
              </TableBody>,
              <VBTableFooter>
                <TableRow>
                  <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
                  <VBTableCellFooter></VBTableCellFooter>
                  {filterOptions.showUnit &&
                    <VBTableCellFooter></VBTableCellFooter>
                  }
                  {filterOptions.showLedger &&
                    <VBTableCellFooter></VBTableCellFooter>
                  }
                  {filterOptions.withVoucher && (
                    <>
                      <VBTableCellFooter></VBTableCellFooter>
                      <VBTableCellFooter></VBTableCellFooter>
                    </>
                  )}
                  {filterOptions.transactables !== "SI" &&
                    filterOptions.transactables !== "all" &&
                    filterOptions.transactables !== "SR" && [
                      <VBTableCellFooter>
                        {/* {reportData.total.LastQty} */}
                      </VBTableCellFooter>,
                      <VBTableCellFooter sx={{ textAlign: "right" }}>
                        {formatNumber(reportData.total.LastGrossAmount)}
                      </VBTableCellFooter>,
                    ]}
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {formatNumber(reportData.total.Qty)}
                  </VBTableCellFooter>
                  {filterOptions.transactables !== "PR" &&
                    filterOptions.transactables !== "SR" && (
                      <VBTableCellFooter sx={{ textAlign: "right" }}>
                        {formatNumber(reportData.total.FreeQty)}
                      </VBTableCellFooter>
                    )}
                  <VBTableCellFooter sx={{ textAlign: "right" }}>
                    {formatNumber(reportData.total.GrossAmount)}
                  </VBTableCellFooter>
                  {filterOptions.transactables === "PR" && filterOptions.transactables === "SR" && (
                    <>
                      <VBTableCellFooter sx={{ textAlign: "right" }}>
                        {formatNumber(reportData.total.ReturnQty)}
                      </VBTableCellFooter>
                      <VBTableCellFooter sx={{ textAlign: "right" }}>
                        {formatNumber(reportData.total.ReturnAmount)}
                      </VBTableCellFooter>
                    </>
                  )}
                  <VBTableCellFooter></VBTableCellFooter>
                </TableRow>
              </VBTableFooter>,
            ]
          )}
        </VBTableContainer>
      </Paper>
      <Popover
            id={Boolean(anchorEl) ? "simple-popover" : undefined}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePenIconClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ padding: "10px" }}
          >
            <ViknCheckboxGroup
              radios={[
                {
                  label: t("Voucher"),
                  checked: filterOptions.withVoucher,
                  onChange: () => handleTableHeadingSelection("withVoucher"),
                },
                {
                  label: t("Ledger Name"),
                  checked: filterOptions.showLedger,
                  onChange: () => handleTableHeadingSelection("showLedger"),
                },
                {
                  label: t("Unit"),
                  checked: filterOptions.showUnit,
                  onChange: () => handleTableHeadingSelection("showUnit"),
                },
              ]}
            />
          </Popover>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={handleFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              {/* Product Search AutoComplete */}
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                minDate={moment().subtract(3, "months")}
                fromOnChange={(date) =>
                  setFilterOptions((prev) => ({ ...prev, fromDate: date }))
                }
                toOnChange={(date) =>
                  setFilterOptions((prev) => ({ ...prev, toDate: date }))
                }
              />
            </ListItem>
            {/* <Divider /> */}
            <ListItem>
              <ViknCheckboxGroup radios={radiosValue} />
            </ListItem>
            {/* <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("With Voucher"),
                    checked: filterOptions.withVoucher,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        withVoucher: !prev.withVoucher,
                      }));
                    },
                  },
                ]}
              />
            </ListItem> */}
            {filterOptions.isProduct && (
              <ListItem>
                <ViknCheckboxGroup
                  radios={[
                    {
                      label: t("Show Inactive Products"),
                      checked: filterOptions.showActiveProductsOnly
                        ? false
                        : true,
                      onChange: () => {
                        setFilterOptions((prev) => ({
                          ...prev,
                          showActiveProductsOnly: !prev.showActiveProductsOnly,
                        }));
                        searchProduct();
                      },
                    },
                  ]}
                />
              </ListItem>
            )}
            {/* <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Unit In Reports"),
                    checked: filterOptions.unitInReport,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        unitInReport: !prev.unitInReport,
                      }));
                      searchProduct();
                    },
                  },
                ]}
              />
            </ListItem>
            {/* <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Show Ledger"),
                    checked: filterOptions.showLedger,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        showLedger: !prev.showLedger,
                      }));
                      searchProduct();
                    },
                  },
                ]}
              />
            </ListItem> */}
            {/* <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Show Unit"),
                    checked: filterOptions.showUnit,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        showUnit: !prev.showUnit,
                      }));
                      searchProduct();
                    },
                  },
                ]}
              />
            </ListItem> */}
            <ListItem>
              {filterOptions.isProduct && (
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Search for Product")}
                  value={filterOptions.product}
                  loading={isLoading.product}
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        (option.ProductName &&
                          option.ProductName.toLowerCase().includes(
                            inputValue.toLowerCase()
                          )) ||
                        (option.ProductCode &&
                          option.ProductCode.toLowerCase().includes(
                            inputValue.toLowerCase()
                          ))
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Box sx={{ fontSize: "14px", color: "#797979", mr: 2 }}>
                        {option.ProductCode}
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>{option.ProductName}</Box>
                    </Box>
                  )}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, product: newValue }))
                  }
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setCsValue({ ...csValue, product: value });
                    }
                  }}
                  options={filterData.productList}
                  getOptionLabel={(option) => option.ProductName}
                />
              )}
              {filterOptions.isProductCategory && (
                <ViknAutoComplete
                  placeholder={t("Category")}
                  value={filterOptions.productCategory}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({
                      ...prev,
                      productCategory: newValue,
                    }))
                  }
                  options={filterData.productCategoryList}
                  getOptionLabel={(option) => option?.name}
                  // isOptionEqualToValue={(option, value) => option?.name === value?.name}
                />
              )}
              {filterOptions.isProductGroup && (
                <ViknAutoComplete
                  placeholder={t("Group")}
                  value={filterOptions.productGroup}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({
                      ...prev,
                      productGroup: newValue,
                    }))
                  }
                  options={filterData.productGrouplist}
                  getOptionLabel={(option) => option.GroupName}
                  // isOptionEqualToValue={(option, value) => option?.GroupName === value?.GroupName}
                />
              )}
              {filterOptions.isBrand && (
                <ViknAutoComplete
                  placeholder={t("Brand")}
                  value={filterOptions.brand}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, brand: newValue }))
                  }
                  options={filterData.brandList}
                  getOptionLabel={(option) => option?.name}
                  // isOptionEqualToValue={(option, value) => option?.name === value?.name}
                />
              )}
            </ListItem>
            {(filterOptions.transactables === "PI" || filterOptions.transactables === 'PR') && filterOptions.showLedger &&
              <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                placeholder={t("Supplier")}
                label={t("Supplier")}
                loading={isLoading.supplier}
                options={filterData.supplierList}
                getOptionLabel={(option) => option.PartyName || ""}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (option) =>
                      (option.PartyName &&
                        option.PartyName.toLowerCase().includes(
                          inputValue.toLowerCase()
                        )) ||
                      (option.PartyCode &&
                        option.PartyCode.toLowerCase().includes(
                          inputValue.toLowerCase()
                        ))
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Box sx={{ fontSize: "14px", color: "#797979", mr: 2 }}>
                      {option.PartyCode}
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>{option.PartyName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => {
                  if (reason === "input") {
                    setCsValue({ ...csValue, name: value, PartyType: 2 });
                  }
                }}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    supplier: newValue,
                    customer: null,
                  }))
                }
                value={filterOptions.supplier}
              />
            </ListItem>
            }
           
            {(filterOptions.transactables === 'SI' || filterOptions.transactables === "SR") && filterOptions.showLedger &&
              <ListItem>
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Customer")}
                  label={t("Customer")}
                  loading={isLoading.customer}
                  options={filterData.customerList}
                  getOptionLabel={(option) => option.PartyName || ""}
                  filterOptions={(options, { inputValue }) =>
                    options.filter(
                      (option) =>
                        (option.PartyName &&
                          option.PartyName.toLowerCase().includes(
                            inputValue.toLowerCase()
                          )) ||
                        (option.PartyCode &&
                          option.PartyCode.toLowerCase().includes(
                            inputValue.toLowerCase()
                          ))
                    )
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Box sx={{ fontSize: "14px", color: "#797979", mr: 2 }}>
                        {option.PartyCode}
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>{option.PartyName}</Box>
                    </Box>
                  )}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setCsValue({ ...csValue, name: value, PartyType: 1 });
                    }
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({
                      ...prev,
                      customer: newValue,
                      supplier: null,
                    }))
                  }
                  value={filterOptions.customer}
                />
              </ListItem>
            }
            {EnableWarehouse && (
              <ListItem>
                <ViknSearchAutoComplete
                  placeholder={t("Warehouse")}
                  label={t("Warehouse")}
                  options={filterData.warehouseList}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value?.name
                  }
                  onChange={(event, newValue) =>
                    setFilterOptions((prevState) => ({
                      ...prevState,
                      warehouse: newValue,
                    }))
                  }
                  value={filterOptions.warehouse}
                />
              </ListItem>
            )}
            <ListItem>
              <ViknSearchAutoComplete
                placeholder={t("User")}
                label={t("User")}
                options={filterData.userList}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value?.name
                }
                onChange={(event, newValue) =>
                  setFilterOptions((prevState) => ({
                    ...prevState,
                    user: newValue,
                  }))
                }
                value={filterOptions.user}
              />
            </ListItem>
            <ListItem>
              <ViknRadioGroup
                value={filterOptions.transactables}
                radios={[
                  // {
                  //   value: "all",
                  //   label: t("All"),
                  // },
                  {
                    value: "PI",
                    label: t("Purchase"),
                  },
                  {
                    value: "SI",
                    label: t("Sales"),
                  },
                  {
                    value: "PR",
                    label: t("Purchase Return"),
                  },
                  {
                    value: "SR",
                    label: t("Sales Return"),
                  },
                ]}
                handleChange={handleChange}
              />
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default ProductSummaryReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "32%",
}));
