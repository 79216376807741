import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { callGeneralSettingsList } from "../Api/generalSettings/generalSettings";

let initialState = {
  generalSettings: {
    EnableLoyaltySettings: "False",
    ReferenceBillNo: "False",
    is_Loyalty_SalesReturn_MinimumSalePrice: "False",
    show_productcode_InsearchPurchase: "False",
    ShowProfitinSalesRegisterReport: "False",
    AllowNegativeStockSales: "True",
    BlockSalesBelowPurchase: "True",
    show_stock_InsearchPurchase: "False",
    AllowQtyDividerinSales: "False",
    Free_Quantity_In_Purchase: "True",
    Free_Quantity_In_Sales: "False",
    MultiUnit: "True",
    loyalty_PointValue: "0",
    show_stock_InsearchSale: "False",
    AllowUpdateBatchPriceInSales: "False",
    GST: "False",
    Print_After_Save_Active: "false",
    ShowSettingsinSales: "False",
    ShowDiscountInPayments: "False",
    EnableDeliveryDateInSales: "True",
    PurchasePriceUpdateMultiUnit: "False",
    Purchase_VAT_Type: "32",
    ShowDueBalanceInSales: "False",
    Sales_GST_Type: "22",
    PriceDecimalPoint: "2",
    show_salesPrice_InsearchPurchase: "False",
    ShowAllProductsInWorkOrder: "False",
    ShowCustomerLastSalesPriceinSales: "False",
    Show_Sales_Type: "False",
    ShowSettingsinPurchase: "False",
    EnableCardDetails: "False",
    ShowEmployeesInSales: "False",
    AutoIncrimentQty: "False",
    blockSaleByBillDisct: "False",
    EnableItemCodeNoInSales: "False",
    PurchasePriceUpdate: "False",
    EnableWarehouse: "False",
    AllowAdvanceReceiptinPurchase: "False",
    AllowCashReceiptMorePurchaseAmt: "False",
    ShowNegativeBatchInSales: "False",
    ShowSupplierInSales: "False",
    AllowCashReceiptMoreSaleAmt: "False",
    ShowPositiveStockInSales: "False",
    CustomerBasedPrint: "False",
    InclusiveRatePurchase: "False",
    ShowExpenseInStockValue: "False",
    Order_Print: "false",
    Purchase_GST_Type: "27",
    EnableProductBatchWise: "False",
    EnableDuplicateProductName: "False",
    Kitchen_Print: "false",
    EnableCreditPeriod: "False",
    BlockTransactionsByDate: "False",
    VAT: "True",
    show_productcode_InsearchSale: "False",
    PriceCategory: "False",
    EnableCreditLimit: "False",
    ShowCustomerInPurchase: "False",
    AllowNegativeStockInStockTransfer: "False",
    ShowManDateAndExpDatePurchase: "False",
    KFC: "False",
    PurchasePriceInSales: "False",
    PostDateTransaction: "30",
    CreateBatchForWorkOrder: "False",
    show_purchasePrice_InsearchSale: "False",
    RoundingFigure: "5",
    BlockSalesPrice: "False",
    ShowWarrantyPeriodInProduct: "False",
    ShowMaximumStockAlert: "False",
    EnableVoucherNoUserWise: "False",
    AllowExtraSerielNos: "False",
    EnableBranch: "True",
    EnableForeignCurrency: "True",
    QtyDecimalPoint: "2",
    ShowDiscountInReceipts: "False",
    Bill_Discount: "False",
    ShowCustomerCode: "False",
    ShowDiscountInPurchase: "False",
    Increment_Qty_In_POS: "false",
    ShowDueBalanceInPurchase: "False",
    Additional_Discount: "False",
    SalesPriceUpdate: "False",
    EnableRoute: "False",
    ShowYearMonthCalanderInWorkOrder: "False",
    RoundOffSales: "5",
    Show_Purchase_Type: "False",
    RoundOffPurchase: "5",
    loyalty_point_value: "0",
    ShowSupplierLastPurchasePriceinPurchase: "False",
    AllowAdvanceReceiptinSales: "False",
    ShowDiscountInSales: "False",
    show_brand_InsearchSale: "True",
    PreDateTransaction: "30",
    EnableCardNetWork: "False",
    InclusiveRateWorkOrder: "False",
    EnableShippingCharge: "False",
    EnableSalesManInSales: "True",
    EnableTransilationInProduct: "False",
    ShowDescriptionInSales: "False",
    VoucherNoAutoGenerate: "False",
    SalesPriceLessThanMinimumSalesPrice: "Ignore",
    EnableSerialNoInSales: "False",
    BatchCriteria: "PurchasePrice",
    EnableBillwise: "True",
    EnableAdditionalExpense: "True",
    ShowSalesPriceInPurchase: "True",
    EnableFaeraSettings: "True",
    SalesPriceUpdateMultiUnit: "False",
    Sales_VAT_Type: "32",
    ShowInactiveUsers: "False",
    Company_Type: "VAT",
    EnableVbassist : "false",
    UnitPriceUpdatebyItem: "false",
    ShowLedgerWisePurchaseHistory : "false",
  },
  lite: {
    "LoyaltyCustomer":false,
    "Loyalty Customer":false,
    "LoyalityProgram":false,
    "Loyalty Program":false,
    "Loyalty":false,
    "BankReconciliation":false,
    "Bank Reconciliation":false,
    "BankReconciliationReport":false,
    "BankStatmentReport":false,
    "Purchase Return Register Report":false,
    "PurchaseReturnRegisterReport":false,
    "ProductReport":false,
    "Product Report":false,
    "ShortageStockReport":false,
    "Shortage Stock Report":false,
    "ExcessStockReport":false,
    "Excess Stock Report":false,
    "VanReport":false,
    "VanStockReport":false,
    "ActivityLog":false,
    "VanSalesSettings":false,
    "Manufacture":false,
  
    "DashboardChart":false,
    "ProductSummary":false,
    "OutstandingReport":false,
    "Outstanding Report":false,
    "List":false,
    "ListReport":false,
    "Sales Order":false,
    "SalesSummary":false,
    "Sales Summary Report":false,
    "BillwiseProfit":false,
    "BillwiseReport":false,
    "Bill Wise Report":false,
    "Purchase Estimate":false,
    "Warehouse":false,
    "Shelf":false,
    "WorkOrder":false,
    "Work Order":false,
    "StockTransfer":false,
    "Stock Transfer":false,
    "Inventory":false,
    "StockTransferRegister":false,
    "SupplierVsProductReport":false,
    "Supplier Vs Product Report":false,
    "ProductAnalysisReport":false,
    "Product Analysis Report":false,
    "BatchWiseReport":false,
    "Batch Wise Report":false,
    "PriceChecker":false,
    "Route":false,
    "Enable route":false,
    "StockOrder":false,
    "StockManagement":false,
    "Stock Management":false,
    "PriceCategory":false,
    "Price Category":false,
    "SalesRegisterReport":false,
    "Sales Register Report":false,
    "SalesIntegratedReport":false,
    "Sales Integrated Report":false,
    "SalesReturnRegisterReport":false,
    "Sales Return Register Report":false,
    "PurchaseRegisterReport":false,
    "Purchase Register Report":false,
    "PurchaseIntegratedReport":false,
    "Purchase Integrated Report":false,
    "CreditorAgeingreport":false,
    "DebtorAgeingreport":false,
    "ExpenseSummary":false,
    "QuickReport":false,
    "BranchTransfer":false,
    "EnableForeignCurrency":false,
    "EnableAdditionalExpense":false,
    "DebitNote":false,
    "CreditNote":false,
    "BatchReport":false,
    "Batch Report":false,
    "Sales GST Report": false,
    "Purchase GST Report": false,
    "GSTR3B": false,
    "Sales VAT Report" : false,
    "Purchase VAT Report": false,
  
    "OpeningStock":false,
    "Opening Stock":false,
    "AccountGroup":false,
    "Account Group":false,
    "PurchaseTaxReport":false,
    "SalesTaxReport":false,
    "TrialBalance":false,
    "Trial Balance":false,
    "TradingProfitAndLossAccount":false,
    "Profit And Loss Account":false,
    "BalanceSheet":false,
    "Balance Sheet":false,
    "SalesOrder":false,
    "SalesOrderReport":false,
    "SalesEstimate":false,
    "Sales Estimate":false,
    "ExpenseReport":false,
    "ExpenseList":false,
    "MultiUnit":false,
    "StockLedgerReport":false,
    "StockValueReport":false,
    "Stock Value Report":false,
    "DailyReport":false,
    "Stock Ledger Report":false,
    "DeliveryNote":false,
    "BOM":false,
    "faera report": false,
  }
};

export const callSettingsTunk = createAsyncThunk(
  "fetchGeneralSettings",
  async () => {
    const res = await callGeneralSettingsList({ type_data: "list" });
    return res.data;
  }
);

const generealSetting = createSlice({
  name: "generalSettings",
  initialState: initialState,
  reducers: {
    generalSettingsSuccess: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.generalSettings[key] = action.payload[key];
      });

      localStorage.setItem("generalSettings", JSON.stringify(action.payload));
    },
    generalSettingsUpdate: (state, action) => {
      // state.generalSettings[key] = action.payload[key]
      // console.log(state.generalSettings);
      // console.log(action);
      state.generalSettings[action.payload.key] = action.payload.value
    },
    generalSettingsLogoutSuccess: (state, action) => {
      // Object.keys(action.payload).forEach((key) => {
      //   state.generalSettings[key] = null;
      // });
      localStorage.removeItem("generalSettings");
    },

  },
  
  extraReducers: (builder) => {
    builder.addCase(callSettingsTunk.pending, (state, action) => {});
    builder.addCase(callSettingsTunk.fulfilled, (state, action) => {
      // state.generalSettings
      Object.keys(action.payload).forEach((key) => {
        state.generalSettings[key] = action.payload[key];
      });
      // console.log();
      state.generalSettings.Company_Type = action.payload.VAT ? "VAT" : "GST"

      // state.lite["BatchReport"] = action.payload.EnableProductBatchWise
      // state.lite["Batch Report"] = action.payload.EnableProductBatchWise
      // state.lite["Sales GST Report"] = action.payload.GST
      // state.lite["Purchase GST Report"] = action.payload.GST
      // state.lite["GSTR3B"] = action.payload.GST
      // state.lite["Sales VAT Report"] = action.payload.VAT
      // state.lite["Purchase VAT Report"] = action.payload.VAT
      // state.lite["faera report"] = action.payload.EnableFaeraSettings

      localStorage.setItem("generalSettings", JSON.stringify(action.payload));
    });
    builder.addCase(callSettingsTunk.rejected, (state, action) => {});
  },
});

export const { generalSettingsSuccess, generalSettingsUpdate, generalSettingsLogoutSuccess } = generealSetting.actions;
export default generealSetting.reducer;