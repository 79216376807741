import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { createSvgIcon } from "@mui/material/utils";

// 📢 📢 📢 Important Not To add Dynamic Color to svg add "fill="currentColor"" to path
// fill="currentColor"

const SearchNormal = createSvgIcon(
  <svg
    // width="18"
    // height="17"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 17"
    fill="none"
  >
    <path
      d="M8.09605 14.1063C11.5774 14.1063 14.3996 11.2841 14.3996 7.80278C14.3996 4.32141 11.5774 1.49921 8.09605 1.49921C4.61469 1.49921 1.79248 4.32141 1.79248 7.80278C1.79248 11.2841 4.61469 14.1063 8.09605 14.1063Z"
      stroke="currentColor"
      stroke-width="1.0506"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6501 14.5895C14.0213 15.7101 14.8688 15.8222 15.5202 14.8416C16.1155 13.9451 15.7233 13.2097 14.6447 13.2097C13.8462 13.2027 13.398 13.8261 13.6501 14.5895Z"
      stroke="currentColor"
      stroke-width="1.0506"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const DollerCircle = createSvgIcon(
  <svg
    // width="25"
    // height="25"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
  >
    <path
      d="M13.5776 17.6397H11.0676C9.42765 17.6397 8.09765 16.2597 8.09765 14.5597C8.09765 14.1497 8.43765 13.8097 8.84765 13.8097C9.25765 13.8097 9.59765 14.1497 9.59765 14.5597C9.59765 15.4297 10.2576 16.1397 11.0676 16.1397H13.5776C14.2276 16.1397 14.7676 15.5597 14.7676 14.8597C14.7676 13.9897 14.4576 13.8197 13.9476 13.6397L9.91765 12.2197C9.13765 11.9497 8.08765 11.3697 8.08765 9.57971C8.08765 8.03971 9.29765 6.79971 10.7776 6.79971H13.2876C14.9276 6.79971 16.2576 8.17971 16.2576 9.87971C16.2576 10.2897 15.9176 10.6297 15.5076 10.6297C15.0976 10.6297 14.7576 10.2897 14.7576 9.87971C14.7576 9.00971 14.0976 8.29971 13.2876 8.29971H10.7776C10.1276 8.29971 9.58765 8.87971 9.58765 9.57971C9.58765 10.4497 9.89765 10.6197 10.4076 10.7997L14.4376 12.2197C15.2176 12.4897 16.2676 13.0697 16.2676 14.8597C16.2576 16.3897 15.0576 17.6397 13.5776 17.6397Z"
      fill="currentColor"
    />
    <path
      d="M12.1776 18.9697C11.7676 18.9697 11.4276 18.6297 11.4276 18.2197V6.21973C11.4276 5.80973 11.7676 5.46973 12.1776 5.46973C12.5876 5.46973 12.9276 5.80973 12.9276 6.21973V18.2197C12.9276 18.6297 12.5876 18.9697 12.1776 18.9697Z"
      fill="currentColor"
    />
    <path
      d="M12.1776 22.9697C6.24761 22.9697 1.42761 18.1497 1.42761 12.2197C1.42761 6.28973 6.24761 1.46973 12.1776 1.46973C18.1076 1.46973 22.9276 6.28973 22.9276 12.2197C22.9276 18.1497 18.1076 22.9697 12.1776 22.9697ZM12.1776 2.96973C7.07761 2.96973 2.92761 7.11973 2.92761 12.2197C2.92761 17.3197 7.07761 21.4697 12.1776 21.4697C17.2776 21.4697 21.4276 17.3197 21.4276 12.2197C21.4276 7.11973 17.2776 2.96973 12.1776 2.96973Z"
      fill="currentColor"
    />
  </svg>
);

const EditLine = createSvgIcon(
  <svg
    // width="24"
    // height="25"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M12.9493 6.5514L6.79017 12.8334C6.5576 13.072 6.33254 13.5419 6.28753 13.8672L6.00995 16.2094C5.91243 17.0552 6.5426 17.6335 7.41283 17.4889L9.82849 17.0913C10.1661 17.0335 10.6387 16.7949 10.8713 16.5491L17.0305 10.2671C18.0957 9.18276 18.5759 7.9466 16.9179 6.43573C15.2675 4.93932 14.0146 5.46704 12.9493 6.5514Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 7.51108C12.2367 9.65477 13.9697 11.2936 15.5 11.5111"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 19.5111L18 19.5111"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Convert3D = createSvgIcon(
  <svg
    // width="24"
    // height="25"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M22 15.0111C22 18.8811 18.87 22.0111 15 22.0111L16.05 20.2611"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2 9.01108C2 5.14108 5.13 2.01108 9 2.01108L7.95 3.76108"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7 4.46109L17.6799 6.76108L21.6199 4.4711"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.6799 10.8311V6.75107"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.74 2.22108L14.34 3.55103C13.8 3.85103 13.35 4.61103 13.35 5.23103V7.77107C13.35 8.39107 13.79 9.15106 14.34 9.45106L16.74 10.7811C17.25 11.0711 18.09 11.0711 18.61 10.7811L21.01 9.45106C21.55 9.15106 22 8.39107 22 7.77107V5.23103C22 4.61103 21.56 3.85103 21.01 3.55103L18.61 2.22108C18.1 1.94108 17.26 1.94108 16.74 2.22108Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.34998 15.4611L6.31998 17.7611L10.27 15.4711"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.31995 21.8311V17.7511"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.39 13.2211L2.99001 14.551C2.45001 14.851 2 15.611 2 16.231V18.7711C2 19.3911 2.44001 20.1511 2.99001 20.4511L5.39 21.7811C5.9 22.0711 6.73999 22.0711 7.25999 21.7811L9.66 20.4511C10.2 20.1511 10.65 19.3911 10.65 18.7711V16.231C10.65 15.611 10.21 14.851 9.66 14.551L7.25999 13.2211C6.73999 12.9411 5.9 12.9411 5.39 13.2211Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ShareLine = createSvgIcon(
  <svg
    // width="39"
    // height="35"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 39 35"
    fill="none"
  >
    <path
      d="M26.7838 22.7352L26.7841 22.7352C27.4011 22.7351 27.9979 22.915 28.4906 23.2431C28.9827 23.5708 29.3427 24.027 29.5307 24.5408C29.7183 25.0538 29.7283 25.6062 29.5599 26.124C29.3912 26.6427 29.0489 27.1092 28.5701 27.4516C28.0906 27.7945 27.5014 27.9932 26.8849 28.0128C26.2683 28.0323 25.6647 27.8715 25.1593 27.5586C24.6546 27.2461 24.2773 26.8006 24.0703 26.2921C23.8637 25.7846 23.8338 25.2327 23.9829 24.7105L24.1499 24.1257L23.6111 23.8438L15.1901 19.4379L14.7117 19.1876L14.3241 19.5634C13.9277 19.9478 13.4103 20.2188 12.8355 20.3339C12.2605 20.4489 11.6619 20.4011 11.1189 20.198C10.5761 19.9951 10.1198 19.649 9.8026 19.2126C9.48602 18.777 9.32094 18.2696 9.32094 17.7556C9.32094 17.2416 9.48602 16.7342 9.8026 16.2985C10.1198 15.8621 10.5761 15.516 11.1189 15.3131L10.8574 14.6137L11.1189 15.3131C11.6619 15.1101 12.2605 15.0622 12.8355 15.1773C13.4103 15.2923 13.9277 15.5634 14.3241 15.9477L14.7117 16.3235L15.1901 16.0732L23.6111 11.6673L24.1504 11.3852L23.9828 10.8001C23.8095 10.1951 23.8781 9.55148 24.1811 8.98531C24.485 8.4173 25.0089 7.95857 25.6671 7.70907C26.3259 7.45932 27.0629 7.44123 27.7361 7.6591C28.4087 7.8768 28.9593 8.31066 29.2957 8.865L29.9341 8.47756L29.2957 8.865C29.6311 9.41751 29.7352 10.0576 29.5963 10.6691C29.4571 11.2818 29.0794 11.8397 28.5169 12.2296C27.9533 12.6203 27.248 12.811 26.534 12.7568C25.82 12.7026 25.1614 12.4086 24.6782 11.9428L24.2907 11.5692L23.8137 11.8187L15.3927 16.2246L14.8535 16.5067L15.021 17.0918C15.1457 17.5271 15.1457 17.984 15.021 18.4193L14.8535 19.0044L15.3927 19.2865L23.8137 23.6924L24.2929 23.9431L24.6805 23.5661C24.9472 23.3068 25.2706 23.0976 25.6329 22.9537C25.9951 22.8098 26.3868 22.735 26.7838 22.7352Z"
      stroke="black"
      stroke-width="1.49344"
    />
  </svg>
);

const LinkLine = createSvgIcon(
  <svg
    // width="24"
    // height="25"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M16.5 18.7611H14.99C14.58 18.7611 14.24 18.4211 14.24 18.0111C14.24 17.6011 14.58 17.2611 14.99 17.2611H16.5C19.12 17.2611 21.25 15.1311 21.25 12.5111C21.25 9.89108 19.12 7.76108 16.5 7.76108H15C14.59 7.76108 14.25 7.42108 14.25 7.01108C14.25 6.60108 14.58 6.26108 15 6.26108H16.5C19.95 6.26108 22.75 9.06108 22.75 12.5111C22.75 15.9611 19.95 18.7611 16.5 18.7611Z"
      fill="#292D32"
    />
    <path
      d="M9 18.7611H7.5C4.05 18.7611 1.25 15.9611 1.25 12.5111C1.25 9.06108 4.05 6.26108 7.5 6.26108H9C9.41 6.26108 9.75 6.60108 9.75 7.01108C9.75 7.42108 9.41 7.76108 9 7.76108H7.5C4.88 7.76108 2.75 9.89108 2.75 12.5111C2.75 15.1311 4.88 17.2611 7.5 17.2611H9C9.41 17.2611 9.75 17.6011 9.75 18.0111C9.75 18.4211 9.41 18.7611 9 18.7611Z"
      fill="#292D32"
    />
    <path
      d="M16 13.2611H8C7.59 13.2611 7.25 12.9211 7.25 12.5111C7.25 12.1011 7.59 11.7611 8 11.7611H16C16.41 11.7611 16.75 12.1011 16.75 12.5111C16.75 12.9211 16.41 13.2611 16 13.2611Z"
      fill="#292D32"
    />
  </svg>
);

const PrinterLine = createSvgIcon(
  <svg
    // width="25"
    // height="25"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M17.8339 17.5111H19.8339C20.3643 17.5111 20.873 17.3004 21.2481 16.9253C21.6231 16.5502 21.8339 16.0415 21.8339 15.5111V11.5111C21.8339 10.9806 21.6231 10.4719 21.2481 10.0969C20.873 9.72179 20.3643 9.51108 19.8339 9.51108H5.83386C5.30343 9.51108 4.79472 9.72179 4.41965 10.0969C4.04458 10.4719 3.83386 10.9806 3.83386 11.5111V15.5111C3.83386 16.0415 4.04458 16.5502 4.41965 16.9253C4.79472 17.3004 5.30343 17.5111 5.83386 17.5111H7.83386M17.8339 9.51108V5.51108C17.8339 4.98064 17.6231 4.47194 17.2481 4.09686C16.873 3.72179 16.3643 3.51108 15.8339 3.51108H9.83386C9.30343 3.51108 8.79472 3.72179 8.41965 4.09686C8.04458 4.47194 7.83386 4.98064 7.83386 5.51108V9.51108M7.83386 15.5111C7.83386 14.9806 8.04458 14.4719 8.41965 14.0969C8.79472 13.7218 9.30343 13.5111 9.83386 13.5111H15.8339C16.3643 13.5111 16.873 13.7218 17.2481 14.0969C17.6231 14.4719 17.8339 14.9806 17.8339 15.5111V19.5111C17.8339 20.0415 17.6231 20.5502 17.2481 20.9253C16.873 21.3004 16.3643 21.5111 15.8339 21.5111H9.83386C9.30343 21.5111 8.79472 21.3004 8.41965 20.9253C8.04458 20.5502 7.83386 20.0415 7.83386 19.5111V15.5111Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const SelectIcon = createSvgIcon(
  <svg
    // width="25"
    // height="24"
    // xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M16.2683 14.3533C16.2688 14.4439 16.2326 14.5309 16.1681 14.5946L12.5248 18.238C12.3914 18.3712 12.1754 18.3712 12.042 18.238L8.39868 14.5946C8.27328 14.4601 8.27698 14.2503 8.40705 14.1203C8.53712 13.9902 8.74684 13.9865 8.88142 14.1119L12.2834 17.5139L15.6854 14.1119C15.8188 13.9787 16.0348 13.9787 16.1681 14.1119C16.2326 14.1756 16.2688 14.2626 16.2683 14.3533Z"
      fill="black"
      stroke="black"
      stroke-width="0.5"
    />
    <path
      d="M8.18454 9.22968C8.18412 9.13902 8.22023 9.05201 8.28474 8.98831L11.9281 5.34495C12.0615 5.21176 12.2775 5.21176 12.4108 5.34495L16.0542 8.98831C16.1796 9.12289 16.1759 9.33261 16.0458 9.46268C15.9158 9.59276 15.706 9.59646 15.5715 9.47105L12.1695 6.06907L8.76748 9.47105C8.63413 9.60424 8.41809 9.60424 8.28474 9.47105C8.22023 9.40735 8.18412 9.32034 8.18454 9.22968Z"
      fill="black"
      stroke="black"
      stroke-width="0.5"
    />
  </svg>
);

const ArrowRightIcon = createSvgIcon(
  <svg
    // xmlns="http://www.w3.org/2000/svg"
    // width="14"
    // height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M8.41749 10.9783C8.30665 10.9783 8.19582 10.9375 8.10832 10.85C7.93915 10.6808 7.93915 10.4008 8.10832 10.2317L11.34 7.00001L8.10832 3.76834C7.93915 3.59917 7.93915 3.31917 8.10832 3.15001C8.27749 2.98084 8.55749 2.98084 8.72665 3.15001L12.2675 6.69084C12.4367 6.86001 12.4367 7.14001 12.2675 7.30917L8.72665 10.85C8.63915 10.9375 8.52832 10.9783 8.41749 10.9783Z"
      fill="currentColor"
    />
    <path
      d="M11.8592 7.4375H2.04175C1.80258 7.4375 1.60425 7.23917 1.60425 7C1.60425 6.76083 1.80258 6.5625 2.04175 6.5625H11.8592C12.0984 6.5625 12.2967 6.76083 12.2967 7C12.2967 7.23917 12.0984 7.4375 11.8592 7.4375Z"
      fill="currentColor"
    />
  </svg>
);

export {
  SearchNormal,
  DollerCircle,
  EditLine,
  Convert3D,
  ShareLine,
  LinkLine,
  PrinterLine,
  SelectIcon,
  ArrowRightIcon
};
