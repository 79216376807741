import React, { useEffect, useRef, useState } from "react";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import { useTranslation } from "react-i18next";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import { date_range } from "../../../../Function/comonFunction";
import moment from "moment";
import VBSelect from "../../../../Components/Utils/VBSelect";
import { CallVatReturn } from "../../../../Api/Reports/TaxsApi";
import { formatNumber } from "../../../../generalFunction";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Icons } from "../../../../Assets/AssetLog";
import PrintVatReturn from "./Functions/PrintVatReturn";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { UserRolePermission } from "../../../../Function/Editions";

const VATReturn = () => {
  let Export_view = UserRolePermission("VAT Return", "print_permission");
  const appSetting = useSelector((state) => state.generalSettingsSclice.generalSettings);
  console.log(appSetting," apppsettings");
  const dispatch = useDispatch();
  // State Handling
  const {CompanyID,BranchID,CurrencySymbol} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [t, i18n] = useTranslation("common");
  const tableRef = useRef(null);

  const [filterOptions, setFilterOptions] = useState({
    fromDate: moment(),
    toDate: moment(),
    dateRange: "Customize",
    language: i18n.language === "ar" ? "Arabic" : "English",
  });

  const [filterData, setFilterData] = useState({
    dateRangeList: ["Customize", "Monthly", "This Quarter"],
    languageList: ["English", "Arabic"],
    standard_sales: {
      Amount: 0.0,
      vat_amount: 0,
    },
    zero_rated_sales: {
      Amount: 0,
      vat_amount: 0,
    },
    exempt_sales: {
      Amount: 0,
      vat_amount: 0,
    },
    export_sales: {
      Amount: 0,
      vat_amount: 0,
    },
    private_healthcare_sales: {
      Amount: 0,
      vat_amount: 0,
    },
    total_sales: {
      Amount: 0.0,
      vat_amount: 0,
    },
    standard_purchase: {
      Amount: 0.0,
      vat_amount: 0.0,
    },
    import_subject_paid_purchase: {
      Amount: 0,
      vat_amount: 0,
    },
    import_subject_accounted_purchase: {
      Amount: 0,
      vat_amount: 0,
    },
    zero_rated_purchase: {
      Amount: 0.0,
      vat_amount: 0,
    },
    exempt_purchase: {
      Amount: 0,
      vat_amount: 0,
    },
    total_purchase: {
      Amount: 0.0,
      vat_amount: 0.0,
    },
    Total_VAT_due: {
      Amount: 0.0,
      vat_amount: 0.0,
    },
  });

  // Handle Function

  const handleDefaultFilter = function() {
    setFilterOptions({
      fromDate: moment(),
      toDate: moment(),
      dateRange: "Customize",
      language: i18n.language === "ar" ? "Arabic" : "English",
    });
  }

  const searchReport = function () {
  // Assuming Moment.js is included in your project

  function checkDateInRange(targetDate) {
    // Get current date using Moment.js
    const currentDate = filterOptions.toDate;

    // Calculate three-month range
    const startDate = moment(currentDate).subtract(3, 'months');

    // Check if targetDate is within the range
    if (moment(targetDate).isBetween(startDate, currentDate, null, '[]')) {
        return true;
    } else {
        return false;
    }
  }

  // Example usage
  const dateToCheck = filterOptions.fromDate.format("YYYY-MM-DD"); // Date to check (format as needed)
  const result = checkDateInRange(dateToCheck);
  console.log(result); // Output: true or false

  if (result) {
    fetchReport();
  }else{
    dispatch(
      openSnackbar({
        open : true,
        message : "The date should be in the range of 3 months.",
        severity : 'warning',
      })
    )

  }
  };

  const fetchReport = async function () {
    const payload = {
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      // BranchID: 1,
      // CompanyID: "924abda3-7706-47b0-a908-2e0013e75fbc",
      // PriceRounding: 2,
    };
    const res = await CallVatReturn(payload);
    if (res.StatusCode === 6000) {
      setFilterData({
        ...filterData,
        ...res
      })
    }
    console.log(res);
  };

  useEffect(() => {
    fetchReport()
  }, [])
  
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "VAT Return",
    sheet: "VATReturn",
  });

  return (
    <>
      {/* <Paper
        sx={{
          px: 2,
          py: 1,
          mb: 1,
          borderRadius: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "500" }} variant="h2">
          Tax Summary
        </Typography>
        <ExportBtn />
      </Paper> */}
      <Paper
        sx={{
          height: "100%",
          // pb: 4,
          width: "100%",
        }}
      >
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {/* {true ? "Tax Summary" : "Vat Summary"} */}
              {t("VAT Return Filing")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          {/* <ExportBtn /> */}
          {Export_view ? 
          <Button
            variant="text"
            startIcon={<img src={Icons.Export} alt="export" />}
            sx={{ textTransform: "none", fontWeight: "400", mx: 2 }}
            disableElevation
            onClick={() => { 
              console.log(tableRef)
              onDownload() 
            }}
          >
            {t("Export")}
          </Button>
          :null}
          {/* <FlexBox>
          <PrintBtn />
          <Pagenation />
          </FlexBox> */}
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date")}
            value={
              filterOptions.fromDate.format("DD/MM/YYYY") +
              " - " +
              filterOptions.toDate.format("DD/MM/YYYY")
            }
          />
          <FilterLable name={t("VAT Period")} value={filterOptions.dateRange} />
          <FilterLable name={t("Language")} value={filterOptions.language} />
        </Box>
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{filterOptions.language === "English" ? t("Title") : "عنوان" }</VBTableCellHeader>
            <VBTableCellHeader>{filterOptions.language === "English" ? t(`Amount(${CurrencySymbol})	`): "أمونوت"}</VBTableCellHeader>
            <VBTableCellHeader>{filterOptions.language === "English" ? t(`Adjustment(${CurrencySymbol})`) : "التعديل "}</VBTableCellHeader>
            <VBTableCellHeader>{filterOptions.language === "English" ? t(`VAT Amount(${CurrencySymbol})`) : "مبلغ ضريبة القيمة المضافة" }</VBTableCellHeader>
          </VBTableHeader>
          <TableBody>
            <TableRow>
              <VBTableCellBody>{filterOptions.language === "English" ? t("Standard rated sales"): "مبيعات مصنفة قياسية"}</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_sales.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t(
                  "Private health care/Private education/First house sales to citizens"
                ) : "الرعاية الصحية الخاصة / التعليم الخاص / مبيعات المنازل الأولى للمواطنين" }
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.private_healthcare_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.private_healthcare_sales.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t("Zero rated domestic sales") : "نسبة المبيعات المحلية الصفرية"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.zero_rated_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.zero_rated_sales.vat_amount)}</VBTableCellBody>
            </TableRow>
            {/* <TableRow>
              <VBTableCellBody>{filterOptions.language === "English" ? t("Standard rated sales") : "مبيعات مصنفة قياسية"}</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_sales.vat_amount)}</VBTableCellBody>
            </TableRow> */}
            <TableRow>
              <VBTableCellBody>{filterOptions.language === "English" ? t("Exports") :  "صادرات" }</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.export_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.export_sales.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>{filterOptions.language === "English" ? t("Exempt sales"):"المبيعات المعفاة"}</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.exempt_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.exempt_sales.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody sx={{ color: "green" }}>
                {filterOptions.language === "English" ? t("Total Sales"):  "إجمالي المبيعات"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.total_sales.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.total_sales.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t("Standard rate domestice purchases"):  "مشتريات محلية بسعر قياسي"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_purchase.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_purchase.vat_amount)}</VBTableCellBody>
            </TableRow>
            {/* <TableRow>
              <VBTableCellBody>{t("Exports")}</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.ex.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.standard_sales.vat_amount)}</VBTableCellBody>
            </TableRow> */}
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t("Imports subject to VAT paid at customers") : "الواردات الخاضعة لضريبة القيمة المضافة المدفوعة للعملاء"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.import_subject_paid_purchase.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.import_subject_paid_purchase.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t(
                  "Import subject to VAT accounted for through reverse charge mechanism"
                ): "يتم احتساب الاستيراد الخاضع لضريبة القيمة المضافة من خلال آلية الاحتساب العكسي"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.import_subject_accounted_purchase.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.import_subject_accounted_purchase.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>{filterOptions.language === "English" ? t("Zero rated purchases"): "المشتريات الخاضعة لنسبة الصفر"}</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.zero_rated_purchase.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.zero_rated_purchase.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>{filterOptions.language === "English" ? t("Exempt purchases"): "المشتريات المعفاة"}</VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.exempt_purchase.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.exempt_purchase.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody sx={{ color: "red" }}>
                {filterOptions.language === "English" ? t("Total Purchases"): "إجمالي المشتريات"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.total_purchase.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.total_purchase.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t("Total VAT due for current period"): "إجمالي المشتريات"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.Total_VAT_due.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.Total_VAT_due.vat_amount)}</VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t("Corrections from previous period"): "تصحيحات من الفترة السابقة" }
              </VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody>
                {filterOptions.language === "English" ? t("VAT credit carried forward from previous period(s)"): "ائتمان ضريبة القيمة المضافة المُرحل من الفترة (الفترات) السابقة"}
              </VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
            </TableRow>
            <TableRow>
              <VBTableCellBody sx={{ color: "blue" }}>
                {filterOptions.language === "English" ? t("Net VAT due(or Claim)"): "صافي ضريبة القيمة المضافة المستحقة (أو المطالبة)"}
              </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.Total_VAT_due.Amount)}</VBTableCellBody>
              <VBTableCellBody>{Number(0).toFixed(appSetting.PriceDecimalPoint)} </VBTableCellBody>
              <VBTableCellBody>{formatNumber(filterData.Total_VAT_due.vat_amount)}</VBTableCellBody>
            </TableRow>
          </TableBody>
        </VBTableContainer>
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onFilter={() => searchReport()}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBSelect
                label={t("VAT Period")}
                value={filterOptions.dateRange}
                options={filterData.dateRangeList}
                handleChange={(e) => {
                  let date  = date_range(e.target.value)
                  setFilterOptions({
                    ...filterOptions,
                    dateRange: e.target.value,
                    fromDate: date.fromdate,
                    toDate: date.todate,
                  })
                }}
              />
            </ListItem>

            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(e) =>
                  setFilterOptions({ ...filterOptions, fromDate: e, dateRange: "Customize" })
                }
                toOnChange={(e) =>
                  setFilterOptions({ ...filterOptions, toDate: e, dateRange: "Customize" })
                }
                // fromReadOnly={true}
                // toReadOnly={true}
              />
            </ListItem>

            <Divider />
            <ListItem>
              <VBSelect
                label={t("Language")}
                value={filterOptions.language}
                options={filterData.languageList}
                handleChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    language: e.target.value,
                  })
                }
              />
            </ListItem>
          </List>
        }
      />





      {/* Print Report */}
      <Box sx={{ display: "none" }}>
        <PrintVatReturn state={{ language : filterOptions.language, ...filterData,CurrencySymbol:CurrencySymbol }} tableRef={tableRef} />
      </Box>
    </>
  );
};

export default VATReturn;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "25%",
}));
