import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Popover,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useRef, useState } from "react";
import VBInputField from "../../../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import VBSelect from "../../../../Components/Utils/VBSelect";
import { formatNumber } from "../../../../generalFunction";
import { useSelector } from "react-redux";
import { viknAlert } from "../../../../Function/comonFunction";

function ProductDetail({
  dataDetail,
  setDataDetail,
  dataList,
  is_edit,
  DefaultBomList,
  DefaultMultiUnit,
}) {
  const {
    // GST,
    // VAT,
    // PriceDecimalPoint,
    // PriceCategory,
    // EnableVoucherNoUserWise,
    // EnableTransilationInProduct,
    // MultiUnit,
    ShowWarrantyPeriodInProduct,
    EnableExciseTax,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  const [openBarModal, setOpenBarModal] = useState(null);
  const [isHover, setIsHover] = useState(false);

  const open = Boolean(openBarModal);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setOpenBarModal(event.currentTarget);
  };

  const handleImage = function (e) {
    let ing = e.target?.files;

    if (ing) {
      let newImages = [];

      // Get the current number of images already added
      const currentImageCount = dataDetail.ProductImages.length;

      // If adding the new images exceeds 5, limit them
      if (currentImageCount + ing.length > 5) {
        // alert("You can only upload a maximum of 5 images");
        // Limit the new images so the total doesn't exceed 5
        // AlertMsg();
        const allowedImages = 5 - currentImageCount;
        newImages = Array.from(ing).slice(0, allowedImages);
      } else {
        newImages = Array.from(ing);
      }

      setDataDetail({
        ...dataDetail,
        ProductImages: [...dataDetail.ProductImages, ...newImages],
      });
    }
  };

  const removeImg = function (i) {
    setDataDetail((prev) => ({
      ...prev,
      ProductImages: prev.ProductImages.filter((o, ind) => ind !== i),
    }));
  };

  const handleAddImage = function () {
    let btn = document.getElementById("uploadPhoto");
    btn.click();
  };

  const cnfFunction = function () {
    setDataDetail({
      ...dataDetail,
      is_BOM: !dataDetail.is_BOM,
      is_Purchase: dataDetail.is_BOM,
      multiUnit: [
        {
          UnitName: "",
          UnitID: 1,
          SalesPrice: dataDetail.multiUnit[0].SalesPrice,
          MinimumSalesPrice: 0,
          PurchasePrice: 0,
          MultiFactor: 1,
          ReverseFactor: "",
          Barcode: dataDetail.multiUnit[0].Barcode,
          detailID: 1,
          SalesPrice1: "0",
          SalesPrice2: "0",
          SalesPrice3: "0",
          MRP: 0,
          UnitInSales: true,
          UnitInPurchase: true,
          UnitInReports: true,
          DefaultUnit: true,
        },
      ],
      BomList: [
        {
          Barcode: "",
          UnitName: null,
          ProductName: null,
          Qty: 1,
          UnitPrice: 0,
          UnitList: [],
          NetAmount: 0,
          id: "",
          MultiFactor: 0,
          PriceListID: 0,
          BOMid: "",
          GstPer: 0,
          VatPer: 0,
        },
      ],
      BarcodeDetails: [],
    });
  };

  const handleBom = function () {
    viknAlert({
      title: "Are you sure",
      description: !dataDetail.is_BOM
        ? " Are you sure you want to remove all Multi Unit lists?"
        : "Are you sure you want to remove all BOM lists?",
      cnfFunction: cnfFunction,
    });
  };

  return (
    <>
      <Box sx={{ px: 4, pt: 4 }}>
        {/* <Typography
          sx={{ color: "#001746", fontSize: "13px", fontWeight: "500", mb: 2 }}
        >
          Product Images
        </Typography> */}

        <Box sx={{ flexGrow: 1, width: "auto", mb: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} alignContent={"center"}>
              <Grid
                container
                rowSpacing={2}
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <VBInputField
                    name="Product Code"
                    label="Product Code"
                    type="text"
                    value={dataDetail.ProductCode}
                    disabled={!dataDetail.ManualProductCode}
                    sx={{ color: "black !important" }}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        ProductCode: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VBInputField
                    label="Barcode"
                    type="text"
                    value={dataDetail.multiUnit[0].Barcode}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        multiUnit: [
                          {
                            ...dataDetail.multiUnit[0],
                            Barcode: e.target.value,
                          },
                          ...dataDetail.multiUnit.slice(1),
                        ],
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} alignContent={"center"}>
              <Grid
                container
                rowSpacing={2}
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <VBInputField
                    name="Product Name"
                    label="Product Name"
                    type="text"
                    value={dataDetail.ProductName}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        ProductName: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <VBInputField
                    name="Display Name"
                    label="Display Name"
                    type="text"
                    value={dataDetail.DisplayName || dataDetail.ProductName}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        DisplayName: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
              <Paper
                elevation={0}
                sx={{
                  border: 2,
                  borderColor: "#D1D1D1",
                  borderStyle: "dashed",
                  borderRadius: "6px",
                  backgroundColor: "#FAFAFA",
                  p: 2,
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <input
                  type="file"
                  id="uploadPhoto"
                  style={{ display: "none" }}
                  accept="image/*"
                  multiple={true}
                  onChange={handleImage}
                />
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#F5F5F5",
                    border: 1,
                    borderColor: "#D1D1D1",
                    boxShadow: "none",
                    height: "148px",
                    minWidth: "140px",
                    color: "#525252",
                    fontSize: "10px",
                    ":hover": {
                      backgroundColor: "#F5F5F5",
                    },
                  }}
                  onClick={handleAddImage}
                >
                  {/* Click to upload */}
                  <img src="../images/addImage.png" alt="btn" />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    overflowX: "scroll",
                    "-ms-overflow-style": "none",
                    scrollbarWidth: "none",
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                    width: "100%",
                  }}
                  // component={TransitionGroup}
                >
                  {dataDetail.ProductImages.map((e, i) => (
                    // <Collapse key={i} orientation="vertical" in={Boolean(i)}>
                    <Badge
                      onMouseEnter={() => setIsHover(i)}
                      onMouseLeave={() => setIsHover(null)}
                      sx={{
                        "&:hover": {
                          "& .MuiBadge-badge": {
                            display: "block",
                          },
                        },

                        "& .MuiBadge-badge": {
                          display: "none",
                          top: "45%",
                          left: "50%",
                          transform: "translate(-35%, -35%)",
                          zIndex: 0,
                        },
                      }}
                      badgeContent={
                        <IconButton
                          sx={{ color: "#EEEEEE" }}
                          onClick={() => removeImg(i)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={URL.createObjectURL(e)}
                        sx={{
                          filter: isHover === i && "blur(1.5px)",
                          height: "148px",
                          width: "140px",
                          borderRadius: "3px",
                          // zIndex: 100
                        }}
                      />
                    </Badge>
                    // </Collapse>
                  ))}
                </Box>
              </Paper>
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                  label="This Is Service"
                  control={
                    <Checkbox
                      name="is_Service"
                      checked={dataDetail.is_Service}
                      onChange={(e) => {
                        setDataDetail({
                          ...dataDetail,
                          is_Service: !dataDetail.is_Service,
                        });
                      }}
                      size="small"
                      //  disabled={radio.disabled}
                    />
                  }
                />

                <FormControlLabel
                  sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                  label="Manual Product Code"
                  control={
                    <Checkbox
                      checked={dataDetail.ManualProductCode}
                      onChange={(e) => {
                        setDataDetail({
                          ...dataDetail,
                          ManualProductCode: !dataDetail.ManualProductCode,
                        });
                      }}
                      size="small"
                      //  disabled={radio.disabled}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, width: "100%", mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ViknAutoComplete
                disablePortal
                label="Unit"
                id="custom-input-demo"
                disabled={dataDetail.multiUnit[0].is_used_unit}
                value={dataDetail.unit}
                options={dataList.unitList}
                getOptionLabel={(option) => option.UnitName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    unit: v,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        UnitName: v ? v.UnitName : "",
                        UnitID: v ? v.UnitID : "",
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {/* AutoComplete ⭐⭐⭐ */}
              <ViknAutoComplete
                disablePortal
                name="Brand"
                label="Brand"
                id="custom-input-demo"
                value={dataDetail.brand}
                options={dataList.brandList}
                getOptionLabel={(option) => option.BrandName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    brand: v,
                    BrandID: v?.BrandID || 0,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField type="text" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ViknAutoComplete
                disablePortal
                name="Product Group"
                label="Product Group"
                id="custom-input-demo"
                value={dataDetail.productGroup}
                options={dataList.priductGroupList}
                getOptionLabel={(option) => option.GroupName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    productGroup: v,
                    ProductGroupID: v ? v.ProductGroupID : 0,
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>

            {ShowWarrantyPeriodInProduct && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {/* <VBInputField
                label="Warranty"
                type="text"
                value={dataDetail.WarrantyType}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    WarrantyType: e.target.value,
                  });
                }}
              /> */}

                <VBSelect
                  name="Warranty"
                  label="Warranty"
                  value={dataDetail.WarrantyType}
                  options={["Year", "Months"]}
                  handleChange={(e) =>
                    setDataDetail({
                      ...dataDetail,
                      WarrantyType: e.target.value,
                    })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={8} lg={4} alignContent={"center"}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel
                  id="discriptio-controlled"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    mb: 1,
                  }}
                >
                  Description
                </FormLabel>

                <InputBase
                  name="Discription"
                  label="minimum height"
                  placeholder="Type Here..."
                  multiline
                  minRows={4}
                  inputProps={{
                    style: { resize: "none" }, // Disable resizing if needed
                  }}
                  sx={{
                    width: "auto",
                    borderRadius: "4px",
                    backgroundColor: "#F3F6F9",
                    border: "1px solid",
                    borderColor: "#E0E3E7",
                    padding: "6px",
                    fontSize: "14px",
                    py: 1
                  }}
                  value={dataDetail.Description}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      Description: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={4}>
              <VBInputField
                name="Cost With Expense"
                label="Cost With Expense"
                type="text"
                value={dataDetail.CostWithExpense}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    CostWithExpense: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <VBInputField
                name="Average Cost"
                label="Average Cost"
                type="number"
                value={dataDetail.AverageCost}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    AverageCost: e.target.value,
                  });
                }}
              />
            </Grid> */}

            {/* <Grid item xs={4}>
              <VBInputField
                name="Description"
                label="Description"
                type="text"
                row={4}
              />
            </Grid> */}
            {/* <Grid item xs={8} container alignItems="center">
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="This Is Service"
                control={
                  <Checkbox
                    name="is_Service"
                    checked={dataDetail.is_Service}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        is_Service: !dataDetail.is_Service,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />

              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Manual Product Code"
                control={
                  <Checkbox
                    checked={dataDetail.ManualProductCode}
                    onChange={(e) => {
                      setDataDetail({
                        ...dataDetail,
                        ManualProductCode: !dataDetail.ManualProductCode,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid> */}
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, py: 2 }}>
          <Grid container rowSpacing={2} wrap="wrap" sx={{ "& .MuiGrid-item": { width: "200px"} }} >
            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Raw Material"
                control={
                  <Checkbox
                    checked={dataDetail.is_RawMaterial}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_RawMaterial: !dataDetail.is_RawMaterial,
                      });
                    }}
                    size="small"
                    disabled={dataDetail.is_BOM}
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Finished Product"
                control={
                  <Checkbox
                    checked={dataDetail.is_FinishedProduct}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_FinishedProduct: !dataDetail.is_FinishedProduct,
                      });
                    }}
                    size="small"
                    disabled={dataDetail.is_BOM}
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Sales"
                control={
                  <Checkbox
                    checked={dataDetail.is_Sales}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_Sales: !dataDetail.is_Sales,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Purchase"
                control={
                  <Checkbox
                    checked={dataDetail.is_Purchase}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_Purchase: !dataDetail.is_Purchase,
                      });
                    }}
                    size="small"
                    disabled={dataDetail.is_BOM}
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Favourite"
                control={
                  <Checkbox
                    checked={dataDetail.is_Favourite}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_Favourite: !dataDetail.is_Favourite,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Active"
                control={
                  <Checkbox
                    checked={dataDetail.is_Active}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_Active: !dataDetail.is_Active,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Inclusive"
                control={
                  <Checkbox
                    checked={dataDetail.is_Inclusive}
                    onChange={() => {
                      setDataDetail({
                        ...dataDetail,
                        is_Inclusive: !dataDetail.is_Inclusive,
                      });
                    }}
                    size="small"
                    //  disabled={radio.disabled}
                  />
                }
              />
            </Grid>

            <Grid item >
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="BOM"
                control={
                  <Checkbox
                    checked={dataDetail.is_BOM}
                    onChange={() => {
                      // setisAlert(true);
                      handleBom();
                    }}
                    size="small"
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Stack direction="column" spacing={2} sx={{ mb: 2, alignItems: "start" }}>
          <Button
            variant="text"
            color="primary"
            aria-describedby={id}
            onClick={handleClick}
          >
            + Multi Barcode
          </Button>
          {/* <Box sx={{ }}> */}
          <Stack
            direction="row"
            rowGap={1}
            columnGap={2}
            sx={{
              mb: 2,
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            {dataDetail.BarcodeDetails.map((obj, ind) => (
              <Card
                variant="outlined"
                sx={{
                  px: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  backgroundColor: "#F9F9F9",
                  borderRadius: "6px",
                }}
              >
                <Typography color="primary" sx={{ fontSize: "14px" }}>
                  Barcode: <span style={{ color: "black" }}>{obj.barcode}</span>
                </Typography>
                <Divider orientation="horizontal" variant="fullWidth" />
                <Typography color="primary" sx={{ fontSize: "14px" }}>
                  Unit: <span style={{ color: "black" }}>{obj.UnitName}</span>
                </Typography>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setDataDetail({
                      ...dataDetail,
                      BarcodeDetails: dataDetail.BarcodeDetails.filter(
                        (e, i) => i !== ind
                      ),
                    });
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Card>
            ))}
          </Stack>
          {/* </Box> */}
        </Stack>
      </Box>

      <Divider variant="fullWidth" sx={{ mb: 5 }} />

      <Box sx={{ px: 4 }}>
        <Typography
          sx={{ color: "#001746", fontSize: "13px", fontWeight: "500", mb: 5 }}
        >
          Pricing and Inventory Information
        </Typography>
        <Box sx={{ flexGrow: 1, width: "auto", mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <VBInputField
                label="Purchase Price"
                type="number"
                value={dataDetail.multiUnit[0].PurchasePrice}
                disabled={dataDetail.is_BOM}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    DefaultSalesPrice: e.target.value,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        PurchasePrice: e.target.value,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <VBInputField
                label="Sales Price"
                type="number"
                value={dataDetail.multiUnit[0].SalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        SalesPrice: e.target.value,
                        MRP: dataDetail.taxType
                          ? (dataDetail.taxType.SalesTax / 100) *
                              e.target.value +
                            Number(e.target.value)
                          : dataDetail.multiUnit[0].MRP,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <VBInputField
                label="Minimum Price"
                type="number"
                value={dataDetail.MinimumSalesPrice}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    MinimumSalesPrice: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <VBInputField
                label="MRP"
                type="number"
                value={dataDetail.multiUnit[0].MRP}
                onChange={(e) => {
                  setDataDetail({
                    ...dataDetail,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        MRP: e.target.value,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {/* AutoComplete $$$$ */}

              <ViknAutoComplete
                disablePortal
                label="VAT"
                id="custom-input-vat"
                value={dataDetail.taxType}
                options={dataList.taxList}
                getOptionLabel={(option) => option.TaxName}
                onChange={(e, v) => {
                  setDataDetail({
                    ...dataDetail,
                    taxType: v,
                    multiUnit: [
                      {
                        ...dataDetail.multiUnit[0],
                        MRP: v?.SalesTax
                          ? (v.SalesTax / 100) *
                              dataDetail.multiUnit[0].SalesPrice +
                            Number(dataDetail.multiUnit[0].SalesPrice)
                          : dataDetail.multiUnit[0].SalesPrice,
                      },
                      ...dataDetail.multiUnit.slice(1),
                    ],
                  });
                }}
                renderInput={(params) => (
                  <VBInputField label="Product Group" type="text" {...params} />
                )}
              />
            </Grid>
            {EnableExciseTax && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ViknAutoComplete
                  disablePortal
                  label="Excise Tax"
                  id="exise-input-demo"
                  value={dataDetail.exciseTaxType}
                  options={dataList.exiseTaxList}
                  getOptionLabel={(option) => option.TaxName}
                  onChange={(e, v) => {
                    setDataDetail({
                      ...dataDetail,
                      exciseTaxType: v,
                    });
                  }}
                  renderInput={(params) => (
                    <VBInputField
                      label="Product Group"
                      type="text"
                      {...params}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>

      <Collapse in={!dataDetail.is_Service}>
        <Divider variant="fullWidth" sx={{ mb: 5 }} />
        <Box sx={{ px: 4 }}>
          <Typography
            sx={{
              color: "#001746",
              fontSize: "16px",
              fontWeight: "500",
              mb: 2,
            }}
          >
            Stock Details
          </Typography>
          <Box sx={{ flexGrow: 1, width: "auto", mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <VBInputField
                  label="Stock Minimum"
                  type="number"
                  value={dataDetail.StockMinimum}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      StockMinimum: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <VBInputField
                  label="Stock Maximum"
                  type="number"
                  value={dataDetail.StockMaximum}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      StockMaximum: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <VBInputField
                  label="Stock Order"
                  type="number"
                  value={dataDetail.StockReOrder}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      StockReOrder: e.target.value,
                    });
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <ViknAutoComplete
                  disablePortal
                  label="Inventory Type"
                  id="custom-input-demo"
                  value={dataDetail.InventoryType}
                  options={dataList.InventoryTypes}
                  onChange={(e, v) => {
                    setDataDetail({
                      ...dataDetail,
                      InventoryType: v,
                    });
                  }}
                  renderInput={(params) => (
                    <VBInputField type="text" {...params} />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12} sm={6} md={4} lg={3}
                sx={{ display: "flex", alignItems: "center", mt: 3}}
                // alignItems={"center"}
                // alignContent={"centers"}
                // orientation=""
                // component={Collapse}
                // in={!dataDetail.is_Service}
                // mountOnEnter
                // unmountOnExit
              >
                <FormControlLabel
                  sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                  label="Weighing Scale"
                  control={
                    <Checkbox
                      checked={dataDetail.is_Weighing}
                      onChange={() => {
                        setDataDetail({
                          ...dataDetail,
                          is_Weighing: !dataDetail.is_Weighing,
                        });
                      }}
                      size="small"
                      //  disabled={radio.disabled}
                    />
                  }
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}></Grid> */}

              <Grid
                item
                xs={12} sm={6} md={4} lg={3} xl={2}
                component={Collapse}
                in={dataDetail.is_Weighing}
              >
                <VBInputField
                  label="PLU No"
                  type="text"
                  value={dataDetail.PLUNo}
                  onChange={(e) => {
                    setDataDetail({
                      ...dataDetail,
                      PLUNo: e.target.value,
                    });
                  }}
                />
              </Grid>
              
              <Grid
                item
                xs={12} sm={6} md={4} lg={3}
                component={Collapse}
                // in={true}
                in={dataDetail.is_Weighing}
              >
                <ViknAutoComplete
                  disablePortal
                  label="Calculate In"
                  id="custom-input-demo"
                  value={dataDetail.WeighingCalcType}
                  options={dataList.WeighingCalcTypes}
                  onChange={(e, v) => {
                    setDataDetail({
                      ...dataDetail,
                      WeighingCalcType: v,
                    });
                  }}
                  renderInput={(params) => (
                    <VBInputField type="text" {...params} />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12} sm={6} md={4} lg={3}
                component={Collapse}
                in={dataDetail.is_Weighing}
              >
                <VBInputField
                  label="Weighting Format"
                  type="text"
                  // value={dataDetail.PLUNo}
                  // onChange={(e) => {
                  //   setDataDetail({
                  //     ...dataDetail,
                  //     PLUNo: e.target.value,
                  //   });
                  // }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Collapse>

      <AddBarcode
        id={id}
        open={open}
        anchorEl={openBarModal}
        dataDetail={dataDetail}
        setDataDetail={setDataDetail}
        dataList={dataList}
        handleClose={() => {
          setOpenBarModal(null);
        }}
      />
    </>
  );
}

export default ProductDetail;

// ||-----------------------------------------------------------------||
// ===================== +++ Add Barcode Modal +++ =====================
// ||-----------------------------------------------------------------||

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  // p: 4,
  boxShadow: 2,
  borderRadius: "15px",
};

const AddBarcode = function ({
  open,
  handleClose,
  dataDetail,
  setDataDetail,
  dataList,
  id,
  anchorEl,
}) {
  const inputRef = useRef(null);
  const [isFilled, setIsFilled] = useState(false);
  const [barData, setBarData] = useState({
    barcode: "",
    unit: null,
  });

  const handleCloseIcon = function () {
    handleClose();
  };

  const handleSave = function (e) {
    e.preventDefault()
    if (barData.barcode === "" && barData.unit === null) {
      setIsFilled(true);
    } else {
      setDataDetail({
        ...dataDetail,
        BarcodeDetails: [
          ...dataDetail.BarcodeDetails,
          {
            barcode: barData.barcode,
            unit: barData.unit.UnitID,
            UnitName: barData.unit.UnitName,
            detailID: 1,
          },
        ],
      });

      setBarData({
        barcode: "",
        unit: null,
      });
      // handleClose();
      setIsFilled(false);
    }
  };

  const focusInput = function() {
    const timer = setTimeout(() => {
      inputRef.current?.focus();
    }, 0);

    return () => clearTimeout(timer);
  }

  useEffect(() => {
    focusInput();
  }, [open])
  
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {/* <Box sx={style}> 
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            py: 1,
          }}
        >
          <Typography>Add Multibar</Typography>

          <IconButton color="primary" onClick={handleCloseIcon}>
            <CloseIcon />
          </IconButton>
        </Stack> 
        <Divider />*/}
      {isFilled && (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 500,
            color: "error.main",
            textAlign: "center",
            px: 2,
            pt: 1,
          }}
        >
          Please Fill all field's
        </Typography>
      )}
      <form onSubmit={handleSave}>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          p: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <input  /> */}
        <VBInputField
          ref={inputRef}
          label="Barcode"
          type="text"
          required
          value={barData.barcode}
          onChange={(e) => {
            setBarData({
              ...barData,
              barcode: e.target.value,
            });
          }}
        />

        {/* <VBInputField
            label="Unit"
            type="number"
            value={barData.unit}
            onChange={(e) => {
              setBarData({
                ...barData,
                unit: e.target.value,
              });
            }}
          /> */}

        <ViknAutoComplete
          disablePortal
          label="Unit"
          id="custom-input-demo"
          value={barData.unit}
          options={dataDetail.multiUnit}
          getOptionLabel={(option) => option.UnitName}
          onChange={(e, v) => {
            setBarData({
              ...barData,
              unit: v,
            });
          }}
          renderInput={(params) => (
            <VBInputField label="Product Group" type="text" {...params} />
          )}
        />

        <Button
          color="primary"
          sx={{ color: "white", width: "100%", boxShadow: "none" }}
          variant="contained"
          // onClick={handleSave}
          type="submit"
        >
          Save
        </Button>
      </Stack>
      </form>
      {/* </Box> */}
    </Popover>
  );
};
