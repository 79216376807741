import Brand from "../../Pages/Product/Brand/Brand";
import ProductCatogeryList from "../../Pages/Product/ProductCatogery/ProductCatogeryList";
import ProductGroup from "../../Pages/Product/ProductGroup/ProductGroup";
import CreateProduct from "../../Pages/Product/CreateProduct/CreateProduct";
import ProductList from "../../Pages/Product/ProductList/ProductList";
import ProductLayout from "../../Pages/Product/ProductLayout";

const ProductRoutes = [
  {
    path: "product-category",
    element: <ProductCatogeryList />,
    isAuth: true,
  },
  {
    path: "product-brand",
    element: <Brand />,
    isAuth: true,
  },
  {
    path: "product-group",
    element: <ProductGroup />,
    isAuth: true,
  },
  {
    path: "create-products",
    element: <CreateProduct />,
    isAuth: true,
  },
  {
    path: "products",
    element: <ProductList />,
    isAuth: true,
  },
];

// const ProductRoutes = {
//   path: "products",
//   element: <ProductLayout />,
//   children: [
//     { path: "", element: <ProductList />, index: true },
//     {
//       path: "product-category",
//       element: <ProductCatogeryList />,
//       isAuth: true,
//     },
//     {
//       path: "product-brand",
//       element: <Brand />,
//       isAuth: true,
//     },
//     {
//       path: "product-group",
//       element: <ProductGroup />,
//       isAuth: true,
//     },
//     {
//       path: "create-products",
//       element: <CreateProduct />,
//       isAuth: true,
//     },
//   ],
// };

export default ProductRoutes;
