import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import TableSwitch from "../../../Components/Utils/TableSwitch";
import ViknAutoComplete from "../../../Components/Utils/ViknAutoComplete";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBTextField from "../../../Components/Utils/VBTextField";
import { callGeneralSettingsSingle } from "../../../Api/generalSettings/generalSettings";
import { useDispatch, useSelector } from "react-redux";
import { Essential, Lite, Standard } from "../../../Edition/edition";
import VBSelect, { StyledSelect } from "../../../Components/Utils/VBSelect";
import { generalSettingsUpdate } from "../../../features/generalSettingsSclice";
import { viknSnackOpen } from "../../../Function/comonFunction";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index &&  */}
      <Box >{children}</Box>
      {/* } */}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Inventory() {
  const dispatch = useDispatch();

  const { CompanyID, BranchID, Edition } = useSelector(
    (state) => state.companyDetails
  );
  const glss = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  // const roll = useSelector((state) => state.userRollSettings);
  // const userRoll = useSelector((state) => state.rollPermessions);

  const [tabValue, setTabValue] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const [generalList, setGeneralList] = useState([
    { keyName: "MultiUnit", name: "Multi Unit", checked: false, premession: false },
    { keyName: "PriceCategory", name: "Price Category", checked: false, premession: false },
    { keyName: "InclusiveRateWorkOrder", name: "Show Inclusive Rate in Work Order", checked: false, premession: false },
    { keyName: "VoucherNoAutoGenerate", name: "Voucher No Auto Generate", checked: false, premession: false },
    { keyName: "EnableProductBatchWise", name: "Enable Product Batch Wise", checked: false, premession: false },
    { keyName: "CreateBatchForWorkOrder", name: "Create Batch For each Work Order", checked: false, premession: false },
    { keyName: "EnableDuplicateProductName", name: "Enable Duplicate Product Name", checked: false, premession: false },
    { keyName: "EnableShippingCharge", name: "Enable Shipping Charge", checked: false, premession: false },
    { keyName: "AutoIncrimentQty", name: "Auto Incriment Qty For Barcode", checked: false, premession: false },
    { keyName: "EnableWarehouse", name: "Enable Warehouse", checked: false, premession: false },
    { keyName: "EnableCreditPeriod", name: "Enable Credit Period", checked: false, premession: false },
    { keyName: "EnableCreditLimit", name: "Enable Credit Limit", checked: false, premession: false },
    { keyName: "ShowAllProductsInWorkOrder", name: "Show all Products in Work Order", checked: false, premession: false },
    { keyName: "EnableTransilationInProduct", name: "Enable Translation (Arabic) in Product", checked: false, premession: false },
    { keyName: "ShowWarrantyPeriodInProduct", name: "Show Warranty Period in Product", checked: false, premession: false },
    { keyName: "Negative_Stock_Show", name: "Show Negative Stock Product", checked: false, premession: false },
    { keyName: "AllowNegativeStockInStockTransfer", name: "Allow Negative Stock in Stock Transfer", checked: false, premession: false },
    { keyName: "ShowYearMonthCalanderInWorkOrder", name: "Show Year & Month Calandar for Expiry Date and Manufacture Date", checked: false, premession: false },
    { keyName: "EnableSerialNoInSales", name: "Enable Serial No", checked: false, premession: false },
    { keyName: "EnableItemCodeNoInSales", name: "Enable Item Code", checked: false, premession: false },
    { keyName: "EnableFaeraSettings", name: "Enable Faera Settings", checked: false, premession: false },
    { keyName: "ShowCustomerCode", name: "Show Customer Code", checked: false, premession: false },
    { keyName: "ShowAverageCostInSales", name: "Show Average Cost In Sales", checked: false, premession: false },
    { keyName: "ShowSalesManInReceipt", name: "Show SalesMan In Receipt", checked: false, premession: false },
    { keyName: "is_Loyalty_SalesReturn_MinimumSalePrice", name: "Loyalty SalesReturn MinimumSalePrice", checked: false, premession: false },
  ])

  const [salesList, setSalesList] = useState([
    { keyName: "Free_Quantity_In_Sales", name: "Free Quantity In Sales", checked: false, premession: false },
    { keyName: "AllowNegativeStockSales", name: "Allow Negative Stock", checked: false, premession: false },
    { keyName: "AllowUpdateBatchPriceInSales", name: "Allow Update Batch Sales Price In Sales Invoice", checked: false, premession: false },
    { keyName: "SalesPriceUpdate", name: "Sales Price Update", checked: false, premession: false },
    { keyName: "SalesPriceUpdateMultiUnit", name: "Sales Price Update All MultiUnit", checked: false, premession: false },
    { keyName: "AllowCashReceiptMoreSaleAmt", name: "Allow Receipt more than Sales Amount", checked: false, premession: false },
    { keyName: "AllowAdvanceReceiptinSales", name: "Allow Advance Receipt in Sales", checked: false, premession: false },
    { keyName: "AllowQtyDividerinSales", name: "Allow Qty Divider in Sales", checked: false, premession: false },
    { keyName: "BlockSalesPrice", name: "Block Sales Price Above MRP", checked: false, premession: false },
    { keyName: "ShowProfitinSalesRegisterReport", name: "Show Profit in Sales Register Report", checked: false, premession: false },
    { keyName: "ShowCustomerLastSalesPriceinSales", name: "Show Customer Last Sales Price In Sales", checked: false, premession: false },
    { keyName: "ShowNegativeBatchInSales", name: "Show Negative Batch", checked: false, premession: false },
    { keyName: "blockSaleByBillDisct", name: "Block Sales Less than Minimum Sales Price on Total Invoice", checked: false, premession: false },
    { keyName: "ShowSupplierInSales", name: "Show Suppliers In Sales", checked: false, premession: false },
    { keyName: "ShowEmployeesInSales", name: "Show Employees In Sales", checked: false, premession: false },
    { keyName: "EnableSalesManInSales", name: "Enable SalesMan", checked: false, premession: false },
    { keyName: "BlockSalesBelowPurchase", name: "Block Sales Price Below Purchase Price", checked: false, premession: false },
    { keyName: "EnableDeliveryDateInSales", name: "Enable Delivery Date", checked: false, premession: false },
    { keyName: "PurchasePriceInSales", name: "Purchase Price In Sales", checked: false, premession: false },
    { keyName: "ShowCostWithExpenseAsPurchasePrice", name: "Show Cost With Expense As Purchase Price", checked: false, premession: false },
    { keyName: "QuantityincrementAlert", name: "Quantity increment Alert", checked: false, premession: false },
    { keyName: "AllowNegativeStockInSalesOrder", name: "Allow Negative Stock In Sales Order", checked: false, premession: false },
    { keyName: "AllowNegativeStockInSalesEstimate", name: "Allow Negative Stock In Sales Estimate", checked: false, premession: false },
    { keyName: "AvoidLedgerwiseFilterInSalesHistory", name: "Avoid Ledger wise filter in Sales History", checked: false, premession: false },

    { keyName: "show_productcode_InsearchSale", name: "Product Code", checked: false, premession: false },
    { keyName: "show_stock_InsearchSale", name: "Stock", checked: false, premession: false },
    { keyName: "show_purchasePrice_InsearchSale", name: "Purchase Price", checked: false, premession: false },
    { keyName: "show_brand_InsearchSale", name: "Brand", checked: false, premession: false },
  ])

  const [purchaseList, setPurchaseList] = useState([
    { keyName: "Free_Quantity_In_Purchase", name: "Free Quantity In Purchase", checked: false, premession: false },
    { keyName: "PurchasePriceUpdate", name: "Purchase Price Update", checked: false, premession: false },
    { keyName: "PurchasePriceUpdateMultiUnit", name: "Purchase Price Update All MultiUnit", checked: false, premession: false },
    { keyName: "ShowManDateAndExpDatePurchase", name: "Show Manufacture Date and Expiry Date in Purchase", checked: false, premession: false },
    { keyName: "AllowCashReceiptMorePurchaseAmt", name: "Allow Receipt more than Purchase Amount", checked: false, premession: false },
    { keyName: "AllowAdvanceReceiptinPurchase", name: "Allow Advance Receipt in Purchase", checked: false, premession: false },
    { keyName: "ReferenceBillNo", name: "Reference Bill Number in Purchase", checked: false, premession: false },
    { keyName: "ShowSalesPriceInPurchase", name: "Show Sales Price in Purchase", checked: false, premession: false },
    { keyName: "ShowMaximumStockAlert", name: "Show Maximum Stock Alert", checked: false, premession: false },
    { keyName: "ShowCustomerInPurchase", name: "Show Customer in Purchase", checked: false, premession: false },
    { keyName: "ShowSupplierLastPurchasePriceinPurchase", name: "Show Supplier Last Purchase Price in Purchase", checked: false, premession: false },
    { keyName: "EnableAdditionalExpense", name: "Enable Additional Expense", checked: false, premession: false },
    { keyName: "ShowLedgerWisePurchaseHistory", name: "Show Ledger Wise Purchase History", checked: false, premession: false },
    { keyName: "UnitPriceUpdatebyItemWiseDiscount", name: "Unit price Update by Item-wise Discount Amount", checked: false, premession: false },

    { keyName: "show_productcode_InsearchPurchase", name: "Product Code", checked: false, premession: false },
    { keyName: "show_stock_InsearchPurchase", name: "Stock", checked: false, premession: false },
    { keyName: "show_salesPrice_InsearchPurchase", name: "Sales Price", checked: false, premession: false },
  ])

  const [inptDetails, setInptDetails] = useState({
    BatchCriteria: "",
    validationSerialNumber: "",
    loyalty_PointValue: "",
    RoundOffSales: "",
    SalesPriceLessThanMinimumSalesPrice: "",
    RoundOffPurchase: ""
  })

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const permissFunc = function (key) {
    let permission = true;
    let edit = "";
    console.log({
      Lite: Lite[key],
      Essential: Essential[key],
      Standard: Standard[key],
      Edition: Edition,
    });

    switch (Edition) {
      case "Professional":
      case "Advance":
        // return true;
        edit = "Advance";
        permission = true;
        break;
      case "Lite":
        edit = "Lite";
        permission = Lite[key] === undefined; // If key not present in the Lite object then it become true
        break;
      case "Essential":
        edit = "Essential";
        permission = Essential[key] === undefined; // If key not present in the Essential object then it become true
        break;
      case "Standard":
        edit = "Standard";
        permission = Standard[key] === undefined; // If key not present in the Standard object then it become true
        break;
      default:
        edit = "default";
        permission = true;
        break;
    }
    console.log(key, permission, edit);

    return permission;
  };

  const changeSetting = async function (keyName, bool, ind) {
    
    let generalList = () => setGeneralList((prev) => prev.map((e, i) => (ind === i ? { ...e, checked: !e.checked } : e)));
    let salesList = () => setSalesList((prev) => prev.map((e, i) => (ind === i ? { ...e, checked: !e.checked } : e)));
    let purchaseList = () => setPurchaseList((prev) => prev.map((e, i) => (ind === i ? { ...e, checked: !e.checked } : e)));

    tabValue === 0 && generalList(); //  Update General State
    tabValue === 1 && salesList(); //  Update Sales State
    tabValue === 2 && purchaseList(); // Update Purchase State 

    await callGeneralSettingsSingle({
      CompanyID,
      BranchID,
      type_data: "edit",
      SettingsType: keyName || "",
      SettingsValue: bool,
    }).then((res) => {
      if (res.StatusCode === 6000 && res.message === "Success") {
        
        tabValue === 0 && updateReduxSetting(keyName, !generalList[ind].checked);
        tabValue === 1 && updateReduxSetting(keyName, !salesList[ind].checked);
        tabValue === 2 && updateReduxSetting(keyName, !purchaseList[ind].checked);
        viknSnackOpen({
          open: true,
          message: "Setting Change successfully",
          severity: "success",
        });
      } else {
        tabValue === 0 && generalList(); //  Update General State
        tabValue === 1 && salesList(); //  Update Sales State
        tabValue === 2 && purchaseList(); // Update Purchase State 
        viknSnackOpen({
          open: true,
          message: "You can't change this now",
          severity: "warning",
        });
      }
    });
  };

  const changeInput = useCallback(async (e) => {
    // Update the input state immediately
    setInptDetails(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set new timeout and store its ID
    const newTimeoutId = setTimeout(async () => {
      try {
        await callGeneralSettingsSingle({
          CompanyID,
          BranchID,
          type_data: "edit",
          SettingsType: e.target.name || "",
          SettingsValue: e.target.value,
        }).then((res) => {
          if (res.StatusCode === 6000 && res.message === "Success") {
            updateReduxSetting(e.target.name, e.target.value);
            viknSnackOpen({
              open: true,
              message: "Setting Change successfully",
              severity: "success",
            });
          } else {
            viknSnackOpen({
              open: true,
              message: "You can't change this now",
              severity: "warning",
            });
          }
        });
      } catch (error) {
        console.error('API call failed:', error);
        // Handle error appropriately
      }
    }, 3000);

    setTimeoutId(newTimeoutId);
  }, [timeoutId]); 

  const updateReduxSetting = function (key, value) {
    dispatch( generalSettingsUpdate({ key: key, value: value }) )
  }

  useEffect(() => {
    setGeneralList((prev) =>
      prev.map((e) => ({
        ...e,
        checked: glss[e.keyName],
        premession: permissFunc(e.keyName),
      }))
    );

    setSalesList((prev) =>
      prev.map((e) => ({
        ...e,
        checked: glss[e.keyName],
        premession: permissFunc(e.keyName),
      }))
    );

    setPurchaseList((prev) =>
      prev.map((e) => ({
        ...e,
        checked: glss[e.keyName],
        premession: permissFunc(e.keyName),
      }))
    );

    setInptDetails({
      ...inptDetails,
      BatchCriteria: glss.BatchCriteria,
      validationSerialNumber: glss.validationSerialNumber,
      loyalty_PointValue: glss.loyalty_PointValue,
      RoundOffSales: glss.RoundOffSales,
      SalesPriceLessThanMinimumSalesPrice: glss.SalesPriceLessThanMinimumSalesPrice, 
      RoundOffPurchase: glss.RoundOffPurchase,
    })

    console.log(glss.ShowLedgerWisePurchaseHistory);
    
    
  }, [glss]);

    // Clean up timeout on component unmount
    useEffect(() => {
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }, [timeoutId]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: "none", width: "150px" }}
            label="General"
            {...a11yProps(0)}
          />
          <Tab sx={{ textTransform: "none", width: "150px" }} label="Sales" {...a11yProps(1)} />
          <Tab
            sx={{ textTransform: "none", width: "150px" }}
            label="Purchase"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <Box sx={{ borderBottom: 1, flexGrow: 1, p: 3, borderColor: "divider" }}>
          <Grid container spacing={1}>
              {generalList.slice(0, -1).map((obj, ind) => (
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: "12px",
                      height:"100%",
                      bgcolor: "#f8f8f8",
                      border: "1px solid #e2e2e2",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>{obj.name}</Typography>
                    <TableSwitch 
                      checked={obj.checked}
                      onChange={(e) => {
                        changeSetting(obj.keyName, e.target.checked, ind);
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
          </Grid>
        </Box>  

        <Box sx={{ borderBottom: 1, flexGrow: 1, p: 3, borderColor: "divider" }}>
          <Grid container spacing={1}>
            <Grid item xs={4} >
              <VBSelect
                label="Batch Criteria"
                //  defaultValue={inptDetails.BatchCriteria.replace(/([A-Z])/g, ' $1').trim()}
                //  value={inptDetails.BatchCriteria.replace(/([A-Z])/g, ' $1').trim()}
                //  options={["PurchasePrice".replace(/([A-Z])/g, ' $1').trim(), "PurchasePriceAndExpiryDate".replace(/([A-Z])/g, ' $1').trim()]}
                value={inptDetails.BatchCriteria}
                options={["PurchasePrice", "PurchasePriceAndExpiryDate"]}
                handleChange={changeInput}
              />
            </Grid>
            <Grid item xs={4} >
              <VBSelect
                 label="Validation for Serial Number"
                 value={inptDetails.validationSerialNumber}
                 options={["Ignore", "Warning", "Block"]}
                 handleChange={changeInput}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, p: 3, }}>
          <Grid container spacing={1}> 
            <Grid item xs={8} >
            <Typography sx={{ mb: 3, fontSize: "14px", fontWeight: "600", }} > Loyalty Settings </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4}}>
                <Typography sx={{ fontSize: "12px", width: "100%", minWidth: "fit-content" }}>
                  Loyalty Value
                </Typography>
                <VBInputField
                  sx={{ width: "60px", }}
                  name="loyalty_PointValue"
                  value={glss.loyalty_PointValue}
                  onChange={changeInput}
                />
              </Box>
              {generalList.slice(-1).map((e, i) => (
                <Paper
                  elevation={0}
                  sx={{
                    height:"100%",
                    width: "308px",
                    p: "12px",
                    bgcolor: "#f8f8f8",
                    border: "1px solid #e2e2e2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={i + 2} 
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>{e.name}</Typography>
                  <TableSwitch checked={e.checked} />
                </Paper>
              ))}
            </Box>            
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={1}>
        <Box sx={{ borderBottom: 1, flexGrow: 1, p: 3, borderColor: "divider" }}>
          <Grid container spacing={1}>
            {salesList.slice(0, -4).map((obj, ind) => (
              <Grid key={ind+1} item xs={12} sm={6} lg={4} xl={3}>
                <Paper
                  elevation={0}
                  sx={{
                    height:"100%",
                    p: "12px",
                    bgcolor: "#f8f8f8",
                    border: "1px solid #e2e2e2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>{obj.name}</Typography>
                  <TableSwitch 
                    checked={obj.checked}
                    onChange={(e) => {
                      changeSetting(obj.keyName, e.target.checked, "salesList");
                    }}
                   />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ borderBottom: 1, flexGrow: 1, p: 3, borderColor: "divider" }}>
          <Grid container spacing={1}>
            <Grid item  xs={12}
              sm={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "center",
              }} >
              <Typography sx={{ fontSize: "12px", width: "100%" }}>
              Round Off Sales
              </Typography>
              <VBInputField
                sx={{ width: "60px" }}
                name="RoundOffSales"
                value={inptDetails.RoundOffSales}
                onChange={changeInput}
              />
            </Grid>
            <Grid 
              item  
              xs={12}
              sm={6}
              lg={4}
              xl={3} 
            >
              <VBSelect
                label="Sales Price Less Than Minimum Sales Price"
                value={inptDetails.SalesPriceLessThanMinimumSalesPrice}
                options={["Ignore", "Warning", "Block"]}
                handleChange={changeInput}
              />  
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ p: 3, mb: 3 }}>
          <Typography sx={{ mb: 3, fontSize: "14px", fontWeight: "600", }} > Search Product settings </Typography>
          <Grid container spacing={1}>
            {salesList.slice(-4).map((obj, ind) => (
              <Grid key={ind+1} item xs={12} sm={6} lg={4} xl={3}>
                <Paper
                  elevation={0}
                  sx={{
                    height:"100%",
                    p: "12px",
                    bgcolor: "#f8f8f8",
                    border: "1px solid #e2e2e2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>{obj.name}</Typography>
                  <TableSwitch 
                    checked={obj.checked}
                    onChange={(e) => {
                      changeSetting(obj.keyName, e.target.checked, ind);
                    }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

      </CustomTabPanel>

      <CustomTabPanel value={tabValue} index={2}>
        <Box sx={{ borderBottom: 1, flexGrow: 1, p: 3, borderColor: "divider" }}>
            <Grid container spacing={1}>
              {purchaseList.slice(0, -3).map((obj, ind) => (
                <Grid key={ind+1} item xs={12} sm={6} lg={4} xl={3}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: "12px",
                      height:"100%",
                      bgcolor: "#f8f8f8",
                      border: "1px solid #e2e2e2",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>{obj.name}</Typography>
                    <TableSwitch  
                      checked={obj.checked}
                      onChange={(e) => {
                        changeSetting(obj.keyName, e.target.checked, "purchaseList");
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
        </Box>  

        <Box sx={{ borderBottom: 1, flexGrow: 1, p: 3, borderColor: "divider" }}>
          <Typography sx={{ mb: 2, fontSize: "14px", fontWeight: "600", }} >Round Off Purchase</Typography>
          <Grid container spacing={1}>
            <Grid item xs={4} >
              <VBInputField
                sx={{ width: "60px" }}
                name="RoundOffPurchase"
                value={inptDetails.RoundOffPurchase}
                onChange={changeInput}
              />  
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ p: 3, mb: 3 }}>
          <Typography sx={{ mb: 3, fontSize: "14px", fontWeight: "600", }} > Search Product settings </Typography>
          <Grid container spacing={1}>
            {purchaseList.slice(-3).map((obj, ind) => (
              <Grid key={ind+1} item xs={12} sm={6} lg={4} xl={3}>
                <Paper
                  elevation={0}
                  sx={{
                    height:"100%",
                    p: "12px",
                    bgcolor: "#f8f8f8",
                    border: "1px solid #e2e2e2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "12px", fontWeight: "300" }}>{obj.name}</Typography>
                  <TableSwitch />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </CustomTabPanel>
    </>
  );
}

export default Inventory;
