import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SelectIcon } from "../../Components/Icones/IconComponents";
import PropTypes from "prop-types";
import Pagenation from "../../Components/Utils/Pagenation";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ActivityLog() {
  const [loadingState, setLoadingState] = useState({
    isListLoading: false,
    isInvoicePrintLoading: false,
  });
  const [dataDetails, setDataDetails] = useState({
    totalItem: 0,
  });

  const [page, setPage] = useState(1);
  const [type, setType] = useState("sales");
  const [tabValue, setTabValue] = useState(1);

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper
      elevation={0}
      sx={{ height: "100%", display: "flex", position: "relative" }}
    >
      <Stack
        sx={{
          minWidth: 400,
          height: "100%",
          justifyContent: "space-between",
          borderRight: "2px solid #F5F5F5",
        }}
      >
        <div>
          <Box sx={{ width: "100%", px: 1 }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#001746",
                width: "100%",
                pt: 2
              }}
            >
              Activity Log
            </Typography>
            <Select
              value={type}
              onChange={handleTypeChange}
              sx={{
                width: "100%",
                borderRadius: "6px",
                border: [
                  "1px solid #EEE",
                  "1px solid color(display-p3 0.9333 0.9333 0.9333)",
                ],
                background: [
                  "#F8F8F8",
                  "color(display-p3 0.9725 0.9725 0.9725)",
                ],
                py: 0,
                my: 2
              }}
              IconComponent={SelectIcon}
            >
              <MenuItem value={"sales"}>Sales</MenuItem>
              <MenuItem value={"purchase"}>Purchase</MenuItem>
              <MenuItem value={"product"}>Product</MenuItem>
            </Select>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              // aria-label="basic tabs example"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab
                sx={{ textTransform: "none" }}
                label="All"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "none" }}
                label="Created"
                {...a11yProps(1)}
              />
              <Tab
                sx={{ textTransform: "none" }}
                label="Deleted"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={tabValue} index={0}>
            <TableContainer
              component={Box}
              sx={{
                height: "100%",
                overflow: "scroll",
                p: 0,
              }}
            >
              <Table aria-label="simple table">
                <TableBody>
                  <LoadingList isLoading={loadingState.isListLoading}>
                    {Array.from({ length: 7 }, (_) => (
                      <TableRow>
                        <TableCell sx={{ p: 0, width: "10%", height: "100%" }}>
                          <Button
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              textAlign: "left",
                              py: 1,
                            }}
                          >
                            <div>
                              <Typography
                                sx={{ fontSize: "14px", fontWeight: 500 }}
                              >
                                Ahammed Sahil
                              </Typography>
                              <Typography
                                sx={{ fontSize: "12px", color: "#4B4B4B" }}
                              >
                                2024/06/2024- 16:20
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  textAlign: "right",
                                  fontWeight: "500",
                                  color: "#0A9EF3",
                                }}
                              >
                                RT0001
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  textAlign: "right",
                                }}
                              >
                                Name
                              </Typography>
                            </div>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </LoadingList>
                </TableBody>
              </Table>
            </TableContainer>
          </CustomTabPanel>

          <CustomTabPanel value={tabValue} index={1}>
            Created
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            Deleted
          </CustomTabPanel>
        </div>
        <Box
          sx={{
            px: 4,
            py: 1,
            borderTop: "2px solid #F5F5F5",
            borderRight: "2px solid #F5F5F5",
          }}
        >
          <Pagenation
            totalItem={dataDetails.totalItem}
            page_no={page}
            setPage_no={setPage}
          />
        </Box>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
            py: 2,
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "inline-block",
                color: "#4B4B4B",
                fontSize: "15px",
                mr: 1,
              }}
            >
              INV -NO:
            </Typography>
            <Typography
              sx={{
                display: "inline-block",
                color: "#2F2F2F",
                fontSize: "15px",
                fontWeight: "bold",
                mr: 2,
              }}
            >
              {/* {dataDetails.invoiceLists[0].VoucherNo} */}
              898
            </Typography>
          </Box>
          <Typography
            sx={{
              border: 1,
              fontSize: "12px",
              borderRadius: "6px",
              py: "4px",
              px: 2,
            }}
          >
            Sales Man: Rtyuiop;lk
          </Typography>
          {/* <Stack sx={{ flexDirection: "row", gap: 2, alignItems: "center" }}>
            {dataDetails.invoiceLists[0].salesman && (
              <Typography
                sx={{
                  border: 1,
                  fontSize: "12px",
                  borderRadius: "6px",
                  py: "4px",
                  px: 2,
                }}
              >
                Sales Man: {dataDetails.invoiceLists[0].salesman}
              </Typography>
            )}
          </Stack> */}
        </Stack>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            gap: 2,
            py: 1,
            pl: 2,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#28A745",
              bgcolor: "rgba(0, 170, 52, 0.07)",
              width: "50px",
              height: "50px",
            }}
          >
            C
          </Paper>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#DC3545",
              bgcolor: "rgba(240, 20, 62, 0.07)",
              width: "50px",
              height: "50px",
            }}
          >
            D
          </Paper>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#007BFF",
              bgcolor: "rgba(0, 125, 255, 0.07)",
              width: "50px",
              height: "50px",
            }}
          >
            E
          </Paper>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 3,
            p: 5,
            height: "80%",
            width: "100%",
          }}
        >
          <Paper
            sx={{
              borderRadius: "8.749px",
              background: ["#FFF", "color(display-p3 1 1 1)"],
              boxShadow: [
                "0px 2.916px 7.874px 0px rgba(0, 0, 0, 0.12)",
                "0px 2.916px 7.874px 0px color(display-p3 0 0 0 / 0.12)",
              ],
              height: "80%",
              width: "50%",
            }}
          ></Paper>

          <Paper
            sx={{
              borderRadius: "8.749px",
              background: ["#FFF", "color(display-p3 1 1 1)"],
              boxShadow: [
                "0px 2.916px 7.874px 0px rgba(0, 0, 0, 0.12)",
                "0px 2.916px 7.874px 0px color(display-p3 0 0 0 / 0.12)",
              ],
              height: "80%",
              width: "50%",
            }}
          ></Paper>
        </Box>
      </Box>
    </Paper>
  );
}

export default ActivityLog;

const LoadingList = function ({ isLoading, children }) {
  return isLoading ? (
    <Stack sx={{ pl: "4px", gap: 1 }}>
      {Array.from({ length: 7 }, (_) => (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={60}
          sx={{
            width: Math.floor(Math.random() * 100 + 1) + "%",
            backgroundColor: "#DFF0FF",
          }}
        />
      ))}
    </Stack>
  ) : (
    children
  );
};
