import styled from "@emotion/styled";
import { Button, Stack } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

function NavigationButtons() {
  return (
    <Stack
      sx={{
        // width: "40px",
        // backgroundColor: "#212121",
        position: "fixed",
        bottom: "14px",
        left: -158,
      }}
      spacing={1}
    >
      <StyledButton
        sx={{ boxShadow: 2 }}
        variant="contained"
        component={NavLink}
        to={"/products"}
        endIcon={<img src="../images/icons/ProductList.svg" alt="" />}
      >
        Products
      </StyledButton>
      <StyledButton
        sx={{ boxShadow: 2 }}
        variant="contained"
        component={NavLink}
        to={"/product-category"}
        endIcon={<img src="../images/icons/Category.svg" alt="" />}
      >
        Product Category
      </StyledButton>
      <StyledButton
        sx={{ boxShadow: 2 }}
        variant="contained"
        component={NavLink}
        to={"/product-group"}
        endIcon={<img src="../images/icons/ProductGroup.svg" alt="" />}
      >
        Group
      </StyledButton>
      <StyledButton
        sx={{ boxShadow: 2 }}
        variant="contained"
        component={NavLink}
        to={"/product-brand"}
        endIcon={<img src="../images/icons/Brand.svg" alt="" />}
      >
        Brand
      </StyledButton>
    </Stack>
  );
}

export default NavigationButtons;

const StyledButton = styled(Button)(() => ({
  position: "relative",
  justifyContent: "space-between",
  color: "#212121",
  backgroundColor: "#fff",
  textTransform: "none",
  fontSize: "15px",
  borderRadius: "0px 6px 6px 0px",
  boxShadow: 2,
  transition: "all 0.3s ease-in-out",
  ":hover": {
    transform: "translateX(72%)",
    color: "white",
    backgroundColor: "#212121",
  },
  img: {
    width: "40px",
    height: "40px",
  },
  "&.active": {
    backgroundColor: "#000",
  },
}));
