import { useSelector } from "react-redux";

function roundOffNumber(value, decimalPlaces) {
  const roundedValue = Math.round(value, 2);

  return roundedValue;
}

export function PurchaseInvoiceCalc(
  dataState,
  state,
  DataList,
  is_manual_roundoff,
  customRound,
  GST,
  CompanyState,
  RoundOffPurchase
) {
  let TotalTaxableAmount = 0;
  let TotalGrossAmt = 0;
  let TotalDiscount = 0;
  let TotalTax = 0;
  let NetTotal = 0;
  let AdditionalCost = 0;
  let GrandTotal = 0;
  let RoundOff = customRound(state.RoundOff) || 0;
  let CashReceived = 0;
  let CashAmount = state.CashAmount;
  let BankAmount = state.BankAmount;
  let BillDiscPercent = customRound(state.BillDiscPercent) || 0;
  let BillDiscAmt = customRound(state.BillDiscAmt) || 0;
  let VATAmount = 0;
  let SGSTAmount = 0;
  let CGSTAmount = 0;
  let IGSTAmount = 0;
  let Balance = 0;
  let OldLedgerBalance = 0;
  let TaxTaxableAmount = 0;
  let NonTaxTaxableAmount = 0;

  let DetailsAmountTotal = 0;
  console.log(
    DataList,
    "Datttttaaaaaaaaaaaaaaaaaa:::::::::::::::<<<<<<<<<<<<<<<<<<<"
  );
  let DataListLength = DataList?.filter((i) => i?.Product != null)?.length ?? 0;
  let TotalQty = DataListLength;
  let GrandTotalFc = 0;
  let NetTotalFc = 0;
  let TotalGrossAmtFc = 0;
  let CashAmountFc = 0;
  let BankAmountFc = 0;
  let InDomesticCurrency = state.InDomesticCurrency;

  // Shippin Charge
  let ShippingCharge = customRound(state.ShippingCharge) || 0;
  let ShippingTax = state.ShippingTax;
  let ShippingTaxAmount = state.ShippingTaxAmount;
  if (ShippingTax) {
    ShippingTaxAmount = customRound(
      (ShippingTax?.PurchaseTax * ShippingCharge) / 100
    );
  }

  // let BillDiscAmt_Split = 0;
  // if (BillDiscAmt && TotalQty) {
  //   BillDiscAmt_Split = BillDiscAmt / TotalQty;
  // }

  // let BillDisc_taxAmount = customRound(BillDiscAmt*15/100)

  //Looping dataList
  for (let index = 0; index < DataList.length; index++) {
    const item = DataList[index];

    let GrossAmount = customRound(item.GrossAmount) || 0;
    let DiscountAmount = customRound(item.DiscountAmount) || 0;
    let TaxAmount = customRound(item.TaxAmount) || 0;
    let Qty = Number(item.Qty) || 0;
    let Tax = item.Tax;
    let PurchaseTax = customRound(Tax?.PurchaseTax ?? 0);
    let Amount = 0;
    if (!isNaN(customRound(item.Amount))) {
      Amount = customRound(item.Amount);
    }

    TotalGrossAmt += GrossAmount || 0;
    TotalDiscount += DiscountAmount || 0;
    TotalTaxableAmount += GrossAmount - DiscountAmount;

    if (TaxAmount > 0) {
      TaxTaxableAmount += GrossAmount - DiscountAmount;
    } else {
      NonTaxTaxableAmount += GrossAmount - DiscountAmount;
    }

    //bill disc amount is splitting equally to every details then substraction that with gross amount then finding total tax
    TotalTax += TaxAmount; //(GrossAmount - (DiscountAmount + BillDiscAmt_Split)) * PurchaseTax / 100;

    DetailsAmountTotal += Amount;

    if (GST) {
      if (state.PlaceOfSupply?.id === CompanyState) {
        SGSTAmount += customRound(item.SGSTAmount);
        CGSTAmount += customRound(item.CGSTAmount);
      } else {
        IGSTAmount += customRound(item.IGSTAmount);
      }
    }
  }

  // TotalTax = TotalTax-BillDisc_taxAmount
  BillDiscAmt = (BillDiscPercent / 100) * TotalGrossAmt;
  state.BillDiscAmt = BillDiscAmt;

  let BillDisc_taxAmount = customRound((BillDiscAmt * 15) / 100);
  if (TotalTax > 0) {
    TotalTax = TotalTax - BillDisc_taxAmount;
  }
  //after
  TotalDiscount += BillDiscAmt;

  GrandTotal += customRound(TaxTaxableAmount + NonTaxTaxableAmount);
  GrandTotal += customRound(TotalTax);
  NetTotal += customRound(
    DetailsAmountTotal + ShippingCharge + ShippingTaxAmount
  );
  GrandTotal += customRound(ShippingCharge + ShippingTaxAmount);
  GrandTotal -= BillDiscAmt;

  if (is_manual_roundoff === false) {
    let round_off_value = RoundOffPurchase;
    let rounding = round_off_value / 10;
    let roundedValue = roundOffNumber(GrandTotal, 2);
    let GrandTotal_Dec = Number(GrandTotal % 1);
    let GrandTotal_INT = Math.floor(GrandTotal);

    if (GrandTotal_Dec <= rounding) {
      RoundOff = GrandTotal_Dec * -1;
      GrandTotal = GrandTotal_INT;
    } else {
      RoundOff = 1 - GrandTotal_Dec;
      GrandTotal = GrandTotal_INT + 1;
    }
  } else {
    GrandTotal += RoundOff;
  }

  // Update the state
  state.TotalQty = TotalQty;
  state.TotalGrossAmt = customRound(TotalGrossAmt);
  state.TotalDiscount = customRound(TotalDiscount);
  state.TotalTax = customRound(TotalTax + ShippingTaxAmount);
  state.NetTotal = customRound(NetTotal);
  state.TotalTaxableAmount = customRound(TotalTaxableAmount);
  state.TaxTaxableAmount = customRound(TaxTaxableAmount);
  state.NonTaxTaxableAmount = customRound(NonTaxTaxableAmount);
  state.RoundOff = customRound(RoundOff);
  state.GrandTotal = customRound(GrandTotal);
  state.GrandTotalFc = customRound(GrandTotal / state.ForeignCurrency);
  state.NetTotalFc = customRound(NetTotal / state.ForeignCurrency);
  state.InForeignCurrencyDisc = customRound(
    BillDiscAmt / state.ForeignCurrency
  );
  state.TotalGrossAmtFc = customRound(TotalGrossAmt / state.ForeignCurrency);
  state.CashAmountFc = customRound(CashAmount / state.ForeignCurrency);
  state.BankAmountFc = customRound(BankAmount / state.ForeignCurrency);
  state.InForeignCurrency = customRound(
    InDomesticCurrency / state.ForeignCurrency
  );

  // shipping Charge
  state.ShippingTaxAmount = customRound(ShippingTaxAmount);

  // state.AdditionalChargesFC = customRound(state.InForeignCurrency / state.ForeignCurrency)
  // state.AdditionalCharges = customRound(AdditionalChargesFC * state.ForeignCurrency)

  state.TotalCGST = customRound(CGSTAmount);
  state.TotalSGST = customRound(SGSTAmount);
  state.TotalIGST = customRound(IGSTAmount);

  return state;
}

// Line Calculation
export function PurchaseLineCalc(
  dataState,
  state,
  lineItem,
  customRound,
  GST,
  CompanyState
) {
  let Qty = Number(lineItem.Qty) || 0;
  let Rate = customRound(lineItem.Rate) || 0;
  let DiscountAmount = customRound(lineItem.DiscountAmount) || 0;
  let DiscountPerc = customRound(lineItem.DiscountPerc) || 0;
  let Tax = 0;
  if (state?.Treatment?.value === "0" || (state?.Treatment?.value === "1" && state?.Treatment?.is_gst)) {
    Tax = lineItem.Tax;
  }
  let TaxID = lineItem.TaxID;

  let PurchaseTax = 0;
  if (state?.Treatment?.value === "0" || (state?.Treatment?.value === "1" && state?.Treatment?.is_gst)) {
    PurchaseTax = customRound(Tax?.PurchaseTax);
  }

  let UnitTaxAmount = customRound((Rate / 100) * PurchaseTax);
  let GrossAmount = customRound(Rate * Qty);
  DiscountAmount = customRound((DiscountPerc / 100) * GrossAmount);

  let TaxableAmount = customRound(GrossAmount - DiscountAmount);
  let TaxAmount = customRound(GrossAmount - DiscountAmount);

  TaxAmount = customRound((TaxAmount / 100) * PurchaseTax);

  let Amount = customRound(GrossAmount - DiscountAmount + TaxAmount);

  let GstHalfAmount = customRound(TaxAmount / 2);
  let Gst_perc = PurchaseTax / 2;

  lineItem.Qty = Qty;
  lineItem.Rate = customRound(lineItem.Rate) || 0;
  let Expense = customRound(lineItem.Expense) || 0;
  lineItem.DiscountAmount = DiscountAmount;
  //update item
  lineItem.TaxableAmount = customRound(TaxableAmount);
  lineItem.TaxAmount = customRound(TaxAmount);
  let InclusivePrice = customRound(Rate + UnitTaxAmount);

  lineItem.InclusivePrice = customRound(InclusivePrice);
  lineItem.GrossAmount = customRound(GrossAmount);
  lineItem.Amount = customRound(Amount);

  // Foreign Currency Calculations
  let FCRate = customRound(Rate / state.ForeignCurrency);
  let FcGrossAmount = customRound(FCRate * Qty);
  let FCDiscountAmount = customRound(DiscountAmount / state.ForeignCurrency);

  lineItem.ConversionRate = customRound(state.ForeignCurrency);
  lineItem.FCRate = FCRate;
  lineItem.FCAmount = customRound(FcGrossAmount - FCDiscountAmount + TaxAmount);
  lineItem.FCDiscountAmount = FCDiscountAmount;
  lineItem.FcGrossAmount = FcGrossAmount;
  lineItem.CostWithExpense = customRound(Rate * Qty + Expense);
  console.log(FCRate, "LLLLLLLLLLLLLLL", FCDiscountAmount, TaxAmount, Expense);

  // if (lineItem.TaxAmount > 0) {
  //   TaxableAmount = customRound(GrossAmount - DiscountAmount);
  // } else {
  //   TaxableAmount = 0
  // }
  if (GST) {
    console.log(
      state.PlaceOfSupply?.id,
      "koOOOOOOOOOOOOOOOOOOOOOOFFF",
      CompanyState
    );

    if (
      state?.Treatment?.value === "4" ||
      state?.Treatment?.value === "5" ||
      state?.Treatment?.value === "6"
    ) {
      lineItem.IGSTAmount = 0;
      lineItem.IGSTPerc = 0;
      lineItem.CGSTAmount = 0;
      lineItem.SGSTAmount = 0;
      lineItem.CGSTPerc = 0;
      lineItem.SGSTPerc = 0;
      lineItem.TaxAmount = 0;
      lineItem.Amount = customRound(GrossAmount - DiscountAmount);
    } else if (state.PlaceOfSupply?.id === CompanyState) {
      console.log(lineItem, "koOOOOOOOOOOOOO1111111111111OOOOOOOOOFFF");

      lineItem.CGSTAmount = GstHalfAmount;
      lineItem.SGSTAmount = GstHalfAmount;
      lineItem.CGSTPerc = Gst_perc;
      lineItem.SGSTPerc = Gst_perc;
      lineItem.IGSTAmount = 0;
      lineItem.IGSTPerc = 0;
    } else {
      console.log(lineItem, "koOOOOOOOOOO2222222222OOOOOOOOOOOOFFF");
      lineItem.IGSTAmount = TaxAmount;
      lineItem.IGSTPerc = PurchaseTax;
      lineItem.CGSTAmount = 0;
      lineItem.SGSTAmount = 0;
      lineItem.CGSTPerc = 0;
      lineItem.SGSTPerc = 0;
    }
  }

  return lineItem;
}

// validation
export const PurchaseInvoiceValidation = (
  dataState,
  state,
  DataList,
  GeneralSettingsData,
  tableSettings
) => {
  console.log(
    "PurchaseInvoiceValidationHELLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL"
  );
  let field = null;
  let message = null;
  let error = null;
  let indexList = [];

  let Data = DataList;
  let length = Data.length;

  let is_non_taxable_amount = false;
  let is_taxable_amount = false;

  if (Data.length === 0) {
    error = true;
    message = "At least need one valid row";
  } else if (Data.length === 1 && !Data[0].Product) {
    message = "At least need one valid row";
  }

  //looping the dataList and checking all mandatory values are included
  Data.map((obj, i) => {
    console.log(typeof(i), "objbjbbjbj", obj.MinimumSalesPrice, obj.SalesPrice);

    if (!obj.Product) {
      if (
        i + 1 === length &&
        !obj.ProductCode &&
        !obj.BarCode &&
        !obj.Product &&
        // !obj.Description &&
        !obj.Unit &&
        // !obj.UnitList &&
        !obj.Stock &&
        !obj.Qty &&
        obj.Qty <= 0 &&
        // !obj.FQty &&
        !obj.Rate &&
        // !obj.AvgCost &&
        !obj.Tax &&
        !obj.InclusivePrice &&
        !obj.GrossAmount &&
        // !obj.DiscPerc &&
        !obj.DiscountAmount &&
        !obj.TaxAmount &&
        !obj.Amount &&
        !obj.MRP
        // !obj.PurchasePrice &&
        // !obj.BatchCode &&
        // !obj.MinimumSalesPrice &&
        // !obj.AverageCost
      ) {
        console.log("IGNORE THE PART");
      } else {
        indexList.push(i);
      }
    } else if (!obj.Product) {
      indexList.push(i);
    } else if (!obj.Unit) {
      indexList.push(i);
    } else if (!obj.Qty && Number(obj.Qty) <= 0) {
      indexList.push(i);
    } else if (!obj.Rate && Number(obj.Rate) <= 0) {
      indexList.push(i);
    } else if (!obj.Tax) {
      indexList.push(i);
    } else if (!obj.InclusivePrice) {
      indexList.push(i);
    } else if (!obj.GrossAmount) {
      indexList.push(i);
    }
    // else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None" ) {
    //   indexList.push(i);
    // }
    else if (!obj.Amount) {
      indexList.push(i);
    } else if (
      GeneralSettingsData.ShowSalesPriceInPurchase &&
      tableSettings.MinimumSalesPrice &&
      tableSettings.SalesPrice &&
      obj.SalesPrice < obj.MinimumSalesPrice
    ) {
      error = true;
      message = "Sales Price Lesthan Minimum SalesPrice! Line " + (i + 1) ;
      console.log(i);
    } else if (
      GeneralSettingsData.ShowSalesPriceInPurchase &&
      tableSettings.MRP &&
      tableSettings.SalesPrice &&
      obj.MRP < obj.SalesPrice
    ) {
      error = true;
      message = "Sales Price Greater MRP! Line " + (i + 1) ;
    } else if (
      GeneralSettingsData.ShowSalesPriceInPurchase &&
      tableSettings.MRP &&
      tableSettings.SalesPrice &&
      obj.MRP < obj.MinimumSalesPrice
    ) {
      error = true;
      message = "Minimum Sales Price Greater MRP! Line " + (i + 1) ;
    }

    if (obj.Product) {
      //checking is there any non tax amount and tax amount
      if (obj.Tax?.TaxName === "None") {
        is_non_taxable_amount = true;
      } else {
        is_taxable_amount = true;
      }
    }
  });

  let newIndexList = indexList.map((i) => i + 1);

  console.log(
    indexList,
    "lllLLLLLLLLLLPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP"
  );
  if (indexList.length > 0) {
    message = "Data missing Lines " + newIndexList;
    error = true;
  }

  //checking values of state
  if (!state.AccountLedger && !error) {
    error = true;
    message = "Provide valid AccountLedger";
  } else if (!state.CashAccount && !error) {
    error = true;
    message = "Provide valid CashAccount";
  } else if (!state.BankAccount && !error) {
    error = true;
    message = "Provide valid BankAccount";
  } else if (!state.Warehouse && !error) {
    error = true;
    message = "Provide valid Warehouse";
  } else if (!state.Treatment && !error) {
    error = true;
    message = "Provide valid Treatment";
  } else if (!state.PlaceOfSupply && !error) {
    error = true;
    message = "Provide valid PlaceOfSupply";
  } else if (!state.Date && !error) {
    error = true;
    message = "Provide valid Date";
  } else if (!state.TotalGrossAmt && !error) {
    error = true;
    message = "Provide valid TotalGrossAmt";
  }
  // else if (!state.TotalTax && !error && is_taxable_amount) {
  //     error = true;
  //     message = "Provide valid TotalTax";
  // }
  else if (!state.NetTotal && !error) {
    error = true;
    message = "Provide valid NetTotal";
  } else if (!state.TotalTaxableAmount && !error) {
    error = true;
    message = "Provide valid TotalTaxableAmount";
  }
  // else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
  //     error = true;
  //     message = "Provide valid TaxTaxableAmount";
  // }
  else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
    error = true;
    message = "Provide valid NonTaxTaxableAmount";
  } else if (!state.GrandTotal && !error) {
    error = true;
    message = "Provide valid GrandTotal";
  } else if (state.BankAmount > state.GrandTotal && !error) {
    error = true;
    message = "Bank amount exceeds GrandTotal!";
  }else if (
    !state.RefferenceBillNo &&
    !error &&
    GeneralSettingsData.ReferenceBillNo
  ) {
    error = true;
    message = "RefferenceBillNo Cannot be empty!";
  } else if (!state.Notes && !error && GeneralSettingsData.EnableEinvoiceKSA) {
    error = true;
    message = "Provide Notes";
  } else if (
    state.BillDiscAmt > state.TotalTaxableAmount &&
    GeneralSettingsData.EnableEinvoiceKSA
  ) {
    error = true;
    message = "Bill Discount Can't be Grater than Taxable Amount ";
  }

  console.log(error, ";ppppppppppppppppppppppppppppperrrororo");
  return { error, message, field, indexList };
};
