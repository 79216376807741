import { styled } from "@mui/system";
import { Box } from "@mui/material";
// import ReactToPrint from "react-to-print";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { forwardRef, useEffect, useRef, useState } from "react";
import { formatNumber } from "../generalFunction";
// import { useNavigate } from "react-router-dom";
// import {
//   selectBranchID,
//   selectCompanyID,
// } from "../features/companyDetailsSlice";

const CustomeTemplate = forwardRef((props, ref) => {
  console.log(props.data.TextField , "Custom props is here and there and how it works and");

  // const [t] = useTranslation("common");

  // const { isAuth, access, user_id } = useSelector((state) => state.user);
  const tableRef = useRef(null);
  const tableHead = useRef(null);
  const [tableHeight, setTableHeight] = useState(0);
  // const history = useNavigate();
  // const CompanyID = useSelector(selectCompanyID);
  // const BranchID = useSelector(selectBranchID);
  // const [state, setState] = useState({ template: [] });
  const [tableHeadHeight, setTableHeadHeight] = useState(0);
  // const { PriceDecimalPoint, QtyDecimalPoint } = useSelector(
  //   (state) => state.generalSettingsSclice.generalSettings
  // );
  const { IsTrialVersion } = useSelector((state) => state.branchSettings);
  const [isMultiPage, setIsMultiPage] = useState(false);
  // const PriceRounding = Number(PriceDecimalPoint);

  const main_style = {
    background: "#fff",
    width: "240mm",
    // height: "auto",
    margin: "0",
    padding: "0mm",
  };
  const font_family = "sans-serif";
  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.offsetHeight);

      // console.log("tableHeadHeight=====>", tableRef.current.offsetHeight);
    }
    if (tableHead.current) {
      setTableHeadHeight(tableRef.current.clientHeight);
    }
  }, []);

  // const checkMultiPagePrint = () => {
  //   const printAreaHeight = document.body.scrollHeight;
  //   const pageHeight = window.innerHeight;
  //   console.log(
  //     "tableRef.current.offsetHeight=====>",
  //     tableRef.current.offsetHeight
  //   );
  //   console.log("printAreaHeight=====>", printAreaHeight);
  //   console.log("pageHeight=====>", pageHeight);
  //   console.log(
  //     "printAreaHeight > pageHeight=====>",
  //     printAreaHeight > pageHeight
  //   );
  //   setIsMultiPage(tableRef.current.offsetHeight > pageHeight);
  // };

  return (
    <Container ref={ref}>
      <FrameMainContainer>
        <FrameContainer>
          {(
            <div style={{ filter: IsTrialVersion ? "blur(2px)" : null }}>
              {/* <ReactToPrint
                ref={props.reactToPrintRef}
                trigger={() => <></>}
                // content={() => props.divRef.current}
                content={() => {
                  checkMultiPagePrint();
                  return props.divRef.current;
                }}
                onBeforeGetContent={() =>
                  new Promise((resolve) => {
                    checkMultiPagePrint();
                    setTimeout(resolve, 500); // Small delay to ensure state update
                  })
                }
              /> */}
              <div ref={props.divRef} id="printable-div" style={main_style}>
                {props.data.TextField.map((field, index) =>
                  field.IsImage ? (
                    <img
                      alt=""
                      key={field.id}
                      style={{
                        position: "absolute",
                        width: field.width + "px",
                        color: field.color,
                        top: field.top !== null ? field.top + "px" : "",
                        bottom:
                          field.bottom !== null ? field.bottom + "px" : "",
                        left: field.left !== null ? field.left + "px" : "",
                        right: field.right !== null ? field.right + "px" : "",
                        border:
                          (field.IsText ? field.border_size : 1) +
                          "px " +
                          (field.IsText ? field.border_style : "dashed") +
                          "" +
                          field.border_color, // Set border directly
                        borderRadius: field.border_radius + "px",
                        zIndex: 0,
                      }}
                      src={field.value}
                    />
                  ) : (
                    <div
                      key={field.id}
                      style={{
                        position: "absolute",
                        width: field.width + "px",
                        fontFamily: font_family,
                        fontSize: field.font_size + "px",
                        fontWeight: field.font_weight,
                        textAlign: field.text_align,
                        color: field.color,
                        top: field.top !== null ? field.top + "px" : "",
                        bottom:
                          field.bottom !== null ? field.bottom + "px" : "",
                        left: field.left !== null ? field.left + "px" : "",
                        right: field.right !== null ? field.right + "px" : "",
                        border:
                          field.border_size +
                          "px " +
                          field.border_style +
                          "" +
                          field.border_color, // Set border directly
                        borderRadius: field.border_radius + "px",
                        zIndex: 10,
                      }}
                    >
                      {field.value}
                    </div>
                  )
                )}
                {/* </div> */}

                <table
                  ref={tableRef}
                  id="tb1"
                  className="print-table"
                  key="TB1"
                  // draggable={true}
                  // onDragStart={(event) => handleDragStart(event, props.data.Table.id)}
                  // onDragEnd={handleDragEnd}
                  // onClick={(event) => props.handleSelect(event, tb1.id, "Text")}
                  style={{
                    position: "absolute",
                    width: props.data.Table.TableWidth + "pt",
                    // fontSize: props.data.Table.font_size + "px",
                    fontWeight: props.data.Table.font_weight,
                    fontFamily: font_family,
                    // textAlign: props.data.Table.text_align,
                    color: props.data.Table.color,
                    // tableLayout: "fixed",
                    top:
                      props.data.Table.TableTop !== null
                        ? props.data.Table.TableTop + "px"
                        : "",
                    left:
                      props.data.Table.TableLeft !== null
                        ? props.data.Table.TableLeft + "px"
                        : "",
                    // bottom: tb1.bottom !== null ? tb1.bottom + "px" : "",
                    // left: tb1.left !== null ? tb1.left + "px" : "",
                    // right: tb1.right !== null ? tb1.right + "px" : "",
                    // border: tb1.border_size + "px " + tb1.border_style + ""+ tb1.border_color, // Set border directly
                    // borderRadius: tb1.border_radius + "px"
                  }}
                >
                  <thead>
                    <tr ref={tableHead}>
                      {props.data.TableHead.map((field, index) => (
                        <th
                          style={{
                            width:
                              field.width !== "0" ? field.width + "px" : "auto",
                            fontSize: field.font_size + "px",
                            textAlign: field.text_align,
                            color: field.color,
                            borderLeft:
                              field.border_Left +
                              "px " +
                              field.border_style +
                              "" +
                              field.border_color,
                            borderRight:
                              field.border_Right +
                              "px " +
                              field.border_style +
                              "" +
                              field.border_color,
                            borderTop:
                              field.border_Top +
                              "px " +
                              field.border_style +
                              "" +
                              field.border_color,
                            borderBottom:
                              field.border_Bottom +
                              "px " +
                              field.border_style +
                              "" +
                              field.border_color,
                          }}
                          // onClick={(event) => props.handleSelectTB(event, field.id)}
                          id={field.id}
                        >
                          <div
                            style={{
                              paddingLeft: field.paddingLeft + "px",
                              paddingRight: field.paddingRight + "px",
                              paddingTop: field.paddingTop + "px",
                              paddingBottom: field.paddingBottom + "px",
                              fontWeight: field.font_weight,
                              fontFamily: font_family,
                              lineHeight: 1,
                            }}
                          >
                            {field.value.includes("<br/>") ? (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: field.value,
                                }}
                                style={{
                                  fontWeight: field.font_weight,
                                  fontFamily: font_family,
                                }}
                              />
                            ) : (
                              field.value
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.TableData.map((row, index) => (
                      <tr>
                        {row?.map((field, indexb) => (
                          <td
                            id={field.id}
                            // onClick={(event) => props.handleSelectTB(event, field.id)}
                            style={{
                              // width:(field.width === "0") ? "auto" : (field.width + "px"),

                              maxWidth:
                                field.width !== "0"
                                  ? field.width + "px"
                                  : "auto",
                              width:
                                field.width !== "0"
                                  ? field.width + "px"
                                  : "auto",
                              fontSize: field.font_size + "px",
                              fontFamily: font_family,
                              textAlign: field.text_align,
                              color: field.color,
                              borderLeft:
                                field.border_Left +
                                "px " +
                                field.border_style +
                                "" +
                                field.border_color,
                              borderRight:
                                field.border_Right +
                                "px " +
                                field.border_style +
                                "" +
                                field.border_color,
                              borderTop:
                                field.border_Top +
                                "px " +
                                field.border_style +
                                "" +
                                field.border_color,
                              borderBottom:
                                field.border_Bottom +
                                "px " +
                                field.border_style +
                                "" +
                                field.border_color,
                            }}
                          >
                            <div
                              style={{
                                paddingLeft: field.paddingLeft + "px",
                                paddingRight: field.paddingRight + "px",
                                paddingTop: field.paddingTop + "px",
                                paddingBottom: field.paddingBottom + "px",
                                fontWeight: field.font_weight,
                                fontFamily: font_family,
                              }}
                            >
                              {field.value === "SlNo" ? index + 1 : formatNumber(field.value)}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* <div style={{ position: 'absolute', top: `${props.data.Table.TableTop + tableHeight + 10}px`, left: props.data.Table.TableLeft !== null ? props.data.Table.TableLeft + "px" : "" }}>
            <button onClick={() => alert('Button clicked!')}>Click Me</button>
          </div> */}

                {/* </div> */}

                {props.data.FooterData.map((field, index) =>
                  field.IsImage ? (
                    <img
                      alt=""
                      key={field.id}
                      className={isMultiPage ? "increment-top" : ""}
                      style={{
                        position: "absolute",
                        width: field.width + "px",
                        color: field.color,
                        top: `${(Number(field.top) + tableHeight) - 50}px`,
                        "--top": `${field.top}px`, // Custom property for top value
                        bottom:
                          field.bottom !== null ? field.bottom + "px" : "",
                        left: field.left !== null ? field.left + "px" : "",
                        right: field.right !== null ? field.right + "px" : "",
                        border:
                          (field.IsText ? field.border_size : 1) +
                          "px " +
                          (field.IsText ? field.border_style : "dashed") +
                          "" +
                          field.border_color, // Set border directly
                        borderRadius: field.border_radius + "px",
                      }}
                      src={field.value}
                    />
                  ) : (
                    <div
                      key={field.id}
                      className={isMultiPage ? "increment-top" : ""}
                      style={{
                        position: "absolute",
                        width: field.width + "px",
                        fontSize: field.font_size + "px",
                        fontFamily: font_family,
                        fontWeight: field.font_weight,
                        textAlign: field.text_align,
                        color: field.color,
                        top: `${(Number(field.top) + tableHeight) - 50}px`,
                        "--top": `${field.top}px`, // Custom property for top value
                        bottom:
                          field.bottom !== null ? field.bottom + "px" : "",
                        left: field.left !== null ? field.left + "px" : "",
                        right: field.right !== null ? field.right + "px" : "",
                        border:
                          field.border_size +
                          "px " +
                          field.border_style +
                          "" +
                          field.border_color,
                        borderRadius: field.border_radius + "px",
                      }}
                    >
                      {field.value}
                    </div>
                  )
                )}
              </div>
            </div>
          )}

        </FrameContainer>
      </FrameMainContainer>
    </Container>
  );
})

export default CustomeTemplate;

// Frame Main Container
const FrameMainContainer = styled("div")({
  position: "relative",
});

// Customize Button
const CustomizeButton = styled("button")({
  border: 0,
  position: "absolute",
  top: 0,
  right: 0,
  padding: "10px 20px",
  borderBottomLeftRadius: "20px",
  background: "#ccc",
  cursor: "pointer",
  opacity: 0,
  visibility: "hidden",
  transition: "all ease-in-out 0.3s",
});

// Background
const Background = styled("div")({
  background: "linear-gradient(90deg, #f2f2f2 4.29%, #f2f2f2 97.5%)",
  color: "transparent",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  fontSize: "15px",
  padding: "10px 0px",
});

// Upgrade Button
const UpgradeButton = styled("div")({
  display: "flex",
  position: "absolute",
  top: "78px",
  right: "-72px",
  padding: "0px 50px",
  // border: '0.5px solid #DED8B9',
  // background: '#1F1F1F',
  borderRadius: "5px",
  cursor: "pointer",
  transform: "rotate(45deg)",
  zIndex: 2,
  clipPath: "polygon(11% 0, 89% 0, 102% 122%, -2% 122%)",
  background: "linear-gradient(45deg, #000000, #AD9515, #1F1F1F)",
  backgroundSize: "300% 300%",
  animation: "runningLight 10s linear infinite",
  border: "1px solid transparent",
  borderTopColor: "transparent",
  borderRightColor: "transparent",

  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "0px",
    left: "-11px",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "12px 12px 0 17px",
    borderColor: "transparent #1f1f1f transparent transparent",
    zIndex: 1,
    transform: "rotate(45deg)",
  },

  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "-5px",
    right: "1px",
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "12px 12px 0 0",
    borderColor: "transparent #1f1f1f transparent transparent",
    zIndex: 1,
    transform: "rotate(45deg)",
  },

  "@keyframes runningLight": {
    "0%": {
      backgroundPosition: "300% 0",
    },
    "50%": {
      backgroundPosition: "-300% 0",
    },
    "100%": {
      backgroundPosition: "300% 0",
    },
  },
});

// Frame Container
const FrameContainer = styled("div")({
  zIndex: 1,
  width: "240mm",
  height: "68vh",
  margin: "0 auto",
  position: "relative",

  [`&:hover ${CustomizeButton}`]: {
    visibility: "visible",
    opacity: 1,
    transition: "all ease-in-out 0.3s",
  },
});

// Container
const Container = styled("div")({
  marginTop: "40px",
  textAlign: "center",
  height: "68vh",
});

// Frame
const Frame = styled("iframe")(({ template }) => ({
  width: "240mm",
  height: "100%",
  ...(template === "template5" || template === "template6"
    ? {
        width: "135mm",
      }
    : {}),
  border: "1px solid #ccc",
}));

// FlexBox
const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
});

//   <style>
//                 {`
//           // @page { size: 210mm;  margin: 0mm; }
//           @media print {
//             html, body { height: initial !important; overflow: initial !important; }
//                 body {
//                   -webkit-print-color-adjust: exact; /* Chrome, Safari, Edge */
//                   color-adjust: exact; /* Firefox */
//                 }
//                 .increment-top {
//                   top: calc(var(--top) + ${tableHeadHeight}px) !important;
//                 }
//                 // .print-table{
//                 //   margin-bottom: 250px;
//                 //   background-color: lightgreen;
//                 // }

//                 // #printable-div {
//                 //   transform: scale(1.1); /* Scale down the content to fit the print view */
//                 //   transform-origin: top left;
//                 // }

//               }

//               }`}
//               </style>
