import axios from "axios";
import { store } from "../../app/store";

const state = store.getState();
const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

const CallUsers = async function (body) {
  try {
    const { data } = await axios.post("api/v10/users/users/",       
    {
      ...body
    });
    return data;
  } catch (error) {
    console.log("Error in CallUsers");
  }
};

const CallWarehouses = async function (body) {
  try {
    const { data } = await axios.post("api/v10/warehouses/warehouses/", {
      ...body
    });
    return data;
  } catch (error) {
    console.log("Error in CallWarehouses");
  }
};
export const CallChartOfAccount = async function (body) {
  try {
    const { data } = await axios.post("api/v11/reports/chart-of-accounts-report/", {
      ...body
    });
    return data;
  } catch (error) {
    console.log("Error in CallWarehouses");
  }
};

const CallParties = async function (body) {
  try {
    const { data } = await axios.post("api/v11/parties/parties/", {
    ...body});
    return data;
  } catch (error) {
    console.log("Error in CallParties");
  }
};

const CallRoutes = async function (body) {
  try {
    const { data } = await axios.post("api/v10/routes/routes/", {
      ...body
    });
    return data;
  } catch (error) {
    console.log("Error in CallParties");
  }
};

const CallEmployees = async function (body) {
  try {
    const { data } = await axios.post("api/v11/employees/employees/", {
      ...body
    });
    return data;
  } catch (error) {
    console.log("Error in CallParties");
  }
};

const CallAgeingLedgerReport = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/ageingReport/ageing/ledger-report/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallAgeingLedgerReport ");
  }
};

const CallProductCategories = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/productCategories/productCategories/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};

const CallProductGroups = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/productGroups/productGroups/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProductGroups");
  }
};

const CallProductSearch = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/products/products-search-qry/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log(error,"error in CallProductSearch");
  }
};

const CallBrands = async function (body) {
  try {
    const { data } = await axios.post("api/v10/brands/brands/", {
      ...body
    });
    return data;
  } catch (error) {
    console.log("error in CallBrands");
  }
};

const CallPriceLists = async function (body) {
  try {
    const { data } = await axios.post("api/v10/priceLists/priceLists/", body);
    return data;
  } catch (error) {
    console.log("error in CallPriceLists");
  }
};

const CallLedgerListById = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/accountLedgers/ledgerListByID/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
};


const CallBranchsList = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/branchs/branchs/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
};
const CallLedgerListByGroup = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/accountLedgers/ledgerListByGroupUnder/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
};
const CallLedgerListByGroupsV11 = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/accountLedgers/ledgerListByGroups/",
      {
        ...body
      }
    );
    return data;
  } catch (error){
    console.log('error in LedgerListbygroupvll');
  }
};
const CallLedgerListByGroupLimit = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/accountLedgers/ledgerListByGroupUnder/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
};
const callBatchList = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/products/get-batch/list/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
};

const callEditCustomePeriods = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/ageingReport/edit-custom-period/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
};

const  callAccountGroup = async function(body){
  try {
    const { data } = await axios.post(
      "api/v10/accountGroups/accountGroups/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
}

const callBankReconsilationList = async function(body){
  try {
    const { data } = await axios.post(
      "api/v10/bankReconciliationStatement/bank-list/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
}

const callCreateBankReconsil = async function(body){
  try {
    const { data } = await axios.post(
      "api/v11/Bank/Create-BankReconsil/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in LedgerListById");
  }
}


const callBranchList = async function(body){
  try {
    const { data } = await axios.post(
      "api/v11/branches/branch-list/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in BranchList");
  }
}


const CallBarcodeSearch = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/products/get-product/barcode/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallBarcodeSearch");
  }
};

const CallTaxListByType = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/taxCategories/taxListByType/",
      {
        ...body
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallBarcodeSearch");
  }
};

export {
  CallUsers,
  CallWarehouses,
  CallParties,
  CallRoutes,
  CallEmployees,
  CallAgeingLedgerReport,
  CallProductCategories,
  CallProductGroups,
  CallProductSearch,
  CallBrands,
  CallPriceLists,
  CallLedgerListById,
  CallBranchsList,
  CallLedgerListByGroup,
  CallLedgerListByGroupsV11,
  CallLedgerListByGroupLimit,
  callBatchList,
  callEditCustomePeriods,
  callAccountGroup,
  callBankReconsilationList,
  callCreateBankReconsil,
  callBranchList,
  CallBarcodeSearch,
  CallTaxListByType,
};
