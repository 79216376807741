import {
  Box,
  Button,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import PageLoading from "../../Components/Loadings/PageLoading";

function Settings() {
  return (
    <Stack direction={"row"} sx={{ height: "100%", width: "100%" }}>
      <Box sx={{}}>
        <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: 2 }}>
          Settings
        </Typography>
        <Stack gap={1}>
          <Stylebutton component={NavLink} to="" end>
            Settings
          </Stylebutton>
          <Stylebutton component={NavLink} to="transaction-nos">
            Transaction Nos
          </Stylebutton>
          <Stylebutton component={NavLink} to="users">
            Users
          </Stylebutton>
          <Stylebutton component={NavLink} to="user-roles">
            User Roles
          </Stylebutton>
          <Stylebutton component={NavLink} to="whats-new">
            Whats New
          </Stylebutton>
          <Stylebutton component={NavLink} to="e-invoice">
            KSA E-Invoice Settings
          </Stylebutton>
        </Stack>
      </Box>

      <Box sx={{ width: "87%" }}>
        {/* <React.Suspense fallback={<PageLoading />}>
          <React.lazyazy> */}
        <Outlet />
        {/* </React.lazyazy> */}
        {/* </React.Suspense> */}
      </Box>
    </Stack>
  );
}

export default Settings;

const Stylebutton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  boxShadow: "none",
  justifyContent: "flex-start",
  color: "black",
  "&.active": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
}));
