import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { TableFooter, styled, Skeleton, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { randomId } from "@mantine/hooks";
import Box from "@mui/material/Box";

const ReportTable = () => {
  return <ColumnGroupingTable />;
};

export default ReportTable;

function ColumnGroupingTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  return (
    <Paper sx={{ width: "100%", height: "calc(100% - 126px)" }}>
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ height: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCellHeader></TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <StyledTableCellBody></StyledTableCellBody>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCellFooter>total Value</TableCellFooter>
              <TableCellFooter>total Value</TableCellFooter>
              <TableCellFooter align="right">total Value</TableCellFooter>
              <TableCellFooter align="right">total Value</TableCellFooter>
              <TableCellFooter align="right">total Value</TableCellFooter>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export const VBTableContainer = function (props) {
  const { children, ref, height, isNoFilter } = props;
  return (
    <Paper
      sx={{
        width: "100%",
        height: `calc(100% - ${!isNoFilter ? "126px" : "74px"})`,
        position: "relative",
      }}
      elevation={0}
    >
      <TableContainer sx={{ height: height ? height : "100%" }}>
        <Table
          ref={ref}
          stickyHeader
          aria-label="sticky table"
          sx={{ height: "100%" }}
        >
          {children}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export const VBTableHeader = function ({ children }) {
  return (
    <TableHead>
      <TableRow>{children}</TableRow>
    </TableHead>
  );
};

export const VBTableCellHeader = function (props) {
  return (
    <TableCellHeader sx={{ height: "45px", ...props.sx }} {...props}>
      {props.children || " - "}
    </TableCellHeader>
  );
};

export const VBTableBody = function ({ children }) {
  return (
    <TableBody>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {children}
      </TableRow>
    </TableBody>
  );
};

export const VBTableCellBody = function (props) {
  return (
    <StyledTableCellBody sx={props.sx} {...props}>
      {props.children ?? " - "}
    </StyledTableCellBody>
  );
};
export const VBTableRowNull = function ({ length }) {
  return (
    <>
      {length === 0 && (
        <>
          <TableRow>
            <VBTableCellBody sx={{ border: "none" }}> </VBTableCellBody>
          </TableRow>
          <TableRow></TableRow>
        </>
      )}

      <TableBody></TableBody>
    </>
  );
};
export const VBTableCellBodyColor = function ({ children, color }) {
  return (
    <StyledTableCellBody sx={{ color: color }}>
      {children || " - "}
    </StyledTableCellBody>
  );
};

export const VBTableFooter = function ({ children }) {
  return <TableFooter>{children}</TableFooter>;
};
export const VBTableCellFooter = function (props) {
  return (
    <TableCellFooter sx={{ height: "45px", ...props.sx }} {...props}>
      {props.loader ? (
        <CircularProgress size={15} />
      ) : props.children !== "NaN" ? (
        props.children
      ) : (
        "-"
      )}
    </TableCellFooter>
  );
};
export const VBSkeletonLoader = function () {
  return (
    <TableBody>
      <TableRow>
        <VBTableCellBody colSpan={100}>
          <Box
            sx={{
              p: "1rem",
              width: "100%",
              backgroundColor: "white",
              overflow: "hidden",
            }}
          >
            <Box sx={{ mb: "3rem", overflow: "auto" }}>
              {/* <Skeleton sx={{ backgroundColor: "rgba(173, 212, 245, 0.29)", mb: '0.5rem', width: "70%" }} variant="rectangular" height={40} /> */}
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "50%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "65%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "90%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "70%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "50%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "65%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "90%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "50%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "65%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "90%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "70%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "50%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "65%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "90%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "50%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
              <Skeleton
                sx={{
                  backgroundColor: "rgba(173, 212, 245, 0.29)",
                  mb: "0.1rem",
                  width: "70%",
                  fontSize: "2rem",
                }}
                variant="text"
                height={50}
              />
            </Box>
          </Box>
        </VBTableCellBody>
      </TableRow>
    </TableBody>
  );
};
export function download_excel(url) {
  console.log(url);
  var element = window.document.createElement("a");
  element.href = url;
  element.target = "_blank"; // pdf will open new tab
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
const TableCellHeader = styled(TableCell)(() => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "13px",
  backgroundColor: "#FFFFFF",
  color: "#8A8A8A",
  // textTransform:"uppercase"//
}));

const StyledTableCellBody = styled(TableCell)(() => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  fontSize: "13px",
  height: "20px !important",
  minHeight: "20px",
  maxHeight: "20px",
  borderBottom: "none",
  lineHeight : '1.65'
}));

const TableCellFooter = styled(TableCell)(() => ({
  position: "sticky",
  bottom: 0,
  zIndex: 2,
  backgroundColor: "#fff",
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  paddingTop: "10px",
  paddingBottom: "10px",
  color: "green",
  fontSize: "14px",
  fontWeight: "500",
}));
