import {
  Box,
  Button,
  Paper,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductDetail from "./Components/ProductDetail";
import MultiUnits from "./Components/MultiUnits";
// import { get_VoucherNo, unit_list } from "../../../Api/CommonApi/CommonApis";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateProductURL,
  GetRowMaterialUrl,
  GetSingleRowmaterialUrl,
  ProductDetailsAPI,
  ProductEditURL,
  // ProductGroupAPI,
  ProductsAllURL,
} from "../../../Api/Products/ProductApis";
import {
  // CallBrands,
  CallTaxListByType,
} from "../../../Api/Reports/CommonReportApi";
import Bom from "./Components/Bom";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { jsObjtoFormData } from "../../../generalFunction";
import PaperLoading from "../../../Components/Utils/PaperLoading";
import { openSnackbar } from "../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import NavigationButtons from "../../../Components/ProductComponents/NavigationButtons";

const DefaultMultiUnit = {
  UnitName: "",
  UnitID: 1,
  SalesPrice: 0,
  MinimumSalesPrice: 0,
  PurchasePrice: 0,
  MultiFactor: 1,
  ReverseFactor: "",
  Barcode: "",
  detailID: 1,
  SalesPrice1: "0",
  SalesPrice2: "0",
  SalesPrice3: "0",
  MRP: 0,
  UnitInSales: true,
  UnitInPurchase: true,
  UnitInReports: true,
  DefaultUnit: true,
};

const DefaultBomList = {
  Barcode: "",
  UnitName: null,
  ProductName: null,
  Qty: 1,
  UnitPrice: 0,
  UnitList: [],
  NetAmount: 0,
  id: "",
  MultiFactor: 0,
  PriceListID: 0,
  BOMid: "",
  GstPer: 0,
  VatPer: 0,
};

const DefaultDataDetails = {
  ProductCode: "",
  ProductName: "",
  DisplayName: "",
  Description: "",
  InventoryType: "StockItem",
  WarrantyType: "Year",
  HSNCode: "",

  searchBomText: "",

  VatID: 0,
  BrandID: 0,
  StockMinimum: 0,
  StockOrder: 0,
  StockReOrder: 0,
  StockMaximum: 0,
  MarginPercent: 0,
  ProductGroupID: 0,
  CostWithExpense: 0,
  Warranty: 1,
  AverageCost: 0,
  PLUNo: 0,
  MinimumSalesPrice: 0,

  ManualProductCode: false,
  is_Service: false,
  is_Weighing: false,
  is_RawMaterial: false,
  is_FinishedProduct: false,
  is_Sales: true,
  is_Purchase: true,
  is_Favourite: false,
  is_Active: true,
  is_Inclusive: false,
  is_BOM: false,
  is_KFC: false,

  WeighingCalcType: null,
  productGroup: null,
  brand: null,
  unit: null,
  taxType: null,
  exciseTaxType: null,
  Tax1: null,
  Tax2: null,
  Tax3: null,
  ExciseTaxID: null,

  ProductImages: [],
  BarcodeDetails: [],
  deleted_bom: [],
  multiUnit: [DefaultMultiUnit],
  BomList: [DefaultBomList],

  BomTotal: {
    TotalQty: 0,
    TotalUnitPrice: 0,
    TotalAmountPrice: 0,
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
      <Box sx={{ height: "100%" }}>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateProduct = () => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { uniq_id: ProductID } = location.state || {};
  const [is_edit, setIs_edit] = useState(
    location.state?.uniq_id ? true : false
  );

  const [is_Loading, setIs_Loading] = useState(false);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const {
    GST,
    VAT,
    // PriceDecimalPoint,
    // PriceCategory,
    // EnableVoucherNoUserWise,
    // EnableTransilationInProduct,
    MultiUnit,
    // ShowWarrantyPeriodInProduct,
    // EnableExciseTax,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const [value, setValue] = useState(0);

  const [dataDetail, setDataDetail] = useState(DefaultDataDetails);

  const [dataList, setDataList] = useState({
    priductGroupList: [],
    unitList: [],
    brandList: [],
    taxList: [],
    exiseTaxList: [],
    productList: [],
    InventoryTypes: ["StockItem", "Non StockItem"],
    WeighingCalcTypes: ["Amount And Qty", "Amount", "Qty"],
    WarrantyTypes: ["Year", "Month"],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AddNewMultiUnitLine = function () {
    setDataDetail({
      ...dataDetail,
      multiUnit: [
        ...dataDetail.multiUnit,
        {
          ...DefaultMultiUnit,
          UnitInSales: false,
          UnitInPurchase: false,
          UnitInReports: false,
          DefaultUnit: false,
        },
      ],
    });
  };

  const AddNewBomList = function () {
    setDataDetail({
      ...dataDetail,
      BomList: [
        ...dataDetail.BomList,
        {
          ...DefaultBomList,
        },
      ],
    });
  };

  const saveProduct = function () {
    submitProduct();
  };

  const clearProductState = function () {
    setDataDetail(DefaultDataDetails);
    setIs_edit(false);
    ProductID = "";
    callPreFetch();
    setValue(0);
  };

  const productValidation = function () {
    if (dataDetail.ProductCode === "") {
      dispatch(
        openSnackbar({
          open: true,
          message: "Enter Product Code",
          severity: "warning",
        })
      );
      return false;
    }
    if (dataDetail.ProductName === "") {
      dispatch(
        openSnackbar({
          open: true,
          message: "Enter Product Name",
          severity: "warning",
        })
      );
      return false;
    }
    console.log(dataDetail.productGroup);

    if (typeof dataDetail.productGroup !== "object") {
      dispatch(
        openSnackbar({
          open: true,
          message: "Enter Product Group",
          severity: "warning",
        })
      );
      return false;
    }
    return true;
  };

  async function callPreFetch() {
    setIs_Loading(true);

    let brandList = [];
    let productList = [];
    let unitList = [];
    let taxList = [];
    let exiseTaxList = [];

    // await get_VoucherNo({
    //   BranchID,
    //   CompanyID,
    //   UserID: user_id,
    //   VoucherType: "PC",
    // }).then((e) => {
    //   if (e.StatusCode === 6000) {
    //     setDataDetail((prev) => ({ ...prev, ProductCode: e.VoucherNo }));
    //   }
    // });

    // await ProductGroupAPI({
    //   BranchID,
    //   CompanyID,
    //   CreatedUserID: user_id,
    // }).then((e) => {
    //   if (e.StatusCode === 6000) {
    //     productList = e.data;
    //     setDataList((prev) => ({
    //       ...prev,
    //       priductGroupList: e.data,
    //     }));

    //     setDataDetail((prev) => ({
    //       ...prev,
    //       productGroup: e.data?.[0] || null,
    //     }));
    //   }
    // });

    // await unit_list({
    //   BranchID,
    //   CompanyID,
    //   CreatedUserID: user_id,
    // }).then((e) => {
    //   if (e.StatusCode === 6000) {
    //     unitList = e.data
    //     setDataList((prev) => ({
    //       ...prev,
    //       unitList: e.data,
    //     }));

    //     setDataDetail((prev) => ({
    //       ...prev,
    //       unit: e.data?.[0] || null,
    //       multiUnit: [
    //         {
    //           ...prev.multiUnit[0],
    //           UnitName: e.data?.[0] ? e.data[0].UnitName : "",
    //         },
    //         ...prev.multiUnit.slice(1),
    //       ],
    //     }));
    //   }
    // });

    // await CallBrands({
    //   BranchID,
    //   CompanyID,
    //   CreatedUserID: user_id,
    // }).then((e) => {
    //   if (e.StatusCode === 6000) {
    //     brandList = e.data
    //     setDataList((prev) => ({
    //       ...prev,
    //       brandList: e.data,
    //     }));
    //     setDataDetail((prev) => ({ ...prev, brand: e.data?.[0] || null }));
    //   }
    // });

    await CallTaxListByType({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
      TaxType: 1,
    }).then((e) => {
      if (e.StatusCode === 6000) {
        taxList = e.data;
        setDataList((prev) => ({
          ...prev,
          exiseTaxList: e.excise_data,
        }));
        setDataDetail((prev) => ({
          ...prev,
          exciseTaxType: e.excise_data?.[0] || null,
        }));
        exiseTaxList = e.excise_data;
      }
    });

    await ProductsAllURL({
      BranchID,
      CompanyID,
      UserID: user_id,
      TaxType: 1,
      VoucherType: "PC",
    }).then((res) => {
      if (res.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          taxList: res?.TaxList || [],
          brandList: res?.BrandList || [],
          unitList: res?.UnitList || [],
          priductGroupList: res?.ProductGroupList || [],
        }));

        setDataDetail((prev) => ({
          ...prev,
          ProductCode: res?.ProductInvoice?.VoucherNo || "",
          productGroup: res.ProductGroupList?.[0] || null,
          ProductGroupID: res.ProductGroupList?.[0].ProductGroupID || 0,
          taxType: res.TaxList?.[0] || null,
          unit: res?.UnitList?.[0] || null,
          brand: res?.BrandList?.[0] || null,
          multiUnit: [
            {
              ...prev.multiUnit[0],
              UnitName: res.UnitList?.[0] ? res.UnitList[0].UnitName : "",
              UnitID: res.UnitList?.[0] ? res.UnitList[0].UnitID : 1,
            },
            ...prev.multiUnit.slice(1),
          ],
        }));

        // For editData
        taxList = res?.TaxList || [];
        brandList = res?.BrandList || [];
        unitList = res?.UnitList || [];
        productList = res?.ProductGroupList || [];
        // if (is_edit) {}
      }
    });

    is_edit &&
      ProductID !== "" &&
      (await ProductDetailsAPI(
        {
          BranchID,
          CompanyID,
          CreatedUserID: user_id,
          PriceRounding: 3,
        },
        ProductID
      ).then((res) => {
        if (res.StatusCode === 6000) {
          const data = res.data;
          console.log(res.data);

          // Image set to state Array
          // Do not change the ProductImage key in backend
          const imageUrls = [
            data?.ProductImage,
            data?.ProductImage2,
            data?.ProductImage3,
            data?.ProductImage4,
            data?.ProductImage5,
          ];
          const fetchImages = async (ary) => {
            const imageArray = [];
            for (let itm of ary) {
              if (typeof itm === "string" && itm !== "") {
                try {
                  const response = await fetch(itm);
                  if (response.ok) {
                    const blob = await response.blob();
                    imageArray.push(blob);
                    // setDataDetail((prev) => ({
                    //   ...prev,
                    //   ProductImages: prev.ProductImages.push(blob),
                    // }));
                  }
                } catch (error) {
                  console.error("Error fetching the image:", error);
                }
              }
            }
            setDataDetail((prev) => ({
              ...prev,
              ProductImages: imageArray,
            }));
            console.log(imageArray);
            return imageArray;
          };
          fetchImages(imageUrls);
          // .then((img) => {
          //   // Edit image set only here
          //   setDataDetail((prev) => ({
          //     ...prev,
          //     ProductImages: img,
          //   }));
          // });
          // Image set to state Array End's

          const productGroup = productList.find(
            (e) => e.ProductGroupID === data.ProductGroupID
          );
          const brand = brandList.find((e) => e.BrandID === data.BrandID);

          const unit = unitList.find((e) => e.UnitName === data.UnitName);
          console.log(unit);

          const taxType = taxList.find((e) =>
            VAT ? e.TaxID === data.VatID : e.TaxID === data.GST
          );
          const BarcodeDetails = data.ProductBarcodeList.map((e) => ({
            ...e,
            UnitName: data.PriceListDetails.find((f) => f.UnitID === e.unit)
              .UnitName,
          }));

          const newBomTotal = data.BomList.reduce(
            (total, item) => ({
              TotalUnitPrice: total.TotalUnitPrice + Number(item.UnitPrice),
              TotalAmountPrice:
                total.TotalAmountPrice + Number(item.UnitPrice) * item.Qty,
            }),
            { TotalUnitPrice: 0, TotalAmountPrice: 0 }
          );

          setDataDetail((prev) => ({
            ...prev,

            id: data.id,
            ProductCode: data.ProductCode,
            ProductName: data.ProductName,
            DisplayName: data.DisplayName,
            Description: data.Description,
            InventoryType: data.InventoryType,
            WarrantyType: data.WarrantyType,
            HSNCode: data.HSNCode,

            VatID: data.VatID,
            BrandID: data.BrandID,
            StockMinimum: data.StockMinimum,
            StockOrder: data.StockOrder,
            StockReOrder: data.StockReOrder,

            StockMaximum: data.StockMaximum,
            MarginPercent: data.MarginPercent,
            ProductGroupID: data.ProductGroupID,
            CostWithExpense: data.CostWithExpense,
            Warranty: data.Warranty,
            AverageCost: data.AverageCost,
            PLUNo: data.PLUNo,
            MinimumSalesPrice: data.MinimumSalesPrice,

            // ManualProductCode: data.ManualProductCode,
            is_Service: data.is_Service,
            is_Weighing: data.IsWeighingScale,
            is_RawMaterial: data.IsRawMaterial,
            is_FinishedProduct: data.IsFinishedProduct,
            is_Sales: data.IsSales,
            is_Purchase: data.IsPurchase,
            is_Favourite: data.IsFavourite,
            is_Active: data.Active,
            is_Inclusive: data.is_inclusive,
            is_BOM: data.IsBOM,
            is_KFC: data.IsKFC,

            WeighingCalcType: data.WeighingCalcType,
            productGroup: productGroup,
            brand: brand,
            unit: unit,
            exciseTaxType: data.ExciseTax,
            taxType: taxType,
            Tax1: data.Tax1,
            Tax2: data.Tax2,
            Tax3: data.Tax3,
            ExciseTaxID: data.ExciseTax?.ExciseTaxID || 0,

            // ProductImages: ProductImages,
            BarcodeDetails: BarcodeDetails,
            // deleted_bom: [],
            multiUnit: data.PriceListDetails,
            BomList: data.BomList,
            BomTotal: {
              TotalQty: data.BomTotal.TotalQty,
              TotalUnitPrice: newBomTotal.TotalUnitPrice,
              TotalAmountPrice: newBomTotal.TotalAmountPrice,
            },
          }));
        }
      }));
    setIs_Loading(false);
  }

  async function BomPreFetch() {
    await GetRowMaterialUrl({
      BranchID,
      CompanyID,
      CreatedUserID: user_id,
      Search: dataDetail.searchBomText,
      Type: "Product",
    }).then((e) => {
      if (e.StatusCode === 6000) {
        setDataList((prev) => ({
          ...prev,
          productList: e.data,
        }));
      }
    });
  }

  async function callBomProdUnit(id) {
    try {
      const response = await GetSingleRowmaterialUrl({
        BranchID,
        CompanyID,
        CreatedUserID: user_id,
        Inclusive: false,
        ProductID: id,
        type: "Product",
      });

      if (response.StatusCode === 6000) {
        console.log(response);
        return response.data;
      } else {
        console.log("Error in GetSingleRowmaterialUrl:", response);
        return null;
      }
    } catch (error) {
      console.error("Error in callBomProdUnit:", error);
      return null;
    }
  }

  const submitProduct = async function () {
    // | Create and edit in this same function
    // redirect("/create-products");

    // const barcodeList = dataDetail.BarcodeDetails.map((e) => ({
    //   barcode: e.barcode,
    //   unit: e.unit.UnitID,
    //   detailID: e.detailID
    // }));

    // console.log(dataDetail.ProductImages);
    setIs_Loading(true);

    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ProductName: dataDetail.ProductName,
      ProductCode: dataDetail.ProductCode,
      DisplayName: dataDetail.DisplayName,
      Description: dataDetail.Description,
      ProductGroupID:
        dataDetail.productGroup?.ProductGroupID ||
        dataDetail.ProductGroupID ||
        0,
      BrandID: dataDetail.brand?.BrandID || dataDetail.BrandID,
      InventoryType: dataDetail.InventoryType,
      VatID: VAT ? dataDetail.taxType?.TaxID : null,
      MinimumSalesPrice: dataDetail.MinimumSalesPrice,
      StockMinimum: dataDetail.StockMinimum,
      StockReOrder: dataDetail.StockReOrder,
      // StockOr
      StockMaximum: dataDetail.StockMaximum,
      MarginPercent: dataDetail.MarginPercent,
      ProductImage: dataDetail.ProductImages[0] ?? "",
      ProductImage2: dataDetail.ProductImages[1] ?? "",
      ProductImage3: dataDetail.ProductImages[2] ?? "",
      ProductImage4: dataDetail.ProductImages[3] ?? "",
      ProductImage5: dataDetail.ProductImages[4] ?? "",
      Active: dataDetail.is_Active,
      IsRawMaterial: dataDetail.is_RawMaterial,
      IsWeighingScale: dataDetail.is_Weighing,
      IsFinishedProduct: dataDetail.is_FinishedProduct,
      IsSales: dataDetail.is_Sales,
      IsPurchase: dataDetail.is_Purchase,
      WeighingCalcType: dataDetail.is_Weighing,
      PLUNo: dataDetail.PLUNo,
      IsFavourite: dataDetail.is_Favourite,
      HSNCode: dataDetail.HSNCode,
      IsKFC: dataDetail.is_KFC,
      GST: GST ? dataDetail.taxType.TaxID : null,
      Tax1: dataDetail.Tax1,
      Tax2: dataDetail.Tax2,
      Tax3: dataDetail.Tax3,
      WarrantyType: dataDetail.WarrantyType,
      Warranty: dataDetail.Warranty,
      is_Service: dataDetail.is_Service,
      is_inclusive: dataDetail.is_Inclusive,
      PriceListDetails: dataDetail.multiUnit,
      BarcodeDetails: dataDetail.BarcodeDetails,
      IsBOM: dataDetail.is_BOM,
      BomList:
        dataDetail.is_BOM && dataDetail.BomList[0].id !== ""
          ? dataDetail.BomList
          : [],
      deleted_bom: dataDetail.deleted_bom,
      PurchasePrice: dataDetail.multiUnit[0].PurchasePrice,
      ExciseTaxID: dataDetail.exciseTaxType?.TaxID || dataDetail.ExciseTaxID,
      AverageCost: dataDetail.AverageCost,
      CostWithExpense: dataDetail.CostWithExpense,
    };

    if (is_edit && ProductID !== "") {
      payload.id = dataDetail?.id || ProductID;
    }

    console.log(payload);

    const formData = jsObjtoFormData(payload);
    // const formData = payload;
    const validation = productValidation();
    // const validation = false;
    // console.log(formData);

    if (is_edit && ProductID !== "") {
      validation &&
        (await ProductEditURL(formData, ProductID).then((res) => {
          if (res.StatusCode === 6000) {
            dispatch(
              openSnackbar({
                open: true,
                message: "Edit Product Succesfully",
                severity: "success",
              })
            );
            clearProductState();
          } else if (res.StatusCode === 6001) {
            dispatch(
              openSnackbar({
                open: true,
                message: res?.message || "some field messing or duplicate",
                severity: "warning",
              })
            );
          } else {
            dispatch(
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                severity: "warning",
              })
            );
          }
        }));
    } else {
      validation &&
        (await CreateProductURL(formData).then((res) => {
          if (res.StatusCode === 6000) {
            dispatch(
              openSnackbar({
                open: true,
                message: "Create Product Successfully",
                severity: "success",
              })
            );
            clearProductState();
          } else if (res.StatusCode === 6001) {
            dispatch(
              openSnackbar({
                open: true,
                message: res?.message || "some field messing or duplicate",
                severity: "warning",
              })
            );
          } else if (res.StatusCode === 6002) {
            dispatch(
              openSnackbar({
                open: true,
                message: res?.message || "some field messing or duplicate",
                severity: "error",
              })
            );
          } else {
            dispatch(
              openSnackbar({
                open: true,
                message: res?.message || "some thing went wrong",
                severity: "info",
              })
            );
          }
        }));
    }
    setIs_Loading(false);
  };

  // console.log(ProductID, "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");

  useEffect(() => {
    callPreFetch();
  }, []);

  useEffect(() => {
    BomPreFetch();
  }, [dataDetail.searchBomText]);

  // console.log(dataDetail);

  // console.log(dataDetail, "DSAEATEAERERE");

  return (
    <>
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          p: 3,
          borderColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "36px",
              color: "#001746",
            }}
          >
            {t(`${is_edit ? "Edit" : "Create"} Product`)}
          </Typography>
          <Button
            sx={{
              fontSize: "14px",
              textTransform: "none",
              px: 2,
            }}
            variant="text"
            color="primary"
            component={Link}
            to="/products"
          >
            {t("View List")}
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{
              fontSize: "14px",
              textTransform: "none",
              px: 2,
            }}
            variant="text"
            color="error"
            onClick={() => {
              // navigate("/products");
              // navigate("/create-products", { replace: true });
              clearProductState();
            }}
          >
            {t("Cancel")}
          </Button>
          <BlueButton
            sx={{ px: 4, fontSize: 14 }}
            onClick={() => {
              saveProduct();
            }}
            variant="contained"
            disabled={is_Loading}
          >
            {t("Save")}
          </BlueButton>
          {/* <Button variant="contained" color="primary">Save</Button> */}
        </Box>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              // backgroundColor: VIKNBLUE,
              height: "4px",
            },
            ".MuiButtonBase-root": {
              textTransform: "none",
              width: "160px",
            },
            // "& .Mui-selected": {
            //   color: `${VIKNBLUE} !important`,
            // },
          }}
        >
          <Tab
            label={t("Product Details")}
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(0)}
          />
          {
            <Tab
              label={t("Multi Units")}
              sx={{
                width: "288px",
                textTransform: "none",
                display:
                  !dataDetail.is_Service && !dataDetail.is_BOM && MultiUnit
                    ? "block"
                    : "none",
              }}
              {...a11yProps(1)}
            />
          }
          {
            <Tab
              label={t("BOM")}
              sx={{
                width: "288px",
                textTransform: "none",
                display: dataDetail.is_BOM ? "block" : "none",
              }}
              {...a11yProps(2)}
            />
          }
        </Tabs>
      </Box>
      <ScrollBox>
        <PaperLoading isLoading={is_Loading}>
          <CustomTabPanel value={value} index={0}>
            <ProductDetail
              dataDetail={dataDetail}
              setDataDetail={setDataDetail}
              dataList={dataList}
              is_edit={is_edit}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MultiUnits
              dataDetail={dataDetail}
              setDataDetail={setDataDetail}
              AddNewMultiUnitLine={AddNewMultiUnitLine}
              dataList={dataList}
              is_edit={is_edit}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Bom
              dataDetail={dataDetail}
              dataList={dataList}
              setDataDetail={setDataDetail}
              AddNewBomList={AddNewBomList}
              callBomProdUnit={callBomProdUnit}
              DefaultBomList={DefaultBomList}
            />
          </CustomTabPanel>
        </PaperLoading>
      </ScrollBox>
    </Paper>
    <NavigationButtons />
    </>
  );
};

export default CreateProduct;

const BlueButton = styled(Button)(() => ({
  color: "white",
  // backgroundColor: "#0A9EF3",
  boxShadow: "none",
  textTransform: "none",
  fontSize: "15px",
  borderRadius: "6px",
  // ":hover": {
  //   backgroundColor: "#0A9EF3",
  // },
}));

const ScrollBox = styled(Box)(() => ({
  height: "calc(100vh - 208px)",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&::-webkit-scrollbar-track": {
    display: "none",
  },
}));

// CompanyID: 8218b767-6897-48d7-a559-5f0b09fd7a56
// BranchID: 1
// CreatedUserID: 829
// ProductName: conn
// ProductCode: PC1142
// DisplayName: conn
// Description:
// ProductGroupID: 2
// BrandID: 2
// InventoryType: StockItem
// VatID: 11
// MinimumSalesPrice: 0
// StockMinimum: 0.00
// StockReOrder: 0.00
// StockMaximum: 0.00
// MarginPercent: 0
// ProductImage:
// ProductImage2:
// ProductImage3:
// ProductImage4:
// ProductImage5:
// Active: true
// IsRawMaterial: false
// IsWeighingScale: false
// IsFinishedProduct: false
// IsSales: true
// IsPurchase: true
// WeighingCalcType: Amount
// PLUNo: 0
// IsFavourite: false
// HSNCode:
// IsKFC: false
// GST: null
// Tax1: null
// Tax2: null
// Tax3: null
// WarrantyType: Year
// Warranty: 1
// is_Service: false
// is_inclusive: false
// PriceListDetails: [{"UnitName":"","UnitID":3,"SalesPrice":0,"PurchasePrice":0,"MultiFactor":1,"ReverseFactor":"","Barcode":"","SalesPrice1":"0","SalesPrice2":"0","SalesPrice3":"0","MRP":0,"UnitInSales":true,"UnitInPurchase":true,"UnitInReports":true,"DefaultUnit":true,"detailID":0},{"UnitName":"","UnitID":"","SalesPrice":0,"PurchasePrice":0,"MultiFactor":"","ReverseFactor":"1","Barcode":"","SalesPrice1":"0","SalesPrice2":"0","SalesPrice3":"0","MRP":0,"UnitInSales":false,"UnitInPurchase":false,"UnitInReports":false,"DefaultUnit":false,"detailID":0}]
// BarcodeDetails: [{"barcode":"","unit":1}]
// IsBOM: false
// BomList: [{"Barcode":"","ProductCode":{"ProductCode":""},"ProductName":{"ProductName":""},"Qty":1,"UnitPrice":0,"UnitList":[{"UnitName":""}],"NetAmount":0}]
// deleted_bom: []
// PurchasePrice: 0
// ExciseTaxID: null
// AverageCost: 0
// CostWithExpense: 0
