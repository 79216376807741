// import {  } from '@mui/material'
import styled from '@emotion/styled';
// import React, { useRef } from 'react'

const PrintVatReturn = ({state, tableRef}) => {
  let PriceRounding = 2
  // const tableRef = useRef(null);
  
  return (
    <Table ref={tableRef}>
    <THead>
      <TableHeadRow>
        <TH>{state.language === "Arabic" ? "عنوان" : "Title"}</TH>
        <TH className="align_right">
          {state.language === "Arabic" ? `أمونوت (${state.CurrencySymbol})` : `Amount(${state.CurrencySymbol})`}
        </TH>
        <TH className="align_right">
          {state.language === "Arabic"
            ? `التعديل (${state.CurrencySymbol})`
            : `Adjustment(${state.CurrencySymbol})`}
        </TH>
        <TH className="align_right">
          {state.language === "Arabic"
            ? `مبلغ ضريبة القيمة المضافة (${state.CurrencySymbol} )`
            : `VAT Amount(${state.CurrencySymbol})`}
        </TH>
      </TableHeadRow>
    </THead>
    <TBody>
      <TableBodyRow>
        <TD is_num={false}>
          {" "}
          {state.language === "Arabic"
            ? "مبيعات مصنفة قياسية"
            : "Standard rated sales"}
        </TD>
        <TD className="align_right">
          {state.standard_sales.Amount
            ? Number(state.standard_sales.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.standard_sales.vat_amount
            ? Number(state.standard_sales.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>

      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "الرعاية الصحية الخاصة / التعليم الخاص / مبيعات المنازل الأولى للمواطنين"
            : "Private health care/Private education/First house sales to citizens"}
        </TD>
        <TD className="align_right">
          {state.private_healthcare_sales.Amount
            ? Number(state.private_healthcare_sales.Amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.private_healthcare_sales.vat_amount
            ? Number(state.private_healthcare_sales.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "نسبة المبيعات المحلية الصفرية"
            : "Zero rated domestic sales"}
        </TD>
        <TD className="align_right">
          {state.zero_rated_sales.Amount
            ? Number(state.zero_rated_sales.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.zero_rated_sales.vat_amount
            ? Number(state.zero_rated_sales.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic" ? "صادرات" : "Exports"}
        </TD>
        <TD className="align_right">
          {state.export_sales.Amount
            ? Number(state.export_sales.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.export_sales.vat_amount
            ? Number(state.export_sales.vat_amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "المبيعات المعفاة"
            : "Exempt sales"}
        </TD>
        <TD className="align_right">
          {state.exempt_sales.Amount
            ? Number(state.exempt_sales.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.exempt_sales.vat_amount
            ? Number(state.exempt_sales.vat_amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>

      <TableBodyRow>
        <TD is_num={false} type="sales" className="total">
          {state.language === "Arabic"
            ? "إجمالي المبيعات"
            : "Total Sales"}
        </TD>
        <TD className="align_right">
          {state.total_sales.Amount
            ? Number(state.total_sales.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.total_sales.vat_amount
            ? Number(state.total_sales.vat_amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>

      {/* ------------------------------- */}

      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "مشتريات محلية بسعر قياسي"
            : "Standard rate domestice purchases"}
        </TD>
        <TD className="align_right">
          {state.standard_purchase.Amount
            ? Number(state.standard_purchase.Amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.standard_purchase.vat_amount
            ? Number(state.standard_purchase.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>

      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "الواردات الخاضعة لضريبة القيمة المضافة المدفوعة للعملاء"
            : "Imports subject to VAT paid at customers"}
        </TD>
        <TD className="align_right">
          {state.import_subject_paid_purchase.Amount
            ? Number(state.import_subject_paid_purchase.Amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.import_subject_paid_purchase.vat_amount
            ? Number(
                state.import_subject_paid_purchase.vat_amount
              ).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "يتم احتساب الاستيراد الخاضع لضريبة القيمة المضافة من خلال آلية الاحتساب العكسي"
            : "Import subject to VAT accounted for through reverse charge mechanism"}
        </TD>
        <TD className="align_right">
          {state.import_subject_accounted_purchase.Amount
            ? Number(
                state.import_subject_accounted_purchase.Amount
              ).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.import_subject_accounted_purchase.vat_amount
            ? Number(
                state.import_subject_accounted_purchase.vat_amount
              ).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "المشتريات الخاضعة لنسبة الصفر"
            : "Zero rated purchases"}
        </TD>
        <TD className="align_right">
          {state.zero_rated_purchase.Amount
            ? Number(state.zero_rated_purchase.Amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.zero_rated_purchase.vat_amount
            ? Number(state.zero_rated_purchase.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "المشتريات المعفاة"
            : "Exempt purchases"}
        </TD>
        <TD className="align_right">
          {state.exempt_purchase.Amount
            ? Number(state.exempt_purchase.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.exempt_purchase.vat_amount
            ? Number(state.exempt_purchase.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>

      <TableBodyRow>
        <TD is_num={false} type="purchase" className="total">
          {state.language === "Arabic"
            ? "إجمالي المشتريات"
            : "Total Purchases"}
        </TD>
        <TD className="align_right">
          {state.total_purchase.Amount
            ? Number(state.total_purchase.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.total_purchase.vat_amount
            ? Number(state.total_purchase.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      {/* ------------------------------------- */}
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "إجمالي المشتريات"
            : "Total VAT due for current period"}
        </TD>
        <TD className="align_right">
          {state.Total_VAT_due.Amount
            ? Number(state.Total_VAT_due.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.Total_VAT_due.vat_amount
            ? Number(state.Total_VAT_due.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "تصحيحات من الفترة السابقة"
            : "Corrections from previous period"}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
      <TableBodyRow>
        <TD is_num={false}>
          {state.language === "Arabic"
            ? "ائتمان ضريبة القيمة المضافة المُرحل من الفترة (الفترات) السابقة"
            : "VAT credit carried forward from previous period(s)"}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>

      <TableBodyRow>
        <TD is_num={false} type="net" className="total">
          {state.language === "Arabic"
            ? "صافي ضريبة القيمة المضافة المستحقة (أو المطالبة)"
            : "Net VAT due(or Claim)"}
        </TD>
        <TD className="align_right">
          {state.Total_VAT_due.Amount
            ? Number(state.Total_VAT_due.Amount).toFixed(PriceRounding)
            : Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {Number(0).toFixed(PriceRounding)}
        </TD>
        <TD className="align_right">
          {state.Total_VAT_due.vat_amount
            ? Number(state.Total_VAT_due.vat_amount).toFixed(
                PriceRounding
              )
            : Number(0).toFixed(PriceRounding)}
        </TD>
      </TableBodyRow>
    </TBody>
  </Table>
  )
}

export default PrintVatReturn

const Table = styled.table`
  border-radius: 15px;
  width: 100%;
  background: #fff;
`;

const THead = styled.thead`
  box-shadow: 0 0 0 1px #c6c6c6;
`;
const TH = styled.th`
  border: 2px solid #e6e6e6;
  /* border-right: 1px solid #e6e6e6; */
  text-align: ${({ is_num }) => (is_num === true ? "right" : "left")};
  padding: 1em;
  font-weight: bold;
  /* text-align: left; */
  &.align_right{
    text-align: right;
  }
`;
const TD = styled.td`
  padding: 0.6em;
  /* text-align: left; */
  text-align: ${({ is_num }) => (is_num === true ? "right" : "left")};

  padding: 1em 1em;
  /* border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6; */
  vertical-align: middle;
  &.table-heading {
    text-align: center;
    color: #fff;
    background: #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }
  &.gst-due {
    background: #205d99;
    color: #fff;
    font-weight: bold;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  &.total {
    font-weight: bold;
    /* color: green; */
    color: ${({ type }) =>
      type === "sales" ? "green" : type === "purchase" ? "red" : "blue"};
  }
  &.align_right {
    text-align: right;
  }
`;

const TBody = styled.tbody``;
const TableHeadRow = styled.tr``;
const TableBodyRow = styled.tr`
  &:nth-child(2n + 1) {
    background: #f6f6f6;
  }
`;