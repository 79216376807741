import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import { useTranslation } from "react-i18next";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import Pagenation from "../../../../Components/Utils/Pagenation";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import moment from "moment";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import FilterLable from "../../../../Components/Utils/FilterLable";
import { callProductWarehouseReport } from "../../../../Api/Reports/InventoryApi";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import {
  Box,
  Divider,
  List,
  ListItem,
  Paper,
  Popover,
  Skeleton,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";

import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../../Components/ReportTable/ReportTable";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import VBZoomAnime from "../../../../Components/Utils/VBZoomAnime";
import {
  CallBranchsList,
  CallProductGroups,
  CallProductSearch,
  CallUsers,
  CallWarehouses,
} from "../../../../Api/Reports/CommonReportApi";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { Padding } from "@mui/icons-material";
import { BASE_V11_URL } from "../../../../generalVeriable";

function ProductWarehouseReport() {
  // ==================== global states ====================
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const isAdmin = BranchID === 1 ? true : false;
  console.log(EnableBranch, BranchID);

  // ==================== variables ====================

  const [t] = useTranslation("common");

  // ==================== states ====================

  const [loading, setLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [productSearch, setProductSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState(
    "Generating Report..."
  );
  const [state, setState] = useState({
    pageNo: 1,
    anchorEl: null, // popover
    openFilter: false,
    clearFilter: false,
    customTable: {
      purchaseReturn: { isActive: false },
      salesReturn: { isActive: false },
    },
  });

  const [dataList, setDataList] = useState({
    productWarehouseResponse: {
      data: [],
      count: 0,
    },
  });

  const [filterData, setFilterData] = useState({
    productWiseList: [],
    warehouseList: [],
    userList: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    fromDate: moment(),
    toDate: moment(),
    ProductObj: [],
    WarehouseObj: [],
    userObj: [],
    IsSelectedAllBranch: false,
    SelectedBranches: [{ BranchID: BranchID }],
    BranchList: [],
  });
  console.log(
    filterOptions.SelectedBranches,
    "SelectedBranchesSelectedBranchesSelectedBranches"
  );

  // ==================== functions ====================

  const handlePenIconClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const handlePenIconClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: null,
    }));
  };

  const handleTableHeadingSelection = (option) => {
    setState((prevState) => ({
      ...prevState,
      customTable: {
        ...prevState.customTable,
        [option]: {
          isActive: !prevState.customTable[option].isActive,
        },
      },
    }));
  };

  const handleFilterClose = () => {
    setState((pervState) => ({
      ...pervState,
      openFilter: false,
    }));
  };

  const handleFilter = () => {
    setPageNo(1);
    FetchData();
  };

  const handleDefaultFilter = () => {
    setFilterOptions({
      fromDate: moment(),
      toDate: moment(),
    });
    setPageNo(1);
    setState((prevState) => ({
      ...prevState,
      clearFilter: !prevState.clearFilter,
    }));
  };

  const handleFilterButtonClick = () => {
    setState((prevState) => ({
      ...prevState,
      openFilter: true,
    }));
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === "input") {
      debounceOnChange(value);
    }
  };
  const handleAutoComplete = function (e, value, name) {
    if (value) {
      setFilterOptions({ ...filterOptions, [name]: value });
    }
  };

  const branchCheckChange = (name) => {
    let SelectedBranches = [];
    if (!filterOptions[name] === true) {
      filterOptions.BranchList.map((i) => {
        if (SelectedBranches.includes(i) === false) {
          SelectedBranches.push(i);
        }
      });
      setFilterOptions({
        ...filterOptions,
        SelectedBranches,
        [name]: !filterOptions[name],
      });
    } else {
      setFilterOptions({
        ...filterOptions,
        [name]: !filterOptions[name],
      });
    }
  };

  // ==================== API Fetching ====================
  const controller = new AbortController();
  const signal = controller.signal;
  const searchProduct = async () => {
    setLoadingProduct(true);
    try {
      let res = await CallProductSearch({
        product_name: productSearch,
        length: productSearch.length,
        type: "report",
        is_product_image: false,
        CompanyID: CompanyID,
        BranchID: BranchID,
        PriceRounding: Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        showActiveProductsOnly: filterOptions.showActiveProductsOnly,
        signal, // pass the abort signal here
      });
      if (res.StatusCode === 6000) {
        setFilterData((prev) => ({
          ...prev,
          productWiseList: res.data,
        }));
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error("Error fetching products:", error);
      }
    } finally {
      setLoadingProduct(false);
    }
  };

  const checkTaskStatus = async (taskId) => {
    const response = await fetch(
      `${BASE_V11_URL}reports/check-task-status/` + taskId + "/"
    );
    const data = await response.json();
    if (data.status === "completed") {
      if (data.result.StatusCode === 6000) {
        setDataList((prevState) => ({
          ...prevState,
          productWarehouseResponse: {
            ...prevState.productWarehouseResponse,
            data: data.result.data,
            count: data.result.count,
          },
        }));
        setLoading(false);
      }
    } else if (data.status === "pending") {
      setLoading(true);
      setTimeout(() => checkTaskStatus(taskId), 3000); // Poll every 1 second
    } else {
    }
  };

  const FetchData = async () => {
    const productWarehouseResponse = await callProductWarehouseReport({
      CompanyID: CompanyID,
      BranchID: filterOptions.SelectedBranches.map((i) => i.BranchID),
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      WarehouseList: filterOptions.WarehouseObj.map(
        (item) => item.WarehouseID
      ) || [0],
      ProductList: filterOptions.ProductObj.map((item) => item.ProductID) || [
        0,
      ],
      UserList: filterOptions.userObj.map((item) => item.UserID) || [0],
      PageNo: pageNo,
      ItemPerPage: 4,
    });

    if (productWarehouseResponse.StatusCode === 6000) {
      checkTaskStatus(productWarehouseResponse.task_id);
    }
  };

  const filterApi = async function () {
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "1",
    };
    try {
      const apiNames = ["warehouseList", "userList"];
      const responses = await Promise.all([
        CallWarehouses(payload),
        CallUsers(payload),
      ]);

      let resp = {
        warehouseList: [],
        userList: [],
      };
      responses.forEach((response, index) => {
        if (response.StatusCode === 6000) {
          // console.log(response.data, index);
          resp[apiNames[index]] = response.data;
        } else {
          console.log(
            `API ${apiNames[index]} returned StatusCode ${response.StatusCode}`
          );
        }
      });
      setFilterData((prevState) => ({
        ...prevState,
        warehouseList: resp.warehouseList,
        userList: resp.userList,
      }));
    } catch (error) {
      console.error("An error occurred while making the API calls", error);
    }

    const data = await CallBranchsList({
      items_per_page: 15,
      page_no: 1,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });
    if (data?.StatusCode === 6000) {
      let SelectedBranches = data.data.find((e) => e.BranchID === BranchID);

      setFilterOptions({
        ...filterOptions,
        BranchList: data.data,
        SelectedBranches: [SelectedBranches],
      });
    }
  };

  // ==================== useEffect ====================
  useEffect(() => {
    filterApi();
  }, []);
  useEffect(() => {
    FetchData();
  }, [state.clearFilter, pageNo]);
  useEffect(() => {
    if (productSearch) {
      searchProduct();
    }
  }, [productSearch]);

  // ============= to increase performance ============
  // ====================use memo======================
  const debounceOnChange = React.useMemo(
    () =>
      debounce((value) => {
        setProductSearch(value);
      }, 400),
    []
  );
  const ProductRow = ({ product }) => (
    <TableRow>
      <VBTableCellBody
        colSpan={9}
        sx={{ fontSize: "16px", fontWeight: "bold" }}
      >
        {product.ProductName}
      </VBTableCellBody>
    </TableRow>
  );

  const WarehouseRow = ({ branch }) => (
    <TableRow>
      <VBTableCellBody sx={{ fontWeight: "bold", px: "20px" }}> 
        {branch.BranchName}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.OpeningStock}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.OpeningBalance}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.Purchase}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.PurchaseValue}
      </VBTableCellBody>
      {state.customTable.purchaseReturn.isActive && (
        <>
          <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
            {branch.PurchaseReturn}
          </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
            {branch.PurchaseReturnValue}
          </VBTableCellBody>
        </>
      )}
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.Sales}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.SalesValue}
      </VBTableCellBody>
      {state.customTable.salesReturn.isActive && (
        <>
          <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
            {branch.SalesReturn}
          </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
            {branch.SalesReturnValue}
          </VBTableCellBody>
        </>
      )}
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.ClosingStock}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right", fontWeight: "bold" }}>
        {branch.ClosingBalance}
      </VBTableCellBody>
    </TableRow>
  );

  const DetailedRow = ({ details }) => (
    <TableRow>
      <VBTableCellBody sx={{ px: "40px" }}>
        {details.WarehouseName}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.OpeningStock}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.OpeningBalance}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.Purchase}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.PurchaseValue}
      </VBTableCellBody>
      {state.customTable.purchaseReturn.isActive && (
        <>
          <VBTableCellBody sx={{ textAlign: "right" }}>
            {details.PurchaseReturn}
          </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}>
            {details.PurchaseReturnValue}
          </VBTableCellBody>
        </>
      )}
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.Sales}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.SalesValue}
      </VBTableCellBody>
      {state.customTable.salesReturn.isActive && (
        <>
          <VBTableCellBody sx={{ textAlign: "right" }}>
            {details.SalesReturn}
          </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}>
            {details.SalesReturnValue}
          </VBTableCellBody>
        </>
      )}
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.ClosingStock}
      </VBTableCellBody>
      <VBTableCellBody sx={{ textAlign: "right" }}>
        {details.ClosingBalance}
      </VBTableCellBody>
    </TableRow>
  );
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        {/* ==================== top heading section ==================== */}

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox>
            <Typography
              sx={{
                color: "#001746",
                fontSize: "23px",
                fontWeight: "500",
                mr: 2,
              }}
              variant="h2"
            >
              {t("Product Warehouse Report")}
            </Typography>
            <FilterBtn onClick={handleFilterButtonClick} />
          </FlexBox>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ReportDownload
              setProgress={setProgress}
              setProgressMessage={setProgressMessage}
              invoice_type="product_warehouse_report"
              CompanyID={CompanyID}
              BranchID={`[${filterOptions.SelectedBranches.map(
                (i) => i.BranchID
              )}]`}
              FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
              WarehouseList={
                `[${filterOptions.WarehouseObj.map(
                  (item) => item.WarehouseID
                )}]` || "[0]"
              }
              ProductList={
                `[${filterOptions.ProductObj.map((item) => item.ProductID)}]` ||
                "[0]"
              }
              is_celery={true}
            />
            <Pagenation
              totalItem={dataList.productWarehouseResponse.count}
              page_no={pageNo}
              setPage_no={setPageNo}
              itemPerPage={4}
            />
          </Box>
        </Box>

        {/* ==================== Enabled filter showing area ==================== */}

        <Box
          sx={{
            px: "26px",
            py: "10px",
            overflowX: "scroll",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable
            name={t("Date")}
            value={
              filterOptions.fromDate.format("DD/MM/YYYY") +
              " - " +
              filterOptions.toDate.format("DD/MM/YYYY")
            }
          />
        </Box>

        {/* ==================== table heading section ==================== */}

        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("Name")} </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Opening Stock")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Opening Stock Value")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("New Purchase")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("New Purchase Value")}{" "}
            </VBTableCellHeader>
            {state.customTable.purchaseReturn.isActive && (
              <>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Purchase Return")}{" "}
                </VBTableCellHeader>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Purchase Return value")}{" "}
                </VBTableCellHeader>
              </>
            )}
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Sales")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Sales Value")}{" "}
            </VBTableCellHeader>
            {state.customTable.salesReturn.isActive && (
              <>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Sales Return")}{" "}
                </VBTableCellHeader>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Sales Return")}{" "}
                </VBTableCellHeader>
              </>
            )}
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Closing Stock")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Closing Stock Value")}{" "}
            </VBTableCellHeader>

            {/* ==================== pen icon ==================== */}

            <VBTableCellHeader
              sx={{ textAlign: "right", width: "20px", padding: "3px" }}
            >
              <BorderColorIcon
                sx={{
                  color: "#0A9EF3",
                  cursor: "pointer",
                  width: "20px",
                  textAlign: "center",
                }}
                onClick={handlePenIconClick}
              />
            </VBTableCellHeader>
          </VBTableHeader>
          <Popover
            id={Boolean(state.anchorEl) ? "simple-popover" : undefined}
            open={Boolean(state.anchorEl)}
            anchorEl={state.anchorEl}
            onClose={handlePenIconClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{ padding: "10px" }}
          >
            <ViknCheckboxGroup
              radios={[
                {
                  label: t("Purchase Return"),
                  checked: state.customTable.purchaseReturn.isActive,
                  onChange: () => handleTableHeadingSelection("purchaseReturn"),
                },
                {
                  label: t("Sales Return"),
                  checked: state.customTable.salesReturn.isActive,
                  onChange: () => handleTableHeadingSelection("salesReturn"),
                },
              ]}
            />
          </Popover>

          {/* ==================== table data ==================== */}

          {loading ? (
            <VBSkeletonLoader />
          ) : (
            <TableBody>
              {dataList.productWarehouseResponse.data.map((product, index) => (
                <React.Fragment key={index}>
                  <ProductRow product={product} />
                  {product.BranchesList.map((branch, branchIndex) => (
                    <React.Fragment key={branchIndex}>
                      <WarehouseRow branch={branch} />
                      {branch.Detailed.map((details, detailsIndex) => (
                        <DetailedRow key={detailsIndex} details={details} />
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
              <VBTableRowNull
                length={dataList.productWarehouseResponse.data?.length}
              />
            </TableBody>
          )}
        </VBTableContainer>
      </Paper>

      {/* ======================  filter section =====================*/}

      <ViknDrawer
        open={state.openFilter}
        handleClose={handleFilterClose}
        onFilter={handleFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(date) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    fromDate: date,
                  }))
                }
                toOnChange={(date) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    toDate: date,
                  }))
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Product Wise")}
                filterName={t("Product Wise")}
                loading={loadingProduct}
                options={
                  filterData.productWiseList ? filterData.productWiseList : []
                }
                getOptionLabel={(option) => option.ProductName || ""}
                filterOptions={(options, { inputValue }) =>
                  options.filter(
                    (option) =>
                      (option.ProductName &&
                        option.ProductName.toLowerCase().includes(
                          inputValue.toLowerCase()
                        )) ||
                      (option.ProductCode &&
                        option.ProductCode.toLowerCase().includes(
                          inputValue.toLowerCase()
                        ))
                  )
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Box sx={{ fontSize: "14px", color: "#797979", mr: 2 }}>
                      {option.ProductCode}
                    </Box>
                    <Box sx={{ fontSize: "14px" }}>{option.ProductName}</Box>
                  </Box>
                )}
                onInputChange={handleInputChange}
                onChange={(e, v) => handleAutoComplete(e, v, "ProductObj", -1)}
                value={filterOptions.ProductObj}
              />
            </ListItem>
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                multiple
                placeholder={t("Warehouse")}
                filterName={t("Warehouse")}
                loading={isLoadingUsers}
                options={
                  filterData.warehouseList ? filterData.warehouseList : []
                }
                getOptionLabel={(option) => option.name || ""}
                name="WarehouseObj"
                onInputChange={(event, value, reason) => {
                  if (reason === "clear") {
                    setFilterOptions({ ...filterOptions, WarehouseObj: {} });
                  }
                }}
                onChange={(e, v) =>
                  handleAutoComplete(e, v, "WarehouseObj", -1)
                }
                value={filterOptions.WarehouseObj}
              />
            </ListItem>
            {EnableBranch && BranchID === 1 && (
              <>
                <ListItem>
                  <ViknCheckboxGroup
                    radios={[
                      {
                        label: t("All Branches"),
                        checked: filterOptions.IsSelectedAllBranch,
                        onChange: () =>
                          branchCheckChange("IsSelectedAllBranch"),
                      },
                    ]}
                  />
                </ListItem>
                <ListItem>
                  <ViknAutoComplete
                    disabled={filterOptions.IsSelectedAllBranch}
                    multiple
                    value={filterOptions.SelectedBranches}
                    placeholder={"Select Branches"}
                    options={filterOptions.BranchList}
                    getOptionLabel={(option) =>
                      option.NickName || option.BranchName
                    }
                    onChange={(e, newValue) =>
                      handleAutoComplete(e, newValue, "SelectedBranches")
                    }
                  />
                </ListItem>
              </>
            )}
          </List>
        }
      />
    </>
  );
}

export default ProductWarehouseReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
}));
