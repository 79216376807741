import { customRound } from "../../generalFunction";

function roundOffNumber(value, decimalPlaces) {

  const roundedValue = Math.round(value,2);
  
  return roundedValue
}



//Calculation of Total Amounts
export function ExpenseCalc (dataState,state,DataList,is_manual_roundoff,customRound) {
    let TotalTaxableAmount = 0
    let TotalGrossAmt =0;
    let TotalGrossAmountFc = 0 ;
    let TotalDiscount = 0
    let TotalTax = 0
    let NetTotal = 0
    let AdditionalCost = 0
    let GrandTotal = 0
    let RoundOff = customRound(state.RoundOff) || 0
    let CashReceived = 0
    let CashAmount = 0
    let BankAmount = 0
    let BillDiscPercent = customRound(state.BillDiscPercent) || 0
    let BillDiscAmt = customRound(state.BillDiscAmt) || 0
    let VATAmount = 0
    let SGSTAmount = 0
    let CGSTAmount = 0
    let IGSTAmount = 0
    let Balance = 0
    let OldLedgerBalance = 0
    let TotalVATAmount =0
    let ShippingCharge = customRound(state.ShippingCharge) || 0
    let shipping_tax_amount = customRound(state.shipping_tax_amount) || 0
    let ShippingTax = state.ShippingTax
    if (ShippingTax){
      shipping_tax_amount = customRound(ShippingTax.SalesTax * ShippingCharge/100)
    }
    
    let TaxTaxableAmount = 0
    let NonTaxTaxableAmount = 0
    
    let DetailsAmountTotal = 0
    
    let DataListLength = DataList.filter((i)=> i.Product != null)?.length??0
    let TotalQty = customRound(DataListLength)
    
    // let BillDiscAmt_Split = 0
    // if (BillDiscAmt && TotalQty){
    //   BillDiscAmt_Split = customRound(BillDiscAmt/TotalQty)
    // }
    
    let TotalGrossAmount_for_billdisc = DataList.reduce((acc,curr) => acc + Number(curr.GrossAmount),0)
    TotalGrossAmount_for_billdisc = customRound(TotalGrossAmount_for_billdisc) || 0
    console.log(TotalGrossAmount_for_billdisc,"TotalGrossAmount_for_billdisc");

  
    //Looping dataList
    console.log(DataList,"this is working>>>");
    for (let index = 0; index < DataList.length; index++) {
        
        const item = DataList[index];
     
        
        let GrossAmount = Number(item.Amount) || 0
        let GrossAmountFc = Number(item.FCAmount) || 0
        let DiscountAmount = Number(item.DiscountAmount) || 0
        let TaxAmount =Number(item.TaxAmount) || 0        
        let Tax = item.Tax
        let SalesTax = Tax?.SalesTax || 0
        let Amount = item.Amount || 0
    
        TotalGrossAmt += GrossAmount || 0
        TotalGrossAmountFc += GrossAmountFc || 0
        TotalDiscount += DiscountAmount || 0
        TotalTaxableAmount += Amount - DiscountAmount
        TotalVATAmount += ((GrossAmount - DiscountAmount) * SalesTax  / 100)
       

        NetTotal += Number(GrossAmount - DiscountAmount) + Number(TaxAmount) || 0

        // TotalVATAmount += item.VATAmount || 0

        if (TaxAmount > 0){
            TaxTaxableAmount += Amount - DiscountAmount
        }
        else{
            NonTaxTaxableAmount += Amount - DiscountAmount
        }   

        // let BillDiscAmt_Split = customRound((GrossAmount/TotalGrossAmount_for_billdisc)*BillDiscAmt) || 0
        
      
        //bill disc amount is splitting equally to every details then substraction that with gross amount then finding total tax
        // TotalTax += (GrossAmount - (DiscountAmount + BillDiscAmt_Split)) + TaxAmount //* SalesTax / 100;
        TotalTax +=  TaxAmount //* SalesTax / 100;

        DetailsAmountTotal += Amount

        

        
    }
    BillDiscAmt = (customRound(BillDiscPercent) / 100) * TotalGrossAmt

    // TotalVATAmount = (BillDiscAmt / TaxTaxableAmount) * 100
    // TotalVATAmount -= BillDiscAmt

    state.BillDiscAmount =customRound(BillDiscAmt) 
    
    let BillDisc_taxAmount = customRound(BillDiscAmt*15/100)
    
    if (TotalTax>BillDisc_taxAmount){
  
      TotalTax = TotalTax-BillDisc_taxAmount
      TotalVATAmount -= BillDisc_taxAmount
      
    }

    // if(BillDiscAmt > 0){
    //    TotalVATAmount -= BillDisc_taxAmount
    // }
   
    //after
    TotalDiscount +=customRound(BillDiscAmt) 
    GrandTotal += customRound(TaxTaxableAmount + NonTaxTaxableAmount)
    GrandTotal += customRound(TotalTax)
    
    // VATAmount += (TotalGrossAmt - TotalDiscount) * SalesTax
    GrandTotal += customRound(ShippingCharge + shipping_tax_amount)
    GrandTotal -= BillDiscAmt
   
    
    if (is_manual_roundoff === false){
      let roundedValue= roundOffNumber(GrandTotal, 2);
      RoundOff = customRound(roundedValue - GrandTotal)
      GrandTotal = roundedValue
    }
    else{
      console.log("Else is working>>s");
      GrandTotal += RoundOff
    }
    

    console.log(state.ForeignCurrency,"1323>>");

  // Update the state
  // Update the state
  state.TotalQty = TotalQty;
  state.TotalGrossAmt = TotalGrossAmt;
  state.TotalDiscount = TotalDiscount;
  state.TotalTax = TotalTax;
  state.NetTotal = NetTotal;
  state.TotalVATAmount = TotalVATAmount
  state.TotalTaxableAmount = TotalTaxableAmount;
  state.TaxTaxableAmount = TaxTaxableAmount;
  state.NonTaxTaxableAmount = NonTaxTaxableAmount;
  state.RoundOff = RoundOff
  state.GrandTotal = GrandTotal;
  state.GrandTotalFc = GrandTotal / state.ForeignCurrency
  state.InForeignCurrencyDisc = customRound(
    BillDiscAmt / state.ForeignCurrency
  );
  state.GrossAmountFc = TotalGrossAmountFc 

  
  
  return state
    
}

//Calculation of an line
export function ExpenseLineCalc (dataState,state,lineItem,customRound){

    let Qty = Number(lineItem.Qty) || 0
    let Rate = customRound(lineItem.Rate) || 0
    let DiscountPerc = customRound(lineItem.DiscountPerc)
    let DiscountAmount = customRound(lineItem.DiscountAmount) || 0
    let Tax = lineItem.Tax 
    let TaxID = lineItem.TaxID
    // let Amount = lineItem.Amount * state.ForeignCurrency
    let Amount = lineItem.Amount


    
    let FCAmount   = Amount / state.ForeignCurrency
    let SalesTax = customRound(Tax?.SalesTax??0)
    let PurchaseTax = customRound(Tax?.PurchaseTax??0)

    let TaxPerc = PurchaseTax

    
    let Total  = lineItem.Amount
    let FCTotal  = lineItem.FCAmount


    let UnitTaxAmount = customRound((Rate/100) * SalesTax)
    let InclusivePrice =customRound( Rate + UnitTaxAmount )

    let TaxableAmount = customRound(Amount - DiscountAmount)
    let TaxAmount = ((Amount - DiscountAmount) * SalesTax  / 100)
   
    DiscountAmount = customRound((DiscountPerc / 100) * Amount) || 0;
    let FCDiscountAmount = customRound(DiscountAmount / state.ForeignCurrency);
    
    console.log(Amount , DiscountAmount , TaxAmount , FCDiscountAmount,">>>>Dis");

    Total = customRound((Amount - DiscountAmount) + TaxAmount);
    FCTotal = customRound((FCAmount - FCDiscountAmount) + TaxAmount);
    let VATPerc = TaxPerc

    lineItem.Qty = Qty
    lineItem.Rate = customRound(lineItem.Rate) || 0
    lineItem.DiscountAmount = DiscountAmount
    lineItem.TaxPerc = TaxPerc
    lineItem.VATPerc = VATPerc

    lineItem.TaxableAmount = customRound(TaxableAmount)
    lineItem.TaxAmount = customRound(TaxAmount)
    lineItem.InclusivePrice = customRound(InclusivePrice)
    lineItem.GrossAmount = customRound(Amount)
    lineItem.GrossAmountFc = customRound(FCAmount)
    lineItem.ConversionRate = customRound(state.ForeignCurrency);
    lineItem.FCAmount = FCAmount
    lineItem.Total = Total
    lineItem.FCTotal = FCTotal

    return lineItem
    
}


//Validation 
export const Expense_validation = (dataState,state,DataList,GeneralSettingsData) => {
  
    let field = null;
    let message = null;
    let error = null;
    let indexList = [];

    let Data = DataList;
    let length = Data.length;
    
    let is_non_taxable_amount = false
    let is_taxable_amount = false

    
    //check is there atlease one valid row
    if (Data.length === 0) {
        console.log("Erroroooooorrr1111");
        error = true;
        message = "At least need one valid row";
    } 
    else if (Data.length === 1 && !Data[0].Ledger) {
        error = true;
        message = "At least need one valid row";
    }
    console.log(Data,"DataDataData");
    //looping the dataList and checking all mandatory values are included
    Data.map((obj, i) => {
        console.log(!obj.Ledger,"obj.Ledger>>");
        if (!obj.Ledger) {
          if (
            i + 1 === length &&
            !obj.Ledger &&
            !obj.Tax &&
            !obj.Amount &&
            !obj.Total
          ) {
            //ignoring last line if product is not selected and changed any other data in that line
            console.log("IGNORE THE PART");
          } else {
            indexList.push(i);
          }
        } else if (!obj.Ledger) {
          indexList.push(i);
        }
        else if (!obj.Amount) {
            indexList.push(i);
          }
          // else if (!obj.Qty && Number(obj.Qty) <= 0) {
          //   indexList.push(i);
          // }
        //   else if (!obj.Rate && Number(obj.Rate)<= 0 ) {
        //     indexList.push(i);
          // }
          else if (!obj.Tax) {
            indexList.push(i);
          }
          // else if (!obj.DiscountAmount) {
          //   indexList.push(i);
          // }
          // else if (!obj.GrossAmount) {
          //   indexList.push(i);
          // }
          // else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None" ) {
          //   indexList.push(i);
          // }
          else if (!obj.Total) {
            indexList.push(i);
          }
   
          
          
          
          if (obj.Ledger){
          //checking is there any non tax amount and tax amount
          if (obj.Tax.TaxName === "None"){
            is_non_taxable_amount = true
          }
          else{
            is_taxable_amount = true
          }
          }
          
    });
    
    
    //doing this becouse index is starting from 0 but lines are starting from 1 ,so just incrementing 1 to every index
    let newIndexList = indexList.map(i => i + 1);
    console.log(newIndexList,"newIndexList>>");
    
    if (indexList.length > 0) {
      message = "Data missing in Lines " + newIndexList;
      error = true;
    }

    
    
    //checking values of dataState
    
    //checking values of state
    if (!state.AccountLedger && !error) {
        error = true;
        message = "Provide valid AccountLedger";
        } 

        // else if (!state.Treatment && !error) {
        //     error = true;
        //     message = "Provide valid Treatment";
        // }
        else if (!state.PlaceOfSupply && !error) {
            error = true;
            message = "Provide valid PlaceOfSupply";
        }
        else if (!state.Date && !error) {
            error = true;
            message = "Provide valid Date";
        }
        else if (!state.TotalGrossAmt && !error) {
            error = true;
            message = "Provide valid TotalGrossAmt";
        }
        // else if (!state.TotalTax && !error && is_taxable_amount) {
        //     error = true;
        //     message = "Provide valid TotalTax";
        // }

        else if (!state.NetTotal && !error) {
            error = true;
            message = "Provide valid NetTotal";
        }

        else if (!state.TotalTaxableAmount && !error) {
            error = true;
            message = "Provide valid TotalTaxableAmount";
        }
        // else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
        //     error = true;
        //     message = "Provide valid TaxTaxableAmount";
        // }
        else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
            error = true;
            message = "Provide valid NonTaxTaxableAmount";
        }

        else if (!state.GrandTotal && !error) {
            error = true;
            message = "Provide valid GrandTotal";
        }
        else if ( state.BankAmount > state.GrandTotal && !error) {
          error = true;
          message = "Bank Amount can't  be more than grand total";
      }
        else if ( state.TotalDiscount > 0 && GeneralSettingsData.sales_discount_perm === false && !error) {
          error = true;
          message = "Sorry, you don't have permission for discount";
      }
      
      
        else if ((state.BillDiscPercent > GeneralSettingsData.sales_discount_limit) ){
        error = true;
          message = `Your Bill discount is more than your limit, your limit is ${Number(GeneralSettingsData.sales_discount_limit).toFixed(1)}%`;
      }

      else if (GeneralSettingsData.sales_save === false){
        error = true;
        message = "Sorry, you don't have permission for create an sales invoice "
      }
      else if (state.BillDiscAmt > state.TotalTax && GeneralSettingsData.EnableEinvoiceKSA ){
         error = true;
        message = "Bill Discount Can't be Grater than Taxable Amount "
      }


      
    return { error, message, field, indexList };
  };
