// import { Paper } from "@mui/material";
// import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
// import { useSelector } from "react-redux";
// import { URL } from "../generalVeriable";
// import EinvoiceTemplate from "./EinvoiceTemplate";
// import { useReactToPrint } from "react-to-print";
// import InvoicePDFLoading from "../Components/Loadings/InvoicePDFLoading";
// import CustomeTemplate from "./CustomeTemplate";

// /**
//  * InvoiceComponent function to handle invoice display or printing.
//  *
//  * @param {string} invoice_id - The unique identifier for the invoice.
//  * @param {number} [printPage=1] - The page number to print, default is 1.
//  * @param {'ST' | 'ET' | 'CT'} [invoiceType='ST'] - The type of invoice. One of 'ST', 'ET', or 'CT'.
//  */

// function InvoiceComponent({ invoice_id, printPage = 1 }, ref) {
//   const componentRef = React.useRef(null);
//   const iframeRef = useRef(null);
//   const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
//   const [invoiceType, setInvoiceType] = useState("ST");
//   const [pdfLoading, setPdfLoading] = useState(false);
//   const [htmlContent, setHtmlContent] = useState("");
//   const [PrintInvoiceData, setPrintInvoiceData] = useState({});

//   const printURL = `${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=${printPage}&invoice_type=sales_invoice&invoice_id=${invoice_id}&PriceRounding=3&BranchID=${BranchID}`;

//   const handleAfterPrint = React.useCallback(() => {
//     console.log("`onAfterPrint` called");
//   }, []);

//   const handleBeforePrint = React.useCallback(() => {
//     console.log("`onBeforePrint` called");
//     return Promise.resolve();
//   }, []);

//   const printFn = useReactToPrint({
//     contentRef: componentRef,
//     documentTitle: "AwesomeFileName",
//     onAfterPrint: handleAfterPrint,
//     onBeforePrint: handleBeforePrint,
//   });

//   // const printTemplate = function () {
//   //   invoiceType === "ST" && (async () => await fetch(printURL))();
//   //   invoiceType === "ET" && printFn();
//   // };

//   // Expose printTemplate method to parent component
//   useImperativeHandle(ref, () => ({
//     printTemplate: () => {
//       // Implement your print logic based on invoice type
//       if (invoiceType === "ST") {
//         // For standard invoice type
//         window.print(); // Simple print for iframe content
//       } else if (invoiceType === "ET" && componentRef.current) {
//         // For custom E-Invoice template
//         // Assuming you have a print method on the component
//         // componentRef.current.handlePrint();
//       } else if (invoiceType === "CT" && componentRef.current) {
//         // For custom template
//         // componentRef.current.handlePrint();
//       }
//     },
//   }));

//   const fetchTemplate = async function () {
//     try {
//       // Fetch the HTML content from the server
//       setPdfLoading(true);
//       const response = await fetch(printURL + `&print_template="True"`);
//       if (!response.ok) {
//         throw new Error("Failed to fetch print content");
//       }

//       const contentType = response.headers.get("content-type");

//       if (contentType && contentType.includes("application/json")) {
//         let json = await response.json();
//         if (json?.IsCustom) {
//           setInvoiceType("CT");
//           console.log(json, "Some respinosdo");
//           const TextData = json?.master || [];
//           const TableHead = json.table_head;
//           const TableData = json.details;
//           const FooterData = json.footer;
//           // setPrintInvoiceData({
//           //   IsCustom: res?.IsCustom,
//           //   TextField: [],
//           //   Table: res?.Table,
//           //   TableHead: res?.table_head,
//           //   TableData: res?.details,
//           //   FooterData: res?.footer,
//           // });
//           const textFieldData = TextData.map((detail) => ({
//             id: detail.VariableID,
//             type: detail.Section,
//             name: detail.FieldName,
//             value: detail.FieldValue,
//             IsText: detail.IsText,
//             IsImage: detail.IsImage,
//             width: detail.Width,
//             font_size: detail.FontSize,
//             font_weight: detail.FontWeight,
//             section: detail.Section,
//             text_align: detail.TextAlign,
//             color: detail.Color,
//             top: detail.Top,
//             bottom: detail.Bottom,
//             left: detail.Left,
//             right: detail.Right,
//             border_size: detail.BorderSize,
//             border_color: detail.BorderColor,
//             border_radius: detail.BorderRadius,
//             border_style: detail.BorderStyle,
//           }));
//           const TableHeadData = TableHead.map((detail) => ({
//             id: detail.VariableID,
//             type: detail.Section,
//             name: detail.FieldName,
//             value: detail.FieldValue,
//             IsText: detail.IsText,
//             width: detail.Width,
//             font_size: detail.FontSize,
//             font_weight: detail.FontWeight,
//             section: detail.Section,
//             text_align: detail.TextAlign,
//             color: detail.Color,
//             top: detail.Top,
//             bottom: detail.Bottom,
//             left: detail.Left,
//             right: detail.Right,
//             border_size: detail.BorderSize,
//             border_color: detail.BorderColor,
//             border_radius: detail.BorderRadius,
//             border_style: detail.BorderStyle,
//             border_Top: detail.BorderTop,
//             border_Bottom: detail.BorderBottom,
//             border_Left: detail.BorderLeft,
//             border_Right: detail.BorderRight,
//             paddingTop: detail.PaddingTop,
//             paddingBottom: detail.PaddingBottom,
//             paddingLeft: detail.PaddingLeft,
//             paddingRight: detail.PaddingRight,
//           }));
//           const FooterFieldData = FooterData.map((detail) => ({
//             id: detail.VariableID,
//             type: detail.Section,
//             name: detail.FieldName,
//             value: detail.FieldValue,
//             IsText: detail.IsText,
//             IsImage: detail.IsImage,
//             width: detail.Width,
//             font_size: detail.FontSize,
//             font_weight: detail.FontWeight,
//             section: detail.Section,
//             text_align: detail.TextAlign,
//             color: detail.Color,
//             top: detail.Top,
//             bottom: detail.Bottom,
//             left: detail.Left,
//             right: detail.Right,
//             border_size: detail.BorderSize,
//             border_color: detail.BorderColor,
//             border_radius: detail.BorderRadius,
//             border_style: detail.BorderStyle,
//             border_Top: detail.BorderTop,
//             border_Bottom: detail.BorderBottom,
//             border_Left: detail.BorderLeft,
//             border_Right: detail.BorderRight,
//             paddingTop: detail.PaddingTop,
//             paddingBottom: detail.PaddingBottom,
//             paddingLeft: detail.PaddingLeft,
//             paddingRight: detail.PaddingRight,
//           }));
//           setPrintInvoiceData({
//             TextField: textFieldData,
//             // ImageField : textFieldData,
//             TableHead: TableHeadData,
//             TableData: TableData,
//             FooterData: FooterFieldData,
//             Table: {
//               TableWidth: json.Table.TableWidth,
//               TableTop: json.Table.TableTop,
//               TableLeft: json.Table.TableLeft,
//             },
//           });
//           // setPrintInvoiceData(json);
//         } else if (json?.data) {
//           setInvoiceType("ET");
//           setPrintInvoiceData(json);
//         }
//       } else {
//         // console.log("E-Invoice template is here");
//         setInvoiceType("ST");
//         // Get the HTML content as a string
//         const content = await response.text();
//         setHtmlContent(content);
//       }

//       setPdfLoading(false);
//     } catch (error) {
//       console.error("Error loading print content:", error);
//       setPdfLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchTemplate();
//   }, [invoice_id, CompanyID, BranchID]);

//   useEffect(() => {
//     if (htmlContent && iframeRef.current) {
//       const iframe = iframeRef.current;
//       const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

//       // Clear previous content
//       iframeDoc.open();
//       iframeDoc.write(htmlContent);
//       iframeDoc.close();

//       setPdfLoading(false);
//     }
//   }, [htmlContent]);

//   return (
//     <LoadingContainer isLoading={pdfLoading}>
//       {invoiceType === "ST" && (
//         <Paper
//           elevation={10}
//           sx={{
//             width: "800px",
//             height: "1123px",
//             minHeight: "1123px",
//             borderRadius: "15px",
//             iframe: {
//               borderRadius: "15px",
//               border: 0,
//               width: "100%",
//               //   height: !pdfLoading ? "100%" : "0px", // For loading purpos
//               // display: pdfLoading? "none" : "block"
//             },
//           }}
//         >
//           <iframe
//             ref={iframeRef}
//             style={{
//               width: "100%",
//               height: "100%",
//               border: "none",
//               borderRadius: "15px",
//             }}
//             title="Invoice-Preview"
//           />
//         </Paper>
//       )}

//       {invoiceType === "ET" && (
//         <EinvoiceTemplate
//           ref={componentRef}
//           data={PrintInvoiceData}
//           // reactToPrintEinvoiceRef={reactToPrintEinvoiceRef}
//         />
//       )}
//       {invoiceType === "CT" && (
//         <CustomeTemplate divRef={componentRef} data={PrintInvoiceData} />
//       )}
//     </LoadingContainer>
//   );
// }

// export default InvoiceComponent;

// const LoadingContainer = function ({ children, isLoading }) {
//   return <>{isLoading ? <InvoicePDFLoading /> : children}</>;
// };

import { Paper } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { URL } from "../generalVeriable";
import EinvoiceTemplate from "./EinvoiceTemplate";
import { useReactToPrint } from "react-to-print";
import InvoicePDFLoading from "../Components/Loadings/InvoicePDFLoading";
import CustomeTemplate from "./CustomeTemplate";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const InvoiceComponent = forwardRef(
  (
    { invoice_id, printPage = 1, invoice_type = "sales_invoice", VoucherNo },
    ref
  ) => {
    // Separate refs for different components
    const standardInvoiceRef = useRef(null);
    const einvoiceTemplateRef = useRef(null);
    const customTemplateRef = useRef(null);
    const iframeRef = useRef(null);

    const { CompanyID, BranchID } = useSelector(
      (state) => state.companyDetails
    );
    const [invoiceType, setInvoiceType] = useState("ST");
    const [pdfLoading, setPdfLoading] = useState(false);
    const [htmlContent, setHtmlContent] = useState("");
    const [PrintInvoiceData, setPrintInvoiceData] = useState({});

    const printURL = `${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=${printPage}&invoice_type=${invoice_type}&invoice_id=${invoice_id}&PriceRounding=3&BranchID=${BranchID}`;

    // React to print hook for E-Invoice and Custom Templates
    // const printFn = useReactToPrint({
    //   content: () => {
    //     if (invoiceType === "ET") return einvoiceTemplateRef.current;
    //     if (invoiceType === "CT") return customTemplateRef.current;
    //     return null;
    //   },
    //   documentTitle: `Invoice-${invoice_id}`,
    // });

    const handleAfterPrint = React.useCallback(() => {
      console.log("`onAfterPrint` called");
    }, []);
    //
    const handleBeforePrint = React.useCallback(() => {
      console.log("`onBeforePrint` called");
      return Promise.resolve();
    }, []);

    const printFn = useReactToPrint({
      contentRef: einvoiceTemplateRef,
      documentTitle: "AwesomeFileName",
      onAfterPrint: handleAfterPrint,
      onBeforePrint: handleBeforePrint,
      copyCount: printPage,
    });

    const shareExport = function () {
      const getHighestTop = (data) => {
        return data.reduce(
          (max, item) => (item.top > max ? item.top : max),
          data[0].top
        );
      };

      const handleGeneratePdf = async () => {
        const input = einvoiceTemplateRef.current;
        console.log("input====>", input);
        try {
          const highestTop = getHighestTop(PrintInvoiceData.FooterData);
          // console.log("highestTop============>>>:>>", highestTop + tableHeight);
          const canvasHeight = highestTop + 0 + 20;
          input.style.height = `${canvasHeight}px`;
          const canvas = await html2canvas(input);
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const imgWidth = 210; // A4 width in mm
          const pageHeight = 295; // A4 height in mm
          const marginTop = 0;
          const marginLeftRight = 0;
          const imgHeight =
            (canvasHeight * imgWidth - marginLeftRight) / canvas.width;
          let heightLeft = imgHeight + marginTop * 2;
          let position = marginTop;
          pdf.addImage(
            imgData,
            "PNG",
            marginLeftRight / 2,
            position,
            imgWidth - marginLeftRight,
            imgHeight
          );
          heightLeft -= pageHeight + marginTop;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            console.log("position--while==>", position);
            pdf.addPage();
            pdf.addImage(
              imgData,
              "PNG",
              marginLeftRight / 2,
              position,
              imgWidth - marginLeftRight,
              imgHeight
            );
            heightLeft -= pageHeight;
          }
          pdf.save(`SalesInvoice_${VoucherNo}.pdf`);
        } catch (error) {
          console.error("Error generating PDF:", error);
        }
      };

      const GeneratePDF = async () => {
        const input = einvoiceTemplateRef.current;
        // Convert the DOM element to canvas
        const canvas = await html2canvas(input, { scale: 2, useCORS: true });
        // Convert canvas to Base64 data URL
        const imgData = canvas.toDataURL("image/png");
        // Create a new jsPDF instance
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        // Add more pages if the content is longer than one page
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        // Encode the XML content to Base64
        // const xmlBase64 = btoa(unescape(encodeURIComponent(InvoiceXML)));
        // Attach the XML file to the PDF
        // pdf.addFileToVFS("InvoiceData.xml", InvoiceXML); // Register the XML file
        // pdf.addFile('InvoiceData.xml', 'application/xml'); // Attach the file
        // Save the PDF
        pdf.save(`SalesInvoice_${VoucherNo}.pdf`);
      };
      // console.log(" Ging LaLa, Hu LaLa 🎉 🎉 🎉 🎉 🎉 🎉 🎉 🎉 🎉 🎉 🥳 🥳 🥳 🥳 🥳 🥳 🥳 🥳 🥳 🥳");
      
      if (invoiceType === "CT") {
        handleGeneratePdf();
      } else if (invoiceType === "ET") {
        GeneratePDF();
      } else {
        // downloadPDF();
      }
    };

    // Existing fetchTemplate function remains the same as in your previous code...
    const fetchTemplate = async function () {
      try {
        setPdfLoading(true);
        const response = await fetch(printURL + `&print_template="True"`);
        if (!response.ok) {
          throw new Error("Failed to fetch print content");
        }

        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("application/json")) {
          let json = await response.json();
          if (json?.IsCustom) {
            setInvoiceType("CT");
            // ... (your existing mapping logic for Custom Template)
            setInvoiceType("CT");
            console.log(json, "Some respinosdo");
            const TextData = json?.master || [];
            const TableHead = json.table_head;
            const TableData = json.details;
            const FooterData = json.footer;
            // setPrintInvoiceData({
            //   IsCustom: res?.IsCustom,
            //   TextField: [],
            //   Table: res?.Table,
            //   TableHead: res?.table_head,
            //   TableData: res?.details,
            //   FooterData: res?.footer,
            // });
            const textFieldData = TextData.map((detail) => ({
              id: detail.VariableID,
              type: detail.Section,
              name: detail.FieldName,
              value: detail.FieldValue,
              IsText: detail.IsText,
              IsImage: detail.IsImage,
              width: detail.Width,
              font_size: detail.FontSize,
              font_weight: detail.FontWeight,
              section: detail.Section,
              text_align: detail.TextAlign,
              color: detail.Color,
              top: detail.Top,
              bottom: detail.Bottom,
              left: detail.Left,
              right: detail.Right,
              border_size: detail.BorderSize,
              border_color: detail.BorderColor,
              border_radius: detail.BorderRadius,
              border_style: detail.BorderStyle,
            }));
            const TableHeadData = TableHead.map((detail) => ({
              id: detail.VariableID,
              type: detail.Section,
              name: detail.FieldName,
              value: detail.FieldValue,
              IsText: detail.IsText,
              width: detail.Width,
              font_size: detail.FontSize,
              font_weight: detail.FontWeight,
              section: detail.Section,
              text_align: detail.TextAlign,
              color: detail.Color,
              top: detail.Top,
              bottom: detail.Bottom,
              left: detail.Left,
              right: detail.Right,
              border_size: detail.BorderSize,
              border_color: detail.BorderColor,
              border_radius: detail.BorderRadius,
              border_style: detail.BorderStyle,
              border_Top: detail.BorderTop,
              border_Bottom: detail.BorderBottom,
              border_Left: detail.BorderLeft,
              border_Right: detail.BorderRight,
              paddingTop: detail.PaddingTop,
              paddingBottom: detail.PaddingBottom,
              paddingLeft: detail.PaddingLeft,
              paddingRight: detail.PaddingRight,
            }));
            const FooterFieldData = FooterData.map((detail) => ({
              id: detail.VariableID,
              type: detail.Section,
              name: detail.FieldName,
              value: detail.FieldValue,
              IsText: detail.IsText,
              IsImage: detail.IsImage,
              width: detail.Width,
              font_size: detail.FontSize,
              font_weight: detail.FontWeight,
              section: detail.Section,
              text_align: detail.TextAlign,
              color: detail.Color,
              top: detail.Top,
              bottom: detail.Bottom,
              left: detail.Left,
              right: detail.Right,
              border_size: detail.BorderSize,
              border_color: detail.BorderColor,
              border_radius: detail.BorderRadius,
              border_style: detail.BorderStyle,
              border_Top: detail.BorderTop,
              border_Bottom: detail.BorderBottom,
              border_Left: detail.BorderLeft,
              border_Right: detail.BorderRight,
              paddingTop: detail.PaddingTop,
              paddingBottom: detail.PaddingBottom,
              paddingLeft: detail.PaddingLeft,
              paddingRight: detail.PaddingRight,
            }));
            setPrintInvoiceData({
              TextField: textFieldData,
              // ImageField : textFieldData,
              TableHead: TableHeadData,
              TableData: TableData,
              FooterData: FooterFieldData,
              Table: {
                TableWidth: json.Table.TableWidth,
                TableTop: json.Table.TableTop,
                TableLeft: json.Table.TableLeft,
              },
            });
          } else if (json?.data) {
            setInvoiceType("ET");
            setPrintInvoiceData(json);
          }
        } else {
          setInvoiceType("ST");
          const content = await response.text();
          setHtmlContent(content);
        }

        setPdfLoading(false);
      } catch (error) {
        console.error("Error loading print content:", error);
        setPdfLoading(false);
      }
    };

    // Expose print method to parent component
    useImperativeHandle(ref, () => ({
      printTemplate: () => {
        switch (invoiceType) {
          case "ST":
            // For standard invoice (iframe content)
            if (iframeRef.current) {
              iframeRef.current.contentWindow.print();
            }
            break;
          case "ET":
          case "CT":
            // For E-Invoice and Custom Templates
            printFn();
            break;
          default:
            console.warn("Unknown invoice type for printing");
        }
      },
      pdfExport: () => shareExport(),
    }));

    useEffect(() => {
      fetchTemplate();
    }, [invoice_id, CompanyID, BranchID]);

    useEffect(() => {
      if (htmlContent && iframeRef.current) {
        const iframe = iframeRef.current;
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;

        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();

        setPdfLoading(false);
      }
    }, [htmlContent]);

    return (
      <LoadingContainer isLoading={pdfLoading}>
        {invoiceType === "ST" && (
          <Paper
            elevation={10}
            sx={{
              width: "800px",
              height: "1123px",
              minHeight: "1123px",
              borderRadius: "15px",
            }}
          >
            <iframe
              ref={iframeRef}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "15px",
              }}
              title="Invoice-Preview"
            />
          </Paper>
        )}

        {invoiceType === "ET" && (
          <EinvoiceTemplate ref={einvoiceTemplateRef} data={PrintInvoiceData} />
        )}

        {invoiceType === "CT" && (
          <CustomeTemplate ref={einvoiceTemplateRef} data={PrintInvoiceData} />
        )}
      </LoadingContainer>
    );
  }
);

const LoadingContainer = function ({ children, isLoading }) {
  return <>{isLoading ? <InvoicePDFLoading /> : children}</>;
};

export default InvoiceComponent;
